import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ControlContext } from "../../contexts/ControlContext";
import TextEdit from "./TextEdit";
import ImageEdit from "./ImageEdit";
import { FiImage } from "react-icons/fi";
import {
  MdFontDownload,
  MdTransform,
  MdSlowMotionVideo,
  MdOutlineColorLens,
} from "react-icons/md";
import TransformEdit from "./TransformEdit";
import TwitterEdit from "./Twitter";
import { FaTwitter } from "react-icons/fa";
import LiveLikeEdit from "./LiveLike";
import LiveLikeLogo from "../../Images/LiveLike.png";
import OptaLogo from "../../Images/Opta_Logo.png";
import NeverNoLogo from "../../Images/Dizplai-D-ONLY.png";
import OptaEditor from "./OptaEditor";
import OptaLeague from "./OptaLeague";
import OptaCricketBatting from "./OptaCricketBatting";
import NeverNoEdit from "./NeverNo";
import KitEditor from "./KitEditor";
import { GiTShirt } from "react-icons/gi";
import OptaCricketBowling from "./OptaCricketBowling";
import OptaCricketMiniCard from "./OptaCricketMiniCard";
import OptaCricketTeams from "./OptaCricketTeams";
import NeverNoEditLT from "./NeverNoLT";
import NeverNoEditPoll from "./NeverNoPoll";
import ClockEdit from "./ClockEdit";
import VideoEdit from "./VideoEdit";
import { HiClock } from "react-icons/hi";
import TransitionEdit from "./TransitionEdit";
import WSMEditor from "./WSM";
import ColourEdit from "./ColourEdit";

const Main = styled.div`
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  width: 95%;
`;
const EditList = styled.div`
  display: flex;
  width: 50px;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Button = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 4px;
  justify-content: center;
  background-color: ${(props) => props.theme.secondaryBackgroundColour};
  align-items: center;
`;
const Icon = styled.div`
  font-size: 1.2em;
  padding: 0.5em;
  background-color: ${(props) => (props.selected ? "#191f27" : "")};
`;

const ImageIcon = styled(FiImage)`
  color: ${(props) => props.theme.imageItemColour};
`;

const TextIcon = styled(MdFontDownload)`
  color: ${(props) => props.theme.textItemColour};
`;
const ClockIcon = styled(HiClock)`
  color: ${(props) => props.theme.clockItemColour};
`;
const TransformIcon = styled(MdTransform)`
  color: ${(props) => props.theme.textItemColour};
`;
const MotionIcon = styled(MdSlowMotionVideo)`
  color: ${(props) => props.theme.clockItemColour};
`;
const ColourIcon = styled(MdOutlineColorLens)`
  color: ${(props) => props.theme.colourItemColour};
`;
const TwitterIcon = styled(FaTwitter)`
  color: #00acee;
`;
const KitIcon = styled(GiTShirt)`
  color: #00acee;
`;

const LiveLikeIcon = styled.img`
  height: 17px;
`;
const NeverNoIcon = styled.img`
  height: 17px;
`;

export default function Editor({ view: mainView }) {
  const { selectedScene, selectedEdit, edits, selectedPage } =
    useContext(ProjectContext);
  const { preview, animate, update, triggerAnimation } =
    useContext(ControlContext);
  const [view, setView] = useState(0);
  const [currentTimeline, setCurrentTimeline] = useState("IN");

  function previewGraphic() {
    let tl = selectedScene.data.timelines.find(
      (tl) => tl.name === currentTimeline
    );
    preview(
      selectedScene.name,
      tl.duration - 1,
      edits,
      currentTimeline,
      selectedPage?.engine
    );
  }

  useEffect(() => {
    if (selectedScene?.data?.neverno?.server) {
      setView(3);
    } else if (selectedScene && selectedScene.name === "LiveLike") {
      setView(2);
    } else {
      setView(0);
      setCurrentTimeline("IN");
    }
  }, [selectedScene]);

  return (
    <Main>
      <EditList>
        {selectedEdit && selectedEdit.type === "VIDEO" && (
          <Icon onClick={() => setView(0)} selected={view === 0}>
            <ImageIcon />
          </Icon>
        )}
        {selectedEdit && selectedEdit.type === "TEXT" && (
          <Icon onClick={() => setView(0)} selected={view === 0}>
            <TextIcon />
          </Icon>
        )}
        {selectedEdit && selectedEdit.type === "CLOCK" && (
          <Icon onClick={() => setView(0)} selected={view === 0}>
            <ClockIcon />
          </Icon>
        )}
        {selectedEdit && selectedEdit.type === "IMAGE" && (
          <Icon onClick={() => setView(0)} selected={view === 0}>
            <ImageIcon />
          </Icon>
        )}
        {selectedEdit && (
          <Icon
            onClick={() => setView(1)}
            selected={
              view === 1 ||
              (view === 0 &&
                selectedEdit &&
                (selectedEdit.type === "GROUP" ||
                  selectedEdit.type === "RECTANGLE" ||
                  selectedEdit.type === "CIRCLE"))
            }
          >
            <TransformIcon />
          </Icon>
        )}
        {selectedScene && selectedScene.data?.neverno?.server && (
          <Icon onClick={() => setView(3)} selected={view === 3}>
            <NeverNoIcon src={NeverNoLogo} />
          </Icon>
        )}
        <Icon onClick={() => setView(7)} selected={view === 7 || !selectedEdit}>
          <ColourIcon />
        </Icon>
        <Icon onClick={() => setView(5)} selected={view === 5 || !selectedEdit}>
          <MotionIcon />
        </Icon>
        {window.render && (
          <Icon
            onClick={() => setView(6)}
            selected={view === 6 || !selectedEdit}
          >
            WSM
          </Icon>
        )}
      </EditList>

      <Content>
        {(view === 5 || !selectedEdit) && (
          <TransitionEdit
            triggerAnimation={triggerAnimation}
            selectedScene={selectedScene}
            selectedPage={selectedPage}
            selectedEdit={selectedEdit}
            preview={previewGraphic}
            edits={edits}
          />
        )}
        {view === 3 &&
          (selectedScene?.data?.neverno?.type === "tweet" ||
            selectedScene?.data?.neverno?.type === "" ||
            !selectedScene?.data?.neverno?.type) && (
            <NeverNoEditLT
              selectedScene={selectedScene}
              selectedEdit={selectedEdit}
              preview={previewGraphic}
              edits={edits}
            />
          )}
        {view === 3 && selectedScene?.data?.neverno?.type === "poll" && (
          <NeverNoEditPoll
            selectedScene={selectedScene}
            selectedEdit={selectedEdit}
            preview={previewGraphic}
            edits={edits}
          />
        )}
        {selectedEdit && selectedEdit.type === "CLOCK" && (
          <>
            {view === 0 && (
              <ClockEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
                update={update}
                animate={animate}
                edits={edits}
              />
            )}
            {view === 1 && (
              <TransformEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
          </>
        )}
        {selectedEdit && selectedEdit.type === "TEXT" && (
          <>
            {view === 0 && (
              <TextEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
            {view === 1 && (
              <TransformEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
          </>
        )}
        {mainView === 0 && selectedEdit && selectedEdit.type === "IMAGE" && (
          <>
            {view === 0 && (
              <ImageEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
            {view === 1 && (
              <TransformEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
          </>
        )}
        {selectedEdit && selectedEdit.type === "GROUP" && (
          <>
            {(view === 0 || view === 1) && (
              <TransformEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
          </>
        )}
        {selectedEdit && selectedEdit.type === "RECTANGLE" && (
          <>
            {(view === 0 || view === 1) && (
              <TransformEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
          </>
        )}
        {selectedEdit && selectedEdit.type === "VIDEO" && (
          <>
            {view === 0 && (
              <VideoEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
            {view === 1 && (
              <TransformEdit
                selectedScene={selectedScene}
                selectedEdit={selectedEdit}
                preview={previewGraphic}
              />
            )}
          </>
        )}{" "}
        {view === 6 && (
          <WSMEditor
            selectedScene={selectedScene}
            selectedEdit={selectedEdit}
            edits={edits}
            preview={previewGraphic}
          />
        )}
        {view === 7 && (
          <ColourEdit
            selectedScene={selectedScene}
            selectedEdit={selectedEdit}
            preview={previewGraphic}
          />
        )}
      </Content>
    </Main>
  );
}
