import React from "react";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  justify-content: center;
  background: ${(props) => (props.selected ? "#d20a3e" : "#0e1219")};
  margin-bottom: 3px;
  padding: 0.4em;
`;

const Value = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;
const Cat = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
`;
export default function HeadToHead({
  homeValue,
  awayValue,
  cat,
  onSelect,
  selected,
}) {
  function convertCat() {
    let newCat = [];
    return newCat;
  }
  return (
    <Row onClick={() => onSelect(cat)} selected={selected}>
      <Value>{homeValue}</Value>
      <Cat>{cat}</Cat>
      <Value>{awayValue}</Value>
    </Row>
  );
}
