import React, { useState, useEffect, useContext } from "react";
import { ProjectContext } from "./ProjectContext";
import { UserContext } from "./UserContext";
const ResourcesContext = React.createContext([{}, () => {}]);
const RESOURCE_SERVER =
  window.ENV?.REACT_APP_PROJECT_SERVER || process.env?.REACT_APP_PROJECT_SERVER;
const ResourcesProvider = (props) => {
  const [resources, setResources] = useState(null);
  const [videoResources, setVideoResources] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedVideoFolder, setSelectedVideoFolder] = useState(null);
  const { user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);

  useEffect(() => {
    getFiles();
  }, [user, project]);

  useEffect(() => {
    if (selectedFolder) {
      selectFolder(selectedFolder.path);
    } else if (resources && resources.length > 0) {
      selectFolder(resources?.[0].path);
    }
  }, [selectedFolder, resources, selectFolder]);

  useEffect(() => {
    if (selectedVideoFolder) {
      selectVideoFolder(selectedVideoFolder.path);
    } else if (videoResources && videoResources.length > 0) {
      selectVideoFolder(videoResources?.[0].path);
    }
  }, [selectedVideoFolder, videoResources, setSelectedVideoFolder]);

  function getFiles() {
    if (project) {
      fetch(`${RESOURCE_SERVER}/api/assets/images/${project?.user?._id}`)
        .then((response) => response.json())
        .then((data) => {
          let folders = [
            {
              name: "Root",
              path: "Root",
              type: "directory",
              children: [],
            },
          ];

          data.forEach((item) => {
            let path = item.name.substring(0, item.name.lastIndexOf("/") + 1);
            if (path === "") {
              folders?.[0]?.children?.push({
                path: path,
                name: item.name,
                children: [],
                size: item.size,
              });
            } else {
              let folder = findFolder(folders?.[0], path);
              if (!folder) {
                let newFolder = {
                  path: path,
                  type: "directory",
                  name: item.name.substring(0, item.name.lastIndexOf("/")),
                  children: [],
                };
                if (path !== item.name) {
                  newFolder.children.push({
                    path: path,
                    name: item.name.split("/").at(-1),
                    size: item.size,
                  });
                }
                folders?.[0]?.children?.push(newFolder);
              } else {
                folder.children.push({
                  path: path,
                  name: item.name.split("/").at(-1),
                  size: item.size,
                  children: [],
                });
              }
            }
          });

          setResources(
            folders
            // {
            //   name: "Root",
            //   path: "Root",
            //   type: "directory",
            //   children: data.map((d) => ({ ...d, path: "Root" })),
            // },
          );
        })
        .catch((error) => console.error(error));
      fetch(`${RESOURCE_SERVER}/api/assets/videos/${project?.user?._id}`)
        .then((response) => response.json())
        .then((data) => {
          setVideoResources([
            {
              name: "Root",
              path: "Root",
              type: "directory",
              children: data.map((d) => ({ ...d, path: "Root" })),
            },
          ]);
        })
        .catch((error) => console.error(error));
    }
  }

  function uploadImage(file) {
    const formData = new FormData();

    formData.append("file", file);

    fetch(`${RESOURCE_SERVER}/api/assets/images/${project?.user?._id}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      getFiles();
    });
  }

  function uploadVideo(file) {
    const formData = new FormData();

    formData.append("file", file);

    fetch(`${RESOURCE_SERVER}/api/assets/videos/${project?.user?._id}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      getFiles();
    });
  }

  function selectFolder(path) {
    let folder = findFolder(resources?.[0], path);

    setSelectedFolder(folder);
  }

  function selectVideoFolder(path) {
    let folder = findFolder(videoResources?.[0], path);

    setSelectedVideoFolder(folder);
  }

  function findFolder(folder, path) {
    if (
      folder?.path?.toUpperCase() === path?.toUpperCase() &&
      folder?.type === "directory"
    ) {
      return folder;
    }

    if (folder?.children) {
      for (let i = 0; i < folder.children.length; i++) {
        let f = folder.children[i];
        let fold = findFolder(f, path);
        if (fold) {
          return fold;
        } else if (fold && fold.children) {
          let child = findFolder(fold, path);
          if (child) {
            return child;
          }
        }
      }
    }
  }

  function createNewFolder(name) {
    // axios
    //   .post(
    //     "https://project.hyper.live/folder/" +
    //       window.project +
    //       "?token=" +
    //       window.token,
    //     {
    //       folder: selectedFolder.path + "/" + name,
    //     }
    //   )
    //   .then((response) => {
    //     getFiles();
    //   });
  }

  function deleteFolder(name) {
    fetch(
      "https://project.hyper.live/folder/" +
        window.project +
        "?token=" +
        window.token,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ folder: name }),
      }
    ).then((response) => {
      getFiles();
    });
  }

  function deleteImage(image) {
    fetch(
      `${RESOURCE_SERVER}/api/assets/images/${project?.user?._id}/${image}`,
      {
        method: "DELETE",
      }
    ).then((response) => {
      getFiles();
    });
  }

  function deleteVideo(image) {
    fetch(
      `${RESOURCE_SERVER}/api/assets/videos/${project?.user?._id}/${image}`,
      {
        method: "DELETE",
      }
    ).then((response) => {
      getFiles();
    });
  }

  return (
    <ResourcesContext.Provider
      value={{
        resources,
        uploadImage,
        selectedFolder,
        selectFolder,
        createNewFolder,
        deleteFolder,
        deleteImage,
        videoResources,
        selectedVideoFolder,
        selectVideoFolder,
        uploadVideo,
        deleteVideo,
      }}
    >
      {props.children}
    </ResourcesContext.Provider>
  );
};

export { ResourcesContext, ResourcesProvider };
