import React, { useState, useContext } from "react";
import styled from "styled-components";
import { FiChevronsRight } from "react-icons/fi";
import DesciptionPopup from "../DescriptionPopup/DescriptionPopup";
import { UserContext } from "../../contexts/UserContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import DurationPopup from "../DescriptionPopup/DurationPopup";
import { IoIosInfinite } from "react-icons/io";
import { ScrollBar } from "../../Theme/Hyper";
import EnginePopup from "../DescriptionPopup/EnginePopup";
import { RenderContext } from "../../contexts/RenderContext";

const TableHeader = styled.div`
  display: flex;
  font-weight: 100;
  background-color: #252e40;

  div {
    padding: 0.5em;
    border-left: 1px solid black;
    font-size: 0.8em;
    :first-child {
      width: 40px;
      display: flex;
      justify-content: center;
    }
    :nth-child(2) {
      width: 60px;
      display: flex;
    }
    :nth-child(3) {
      width: 320px;
      display: flex;
    }
    :nth-child(4) {
      width: 80px;
      display: flex;
    }
  }
`;
const TableBody = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 172px);
  overflow-y: auto;
  overflow-x: hidden;
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  white-space: pre;

  :nth-of-type(even) {
    background-color: #1c222b;
  }
  :hover {
    background-color: #d11e42;
  }
  div {
    height: 25px;
    padding: 0.5em;
    border-left: 1px solid black;
    font-size: 0.8em;
    align-items: center;
    :first-child {
      width: 40px;
      display: flex;
      justify-content: center;
    }
    :nth-child(2) {
      width: 60px;
      display: flex;
    }
    :nth-child(3) {
      width: 320px;
      display: flex;
    }
    :nth-child(4) {
      width: 80px;
      display: flex;
    }
  }
`;
const PageNameDiv = styled.div`
  overflow: hidden;
  max-width: 250px;
`;
const TemplateNameDiv = styled.div`
  overflow: hidden;
`;
const EngineNameDiv = styled.div`
  overflow: hidden;
  display: flex;
  color: #8b8b8b;
`;
const PreviewPage = styled(FiChevronsRight)`
  color: #30c17e;
  font-size: 1.5em;
`;

const Popup = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;

const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;
const PlaylistIcon = styled(IoIosInfinite)`
  color: #00d0ff;
  margin-right: 5px;
`;
export default function PageList({
  pages,
  selectPage,
  selectedPage,
  animate,
  animateOff,
  deletePage,
  pageNumber,
  renamePage,
  setDuration,
  setEngine,
  getEdits,
}) {
  const { engines, project ,selectedShow} = useContext(ProjectContext);
  const [contextMenuItem, setContextMenuItem] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDurationPopup, setShowDurationPopup] = useState(false);
  const [showEnginePopup, setShowEnginePopup] = useState(false);
  const [page, setPage] = useState();
  const { user } = useContext(UserContext);
  const { render } = useContext(RenderContext);

  function handleContextMenu(e, item) {
    setContextMenuItem({
      item: item,
      mouseX: e.clientX,
      mouseY: e.clientY,
      showContext: true,
    });
    setPage(item);
    e.preventDefault();
    window.addEventListener("click", handleClick);
  }

  function handleClick() {
    setContextMenuItem({
      ...contextMenuItem,
      showContext: false,
    });
    window.removeEventListener("click", handleClick);
  }

  function getName(page) {
    return page.edits
      .map((edit) => {
        if (edit.type === "TEXT" && edit.text) {
          return edit.text;
        } else if (edit.type === "IMAGE" && edit.image) {
          return edit.image;
        }
      })
      .filter((item) => item)
      .join(" / ");
  }

  return (
    <div>
      <TableHeader>
        <div>-</div>
        <div>Page #</div>
        <PageNameDiv>Name</PageNameDiv>
        <div>Template</div>
        {engines.length > 1 && <div>Output</div>}
      </TableHeader>

      <TableBody>
        {pages
          .filter((p) => {
            if (pageNumber) {
              return p.number.toString().startsWith(pageNumber);
            }
            return true;
          })
          .sort((a, b) => parseInt(a.number) - parseInt(b.number))
          .map((page) => {
            return (
              <TableRow
                onClick={() => {}}
                onDoubleClick={() => {
                  selectPage(page);
                }}
                onContextMenu={(e) => {
                  handleContextMenu(e, page);
                }}
              >
                <div>
                  {selectedPage && page.number === selectedPage.number && (
                    <PreviewPage>></PreviewPage>
                  )}
                  {page.duration > 0 && (
                    <>
                      <PlaylistIcon />
                      {page.duration}s
                    </>
                  )}
                </div>
                <div>{page.number}</div>
                <PageNameDiv>
                  {page.name && page.name !== "" ? page.name : getName(page)}
                </PageNameDiv>
                <TemplateNameDiv>
                  {page.template && page.template.name}
                </TemplateNameDiv>
                {engines.length > 1 && (
                  <EngineNameDiv>{page.engine || 1}</EngineNameDiv>
                )}
              </TableRow>
            );
          })}
      </TableBody>
      {contextMenuItem && contextMenuItem.showContext && (
        <Popup
          style={{
            position: "absolute",
            left: contextMenuItem.mouseX + 10,
            top: contextMenuItem.mouseY,
          }}
        >
          <PopupGroup>
            <PopupItem
              onClick={() => {
                setShowPopup(true);
              }}
            >
              Description
            </PopupItem>
            <PopupItem
              onClick={() => {
                setShowDurationPopup(true);
              }}
            >
              Set Duration
            </PopupItem>
            <PopupItem
              onClick={() => {
                setShowEnginePopup(true);
              }}
            >
              Set Output Channel
            </PopupItem>
            <PopupItem
              onClick={() => {
                deletePage(contextMenuItem.item.number);
              }}
            >
              Delete
            </PopupItem>
          </PopupGroup>
          <PopupGroup>
            <PopupItem
              onClick={() => {
                selectPage(contextMenuItem.item);
              }}
            >
              Preview
            </PopupItem>
            <PopupItem
              onClick={() => {
                let edits = [];
                getEdits(
                  contextMenuItem.item.template.data.scene.children,
                  edits
                );

                edits = edits
                  .sort((a, b) => parseInt(a.control.id) - b.control.id)
                  .map((e) => {
                    return {
                      ...e,
                      ...contextMenuItem.item.edits.find(
                        (pe) => e.control.name === pe.control.name
                      ),
                    };
                  });

                animate(
                  contextMenuItem.item.template.name,
                  edits,
                  contextMenuItem.item.template.data.transition_layer,
                  contextMenuItem.item?.engine
                );
              }}
            >
              Animate In
            </PopupItem>
            <PopupItem
              onClick={() => {
                animateOff(
                  contextMenuItem.item.template.name,
                  [],
                  contextMenuItem.item?.engine
                );
              }}
            >
              Animate Off
            </PopupItem>
          </PopupGroup>
          <PopupGroup>
          {user?.features?.find((f) => f.name === "video_render") &&
          window.render &&
           (
            <PopupItem
              onClick={() => {
                pages?.map((page) =>{
                  let edits = [];
                  getEdits(
                    page.template.data.scene.children,
                    edits
                  );
  
                  edits = edits
                    .sort((a, b) => parseInt(a.control.id) - b.control.id)
                    .map((e) => {
                      return {
                        ...e,
                        ...page.edits.find(
                          (pe) => e.control.name === pe.control.name
                        ),
                      };
                    });
                    render({
                      data: edits,
                      filename:
                        selectedShow?.name +
                        " - " +
                        page?.number +
                        " - " +
                        page?.name +
                        ".mov",
                      template: page?.template?.name,
                      project: project?.name,
                      queue: user?._id,
                    });
                })
              
              }}
            >
              Render all
            </PopupItem>
          )}
          </PopupGroup>

          {/* <PopupGroup>
            <PopupItem>Add to Playlist</PopupItem>
          </PopupGroup> */}
        </Popup>
      )}
      {showPopup && page && (
        <DesciptionPopup
          name={page.name}
          onCancel={() => setShowPopup(false)}
          onSave={(value) => {
            renamePage(page.number, value);
            setShowPopup(false);
            setPage(null);
          }}
        />
      )}
      {showDurationPopup && page && (
        <DurationPopup
          duration={page.duration}
          onCancel={() => setShowDurationPopup(false)}
          onSave={(value) => {
            setDuration(page.number, value);
            setShowDurationPopup(false);
            //setPage(null);
          }}
        />
      )}
      {showEnginePopup && page && (
        <EnginePopup
          duration={page.engine}
          onCancel={() => setShowEnginePopup(false)}
          onSave={(value) => {
            setEngine(page.number, value);
            setShowEnginePopup(false);
            //setPage(null);
          }}
        />
      )}
    </div>
  );
}
