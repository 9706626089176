import React, { useState, useCallback, useContext, useEffect } from "react";
import { FaCaretRight, FaCaretDown, FaFolder } from "react-icons/fa";
import styled from "styled-components";
import { FiImage } from "react-icons/fi";
import { ProjectContext } from "../../contexts/ProjectContext";

const Row = styled.div`
  display: flex;
  justify-items: center;
  font-size: 0.7em;
  height: 25px;
  align-items: center;
  background-color: ${(props) =>
    props.selected ? props.theme.highlightColour : ""};
  :hover {
    background-color: ${(props) => props.theme.highlightColour};
  }
`;

const Icon = styled.div`
  padding-right: 0.5em;
  display: flex;
  align-items: center;
  color: #00d0ff;
  justify-items: center;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0.5em;
  height: 100%;
`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  margin-left: 0.5em;
`;
export default function FolderItem({ resource, level = 0, onContextMenu }) {
  const [open, setOpen] = useState(false);
  const { selectedShowFolder, setSelectedShowFolder } =
    useContext(ProjectContext);

  useEffect(() => {
    if (
      selectedShowFolder &&
      selectedShowFolder.name
        ?.toUpperCase()
        ?.indexOf(resource.name?.toUpperCase()) > -1
    ) {
      setOpen(true);
    }
  }, [selectedShowFolder, resource]);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <Row
        style={{ paddingLeft: level * 2 + "em" }}
        selected={selectedShowFolder && selectedShowFolder._id === resource._id}
      >
        <Main
          onClick={() => {
            toggleOpen();
            setSelectedShowFolder(resource);
          }}
          onContextMenu={(e) => {
            onContextMenu(e, resource);
          }}
        >
          <Icon>
            <FaFolder />
          </Icon>
          {resource.name}
        </Main>
      </Row>
    </>
  );
}
