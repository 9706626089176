import React, { useState, useCallback, useContext, useEffect } from "react";
import { FaCaretRight, FaCaretDown, FaFolder } from "react-icons/fa";
import styled from "styled-components";
import { FiImage } from "react-icons/fi";
import { ResourcesContext } from "../../contexts/ResourcesContext";

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  justify-items: center;
  font-size: 0.7em;
  background-color: ${(props) =>
    props.selected ? props.theme.highlightColour : ""};
  :hover {
    background-color: ${(props) => props.theme.highlightColour};
  }
`;

const Icon = styled.div`
  padding-right: 0.5em;
  display: flex;
  align-items: center;
  color: #00d0ff;
  justify-items: center;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  margin-left: 0.5em;
`;
export default function ImageItem({ resource, level = 0, onContextMenu }) {
  const [open, setOpen] = useState(false);
  const { selectFolder, selectedFolder } = useContext(ResourcesContext);

  useEffect(() => {
    if (
      selectedFolder &&
      selectedFolder.path?.toUpperCase().indexOf(resource.path?.toUpperCase()) >
        -1
    ) {
      setOpen(true);
    }
  }, [selectedFolder, resource]);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);
  if (resource.type !== "directory") {
    return null;
  }

  return (
    <>
      <Row
        style={{ paddingLeft: level * 2 + "em" }}
        selected={
          selectedFolder &&
          selectedFolder.path?.toUpperCase() === resource.path?.toUpperCase()
        }
      >
        <Main
          onClick={() => {
            toggleOpen();
            selectFolder(resource.path);
          }}
          onContextMenu={(e) => {
            onContextMenu(e, resource);
          }}
        >
          {resource.type === "directory" && (
            <>
              {!open && <FaCaretRight color={"#5e6477"} size={"1.5em"} />}
              {open && <FaCaretDown color={"#5e6477"} size={"1.5em"} />}
            </>
          )}

          <Icon>
            {resource.type === "directory" && <FaFolder />}
            {resource.type === "file" && <FiImage />}
          </Icon>
          {resource.name}
          {resource.progress < 100 && <Loading>{resource.progress}%</Loading>}
        </Main>
      </Row>
      {open && resource.children && (
        <Children>
          {resource.children.map((child) => {
            return (
              <ImageItem
                resource={child}
                level={level + 1}
                onContextMenu={onContextMenu}
              />
            );
          })}
        </Children>
      )}
    </>
  );
}
