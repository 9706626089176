import React from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const PROJECTS = gql`
  query Projects($user: String!) {
    projectsByUser(userId: $user) {
      name
      colour
      _id
      user {
        _id
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Projects = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Project = styled.div`
  background-color: #2a2c39;
  border-radius: 4px;
  margin: 1em;
  height: 90px;
  width: 160px;
  display: flex;
  justify-content: center;
  padding: 0.3em;
  cursor: pointer;
  position: relative;
  align-items: center;
  color: black;
`;

function ProjectViewer({ callback }) {
  const { loading, error, data } = useQuery(PROJECTS, {
    variables: { user: localStorage.getItem("user-id") },
    onCompleted: (data) => {
      if (data.projectsByUser.length === 1) {
        callback(data.projectsByUser[0]);
      }
    },
  });

  if (!data?.projectsByUser) {
    return <></>;
  }

  return (
    <Main>
      <div>
        <h2>Select Project..</h2>
      </div>
      <Projects>
        {data?.projectsByUser?.map((project, index) => {
          return (
            <Project
              key={index}
              onClick={() => callback(project)}
              style={{ backgroundColor: project.colour }}
            >
              {project.name}
            </Project>
          );
        })}
      </Projects>
    </Main>
  );
}

export default ProjectViewer;
