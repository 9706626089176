import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import Login from "../Login/Login";
import { UserContext } from "../../contexts/UserContext";
import ProjectViewer from "../ProjectViewer/ProjectViewer";
import { ProjectContext } from "../../contexts/ProjectContext";
import EngineSelect from "../EngineSelect/EngineSelect";

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(14, 18, 25);
  opacity: 1;
  transition: 0.5s opacity;
  z-index: 100;
`;

const ProjectMain = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background-color: rgb(14, 18, 25);
  opacity: 1;
  transition: 0.5s opacity;
  z-index: 100;
  padding-top: 50px;
  padding-left: 50px;
`;

const Bar = styled.div`
  margin-top: -10%;
  width: 25%;
  height: 5px;
  background-color: #191f2b;
  border-radius: 4px;
`;
const InnerBar = styled.div`
  height: 100%;
  background-color: #db0a41;
  border-radius: 4px;
`;
export default function Loading({ selectProject }) {
  const { loggedIn } = useContext(UserContext);
  const { project, setProject, engines } = useContext(ProjectContext);
  const [percentage, setPercenatge] = useState(0);
  const [enginePercentage, setEnginePercenatge] = useState(0);
  const ref = useRef(null);

  // useEffect(() => {
  //   if (loggedIn) {
  //     if (percentage <= 100) {
  //       setTimeout(() => {
  //         setPercenatge(percentage + 1);
  //       }, 2000 / 100);
  //     } else {
  //       if (ref && ref.current) {
  //         setTimeout(() => {
  //           ref.current.style.display = "none";
  //         }, 600);
  //       }
  //     }
  //   }
  // }, [percentage, loggedIn]);

  if (loggedIn && !project) {
    return (
      <ProjectMain ref={ref} style={{ opacity: percentage >= 100 ? 0 : 1 }}>
        <ProjectViewer
          callback={(newProject) => {
            setProject(newProject);
            //selectProject(newProject);
            setPercenatge(100);
          }}
        />
      </ProjectMain>
    );
  }
  if (loggedIn && project) {
    return (
      <ProjectMain
        ref={ref}
        style={{ opacity: enginePercentage >= 100 ? 0 : 1 }}
      >
        <EngineSelect
          engines={engines}
          done={() => {
            selectProject(project);
            setPercenatge(100);
          }}
        />
      </ProjectMain>
    );
  }
  return (
    <Main
      ref={ref}
      style={{ opacity: percentage >= 100 && enginePercentage >= 100 ? 0 : 1 }}
    >
      {!loggedIn && <Login />}
      {project && loggedIn && (
        <Bar>
          <InnerBar
            style={{
              width: percentage + "%",
            }}
          />
        </Bar>
      )}
    </Main>
  );
}
