import React, { useContext, useEffect } from "react";
import { LiveLikeContext } from "../../contexts/LiveLikeContext";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { FiChevronsRight } from "react-icons/fi";
import { OptaContext } from "../../contexts/OptaContext";

const Main = styled.div``;

const Header = styled.div`
  padding: 0.5em;
`;
const RowHeader = styled.div`
  display: flex;
  font-weight: 100;
  background-color: #252e40;
  justify-content: center;
  padding: 0.5em;
  border-left: 1px solid black;
  font-size: 0.8em;
`;

const Row = styled.div`
  padding-left: 1em;
  padding-top: 1em;
  cursor: pointer;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.altColour ? "#1c222b" : "")};
  :hover {
    background-color: #d11e42;
  }
`;

const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const Selected = styled(FiChevronsRight)`
  color: #30c17e;
  font-size: 1.5em;
`;

export default function Opta() {
  const { data, teams, setMatchID, matchData, matchID } = useContext(
    OptaContext
  );

  return (
    <Main>
      <Header>
        <Dropown
          onChange={(e) => {
            setMatchID(e.currentTarget.value);
          }}
        >
          <option disabled selected value>
            -- select event --
          </option>
          {data.map((item) => {
            return (
              <DropownOption
                value={item["@attributes"].uID.replace("g", "")}
                selected={matchID === item["@attributes"].uID.replace("g", "")}
              >
                {
                  teams.find(
                    (t) =>
                      t["@attributes"].uID ===
                      item.TeamData[0]["@attributes"].TeamRef
                  ).Name
                }{" "}
                v{" "}
                {
                  teams.find(
                    (t) =>
                      t["@attributes"].uID ===
                      item.TeamData[1]["@attributes"].TeamRef
                  ).Name
                }
              </DropownOption>
            );
          })}
        </Dropown>
      </Header>
    </Main>
  );
}
