import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";

const Main = styled.div`
  background-color: #c11938;
  padding: 1em;
  border-radius: 4px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.5em;
`;
export default function Button({ scene, selectedPage }) {
  const { animateOff } = useContext(ControlContext);

  function handleClick() {
    animateOff(scene?.name, [], scene?.engine);
  }

  return <Main onClick={handleClick}>{scene?.name}</Main>;
}
