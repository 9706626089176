import React, { useContext, useEffect, useState } from "react";
import { OptaContext } from "../../contexts/OptaContext";
import Opta from "../Opta/Opta";
import styled from "styled-components";
import HeadToHead from "../Opta/HeadToHead";
import { ScrollBar } from "../../Theme/Hyper";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ControlContext } from "../../contexts/ControlContext";
import teamColours from "../../data/teamColours.json";

const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
  flex-direction: column;
`;

const Stats = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 325px;
`;

const Type = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
`;

const TeamColour = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
`;

const Colours = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
  width: 50%;
`;
const Kit = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
  border-radius: 4px;
  background-color: ${(props) => props.colour};
  width: 40px;
  padding: 1em;
  margin-right: 0.5em;
  box-sizing: content-box;
  border: ${(props) =>
    props.selected ? "3px solid #d20a3e" : "1px solid #656565"};
`;

const optaCalculations = [
  {
    type: "Shots",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "total_scoring_att"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Total Shots",
  },
  {
    type: "Shots",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "ontarget_scoring_att"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "On Target",
  },
  {
    type: "Shots",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "att_obox_miss");
        if (value) {
          value = parseInt(value["@value"]);
        } else {
          value = 0;
        }

        let value2 = data.find(
          (d) => d["@attributes"].Type === "att_ibox_miss"
        );
        if (value2) {
          value2 = parseInt(value2["@value"]);
        } else {
          value2 = 0;
        }

        let value3 = data.find(
          (d) => d["@attributes"].Type === "att_obox_post"
        );
        if (value3) {
          value3 = parseInt(value3["@value"]);
        } else {
          value3 = 0;
        }

        let value4 = data.find(
          (d) => d["@attributes"].Type === "att_ibox_post"
        );
        if (value4) {
          value4 = parseInt(value4["@value"]);
        } else {
          value4 = 0;
        }

        return value + value2 + value3 + value4;
      } catch (err) {
        return 0;
      }
    },

    name: "Off Target",
  },
  {
    type: "Shots",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "blocked_scoring_att"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Blocked",
  },
  {
    type: "Possession",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "possession_percentage"
        )["@value"];
        return value;
        return Math.round(value);
      } catch (err) {
        return 0;
      }
    },
    name: "Overall Possession",
  },
  {
    type: "Passes",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "accurate_pass")[
          "@value"
        ];
        let value2 = data.find((d) => d["@attributes"].Type === "total_pass")[
          "@value"
        ];
        return Math.round((value / value2) * 100);
      } catch (err) {
        return 0;
      }
    },
    name: "Passing %",
  },
  {
    type: "Passes",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "successful_final_third_passes"
        )["@value"];
        let value2 = data.find(
          (d) => d["@attributes"].Type === "total_final_third_passes"
        )["@value"];
        return Math.round((value / value2) * 100);
      } catch (err) {
        return 0;
      }
    },
    name: "Attacking 3rd%",
  },
  {
    type: "Passes",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "total_att_assist"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Key Passes",
  },
  {
    type: "Attack",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "big_chance_created"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Clear Cut Chances",
  },
  {
    type: "Attack",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "accurate_cross"
        )["@value"];
        let value2 = data.find((d) => d["@attributes"].Type === "total_cross")[
          "@value"
        ];
        return Math.round((value / value2) * 100);
      } catch (err) {
        return 0;
      }
    },
    name: "Crosses %",
  },
  {
    type: "Attack",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "won_contest")[
          "@value"
        ];
        let value2 = data.find(
          (d) => d["@attributes"].Type === "total_contest"
        )["@value"];
        return Math.round((value / value2) * 100);
      } catch (err) {
        return 0;
      }
    },
    name: "Dribbles %",
  },
  {
    type: "Attack",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "corner_taken")[
          "@value"
        ];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Corners",
  },
  {
    type: "Attack",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "total_offside")[
          "@value"
        ];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Offsides",
  },
  {
    type: "Attack",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "ball_recovery")[
          "@value"
        ];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Recoveries",
  },
  {
    type: "Defence",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "won_tackle")[
          "@value"
        ];
        let value2 = data.find((d) => d["@attributes"].Type === "total_tackle")[
          "@value"
        ];
        return Math.round((value / value2) * 100);
      } catch (err) {
        return 0;
      }
    },
    name: "Tackles %",
  },
  {
    type: "Defence",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "interception")[
          "@value"
        ];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Interceptions",
  },
  {
    type: "Defence",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "outfielder_block"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Blocks",
  },
  {
    type: "Defence",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "total_clearance"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Clearances",
  },
  {
    type: "Defence",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "head_clearance"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Header Clearances",
  },
  {
    type: "Defence",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "aerial_won")[
          "@value"
        ];
        let value2 = data.find((d) => d["@attributes"].Type === "aerial_lost")[
          "@value"
        ];
        return Math.round((value / (parseInt(value) + parseInt(value2))) * 100);
      } catch (err) {
        return 0;
      }
    },
    name: "Aerial Duels %",
  },
  {
    type: "Defence",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "blocked_cross")[
          "@value"
        ];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Blocked Crosses",
  },
  {
    type: "Goal Keeping",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "saves")[
          "@value"
        ];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Saves",
  },
  {
    type: "Goal Keeping",
    opta: function (data) {
      try {
        let dive_catch = data.find(
          (d) => d["@attributes"].Type === "dive_catch"
        )["@value"];
        let stand_catch = data.find(
          (d) => d["@attributes"].Type === "stand_catch"
        )["@value"];
        let good_high_claim = data.find(
          (d) => d["@attributes"].Type === "good_high_claim"
        )["@value"];
        let cross_not_claimed = data.find(
          (d) => d["@attributes"].Type === "cross_not_claimed"
        )["@value"];
        return Math.round(
          ((dive_catch + parseInt(stand_catch) + parseInt(good_high_claim)) /
            (dive_catch +
              parseInt(cross_not_claimed) +
              parseInt(stand_catch) +
              parseInt(good_high_claim))) *
            100
        );
      } catch (err) {
        return 0;
      }
    },
    name: "Catches %",
  },
  {
    type: "Discipline",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "fk_foul_lost")[
          "@value"
        ];
        return parseFloat(value);
      } catch (err) {
        return 0;
      }
    },
    name: "Fouls Committed",
  },
  {
    type: "Discipline",
    opta: function (data) {
      try {
        let value = data.find((d) => d["@attributes"].Type === "fk_foul_won")[
          "@value"
        ];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Fouls Won",
  },

  {
    type: "Discipline",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "total_yel_card"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Yellow Cards",
  },
  {
    type: "Discipline",
    opta: function (data) {
      try {
        let value = data.find(
          (d) => d["@attributes"].Type === "total_red_card"
        )["@value"];
        return value;
      } catch (err) {
        return 0;
      }
    },
    name: "Red Cards",
  },
];

export default function OptaEditor() {
  const { matchData, matchID, setMatchID, data } = useContext(OptaContext);
  const { setDataDrive, selectedPage, edits, clear } = useContext(
    ProjectContext
  );
  const { preview, animate, animateOff, scenesOnAir } = useContext(
    ControlContext
  );
  const [cats, setCats] = useState([]);
  const [homeKit, setHomeKit] = useState("");
  const [awayKit, setAwayKit] = useState("");

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        let animated = false;
        if (window.liveLikeOnAir) {
          animateOff("LiveLike");
          animated = true;
        }
        if (window.twitterOnAir) {
          animateOff("WatchalongTwitter");
          animated = true;
        }
        if (window.watchalongPromoOnAir) {
          animateOff("WatchalongPromo");
          animated = true;
        }
        if (animated) {
          setTimeout(() => {
            animate("WatchalongOpta", edits);
          }, 1000);
        } else {
          animate("WatchalongOpta", edits);
        }
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        animateOff("WatchalongOpta");
        clear();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [edits, scenesOnAir]);

  useEffect(() => {
    let dataDrive = [
      {
        name: "Opta",
        matchID: matchID,
        cats: cats,
        homeKit: homeKit,
        awayKit: awayKit,
      },
    ];
    setDataDrive(dataDrive);
  }, [matchID, cats, homeKit, awayKit]);

  useEffect(() => {
    if (selectedPage && selectedPage.dataDrive) {
      let data = selectedPage.dataDrive.find((d) => d.name === "Opta");
      if (data) {
        setMatchID(data.matchID);
        setCats(data.cats || []);
        if (data.homeKit) {
          setHomeKit(data.homeKit);
        }
        if (data.awayKit) {
          setAwayKit(data.awayKit);
        }
      }
    }
  }, [selectedPage]);

  function selectData(cat) {
    let newCats = [...cats];
    let index = newCats.findIndex((c) => c === cat);
    if (index > -1) {
      newCats.splice(index, 1);
    } else {
      newCats.push(cat);
    }

    setCats(newCats);
  }

  useEffect(() => {
    if (edits && matchID && cats) {
      try {
        edits.find((edit) => edit.control.name === "ROW_1").visible = false;
        edits.find((edit) => edit.control.name === "ROW_2").visible = false;
        edits.find((edit) => edit.control.name === "ROW_3").visible = false;

        edits.find(
          (edit) => edit.control.name === "HOME_COLOUR"
        ).text = homeKit;
        edits.find(
          (edit) => edit.control.name === "AWAY_COLOUR"
        ).text = awayKit;

        cats.forEach((cat, index) => {
          try {
            let stat = optaCalculations.find((s) => s.name === cat);
            let homeValue = stat.opta(matchData.MatchData.TeamData[0].Stat);
            let awayValue = stat.opta(matchData.MatchData.TeamData[1].Stat);
            edits.find(
              (edit) => edit.control.name === index + 1 + "_HOME_VALUE"
            ).text = homeValue;
            edits.find(
              (edit) => edit.control.name === index + 1 + "_AWAY_VALUE"
            ).text = awayValue;
            edits.find(
              (edit) => edit.control.name === index + 1 + "_CAT"
            ).text = stat.name;

            let total =
              parseFloat(homeValue.toString().replace("%", "")) +
              parseFloat(awayValue.toString().replace("%", ""));
            if (total && total > 0) {
              let percent =
                parseFloat(homeValue.toString().replace("%", "")) / total;
              edits.find(
                (edit) => edit.control.name === index + 1 + "_HOME_BAR"
              ).text = 310 * percent;
              percent =
                parseFloat(awayValue.toString().replace("%", "")) / total;
              edits.find(
                (edit) => edit.control.name === index + 1 + "_AWAY_BAR"
              ).text = 310 * percent;
            } else {
              edits.find(
                (edit) => edit.control.name === index + 1 + "_HOME_BAR"
              ).text = 0.0000001;
              edits.find(
                (edit) => edit.control.name === index + 1 + "_AWAY_BAR"
              ).text = 0.0000001;
            }
            edits.find(
              (edit) => edit.control.name === "ROW_" + (index + 1)
            ).visible = true;
          } catch (err) {}
        });
      } catch (err) {}
      preview("WatchalongOpta", 2, edits, "T6");
    }
  }, [matchID, cats, edits, matchData, homeKit, awayKit]);
  let homeTeamKits;
  let awayTeamKits;
  let match = data.find(
    (m) => m["@attributes"].uID.replace("g", "") === matchID
  );
  if (match && match.TeamData) {
    homeTeamKits = teamColours.find(
      (t) => t.uID === match.TeamData[0]["@attributes"].TeamRef
    );
    awayTeamKits = teamColours.find(
      (t) => t.uID === match.TeamData[1]["@attributes"].TeamRef
    );
  }

  return (
    <EditArea>
      {/* {standings &&
        standings.map((team) => {
          return <div>{team.Standing.Against}</div>;
        })} */}
      <Opta />
      <TeamColour>
        {homeTeamKits && (
          <Colours>
            <Kit
              colour={homeTeamKits.homeKit}
              onClick={() => {
                setHomeKit(homeTeamKits.homeKit);
              }}
              selected={homeKit === homeTeamKits.homeKit}
            >
              {" "}
            </Kit>
            <Kit
              colour={homeTeamKits.awayKit}
              onClick={() => {
                setHomeKit(homeTeamKits.awayKit);
              }}
              selected={homeKit === homeTeamKits.awayKit}
            >
              {" "}
            </Kit>
            <Kit
              colour={homeTeamKits.thirdKit}
              onClick={() => {
                setHomeKit(homeTeamKits.thirdKit);
              }}
              selected={homeKit === homeTeamKits.thirdKit}
            >
              {" "}
            </Kit>
          </Colours>
        )}
        {awayTeamKits && (
          <Colours>
            <Kit
              colour={awayTeamKits.homeKit}
              onClick={() => {
                setAwayKit(awayTeamKits.homeKit);
              }}
              selected={awayKit === awayTeamKits.homeKit}
            >
              {" "}
            </Kit>
            <Kit
              colour={awayTeamKits.awayKit}
              onClick={() => {
                setAwayKit(awayTeamKits.awayKit);
              }}
              selected={awayKit === awayTeamKits.awayKit}
            >
              {" "}
            </Kit>
            <Kit
              colour={awayTeamKits.thirdKit}
              onClick={() => {
                setAwayKit(awayTeamKits.thirdKit);
              }}
              selected={awayKit === awayTeamKits.thirdKit}
            >
              {" "}
            </Kit>
          </Colours>
        )}
      </TeamColour>

      <Stats>
        {matchData &&
          optaCalculations.map((op, index) => {
            return (
              <>
                {(index === 0 ||
                  op.type !== optaCalculations[index - 1].type) && (
                  <Type>{op.type}</Type>
                )}
                <HeadToHead
                  onSelect={(cat) => {
                    selectData(cat);
                  }}
                  homeValue={op.opta(
                    matchData.MatchData && matchData.MatchData.TeamData
                      ? matchData.MatchData.TeamData[0].Stat
                      : 0
                  )}
                  cat={op.name}
                  awayValue={op.opta(
                    matchData.MatchData && matchData.MatchData.TeamData
                      ? matchData.MatchData.TeamData[1].Stat
                      : 0
                  )}
                  selected={cats.find((c) => c === op.name)}
                />
              </>
            );
          })}
      </Stats>
    </EditArea>
  );
}
