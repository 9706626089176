import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { IoMdUnlock, IoMdLock } from "react-icons/io";

const EditArea = styled.div`
  background-color: #191f27;
`;

const Label = styled.div`
  background-color: #191f27;
  margin: 0.5em;
  min-width: 100px;
`;
const Property = styled.div`
  display: flex;
  align-items: center;
`;
const TextInput = styled.input`
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
`;
const ImageTransform = styled.div`
  display: flex;
  align-items: center;
`;
const Properties = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LockIcon = styled(IoMdLock)`
  padding: 0.5em;
  font-size: 2em;
  color: ${(props) => props.theme.highlightColour};
  cursor: pointer;
`;
const UnLockIcon = styled(IoMdUnlock)`
  padding: 0.5em;
  font-size: 2em;
  cursor: pointer;
`;
export default function TransformEdit({ selectedEdit, preview }) {
  const [update, setUpdate] = useState(false);
  const [ratioLock, setRatioLock] = useState(true);
  const [ratioWidth, setRatioWidth] = useState();
  const [ratioHeight, setRatioHeight] = useState();
  const [moveObj, setMoveObj] = useState({ mouseDown: 0 });

  useEffect(() => {
    if (selectedEdit) {
      setRatioWidth(selectedEdit.height / selectedEdit.width);
      setRatioHeight(selectedEdit.width / selectedEdit.height);
    }
  }, [selectedEdit, ratioLock]);

  return (
    <EditArea>
      <Property>
        <Label>X</Label>
        <TextInput
          type="number"
          value={selectedEdit.x}
          onChange={(e) => {
            selectedEdit.x = e.currentTarget.value;
            preview();
            setUpdate(!update);
          }}
          onWheel={(e) => {
            if (e.deltaY > 0) {
              selectedEdit.x = parseInt(e.currentTarget.value) - 1;
            } else {
              selectedEdit.x = parseInt(e.currentTarget.value) + 1;
            }
          }}
        />
      </Property>
      <Property>
        <Label>Y</Label>
        <TextInput
          type="number"
          value={selectedEdit.y}
          onChange={(e) => {
            selectedEdit.y = e.currentTarget.value;
            preview();
            setUpdate(!update);
          }}
          onWheel={(e) => {
            if (e.deltaY > 0) {
              selectedEdit.y = parseInt(e.currentTarget.value) - 1;
            } else {
              selectedEdit.y = parseInt(e.currentTarget.value) + 1;
            }
          }}
        />
      </Property>
      {selectedEdit.type === "IMAGE" && (
        <ImageTransform>
          <Properties>
            <Property>
              <Label>Width</Label>
              <TextInput
                type="number"
                value={selectedEdit.width}
                onChange={(e) => {
                  if (e.currentTarget.value > 0) {
                    if (ratioLock) {
                      let value = parseInt(e.currentTarget.value) * ratioWidth;
                      if (value <= 0) {
                        return;
                      }

                      selectedEdit.height = parseInt(value);
                    }
                    selectedEdit.width = parseInt(e.currentTarget.value);
                    preview();
                    setUpdate(!update);
                  }
                }}
                onWheel={(e) => {
                  if (e.deltaY > 0) {
                    selectedEdit.width = parseInt(e.currentTarget.value) - 1;
                  } else {
                    selectedEdit.width = parseInt(e.currentTarget.value) + 1;
                  }
                }}
              />
            </Property>
            <Property>
              <Label>Height</Label>
              <TextInput
                type="number"
                value={selectedEdit.height}
                onChange={(e) => {
                  if (e.currentTarget.value > 0) {
                    if (ratioLock) {
                      let value = parseInt(e.currentTarget.value * ratioHeight);
                      if (value <= 0) {
                        return;
                      }
                      selectedEdit.width = value;
                    }
                    selectedEdit.height = e.currentTarget.value;
                    preview();
                    setUpdate(!update);
                  }
                }}
                onWheel={(e) => {
                  if (e.deltaY > 0) {
                    selectedEdit.height = parseInt(e.currentTarget.value) - 1;
                  } else {
                    selectedEdit.height = parseInt(e.currentTarget.value) + 1;
                  }
                }}
              />
            </Property>
          </Properties>
          {ratioLock && (
            <LockIcon
              onClick={() => {
                setRatioLock(false);
              }}
            />
          )}
          {!ratioLock && (
            <UnLockIcon
              onClick={() => {
                setRatioLock(true);
              }}
            />
          )}
        </ImageTransform>
      )}
    </EditArea>
  );
}
