import React, { useState } from "react";
import teamColours from "../../data/teamColours.json";
import styled from "styled-components";
import Sidebar from "../Sidebar/Sidebar";
const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
`;
const Row = styled.div`
  padding: 1em;
  display: flex;
  align-items: center;
`;
const TeamName = styled.div`
  padding: 1em;
  display: flex;
`;
const Kit = styled.div`
  padding: 1em;
  height: 10px;
  width: 10px;
  display: flex;
  background-color: ${(props) => props.colour};
`;
const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const Team = styled.div`
  width: 50%;
`;
export default function KitEditor({ selectedEdit, preview, edits }) {
  const [selectedHomeTeam, setSelectedHomeTeam] = useState();
  let homeTeam = teamColours.find((t) => t.Name === selectedHomeTeam);
  const [selectedAwayTeam, setSelectedAwayTeam] = useState();
  let awayTeam = teamColours.find((t) => t.Name === selectedAwayTeam);
  return (
    <EditArea>
      <Team>
        <Dropown
          onChange={(e) => {
            setSelectedHomeTeam(e.currentTarget.value);
          }}
        >
          <option disabled selected value>
            -- select event --
          </option>
          {teamColours
            .sort((a, b) => {
              if (a.Name < b.Name) {
                return -1;
              }
              if (a.Name > b.Name) {
                return 1;
              }
              return 0;
            })
            .map((item) => {
              return (
                <DropownOption
                  value={item.Name}
                  selected={selectedHomeTeam === item.Name}
                >
                  {item.Name}
                </DropownOption>
              );
            })}
        </Dropown>

        {homeTeam && (
          <Row>
            <TeamName>{homeTeam.Name}</TeamName>
            <Kit
              colour={homeTeam.homeKit}
              onClick={() => {
                edits.find((edit) => edit.control.name === "HOME_TEAM").colour =
                  homeTeam.homeColour;
                edits.find((edit) => edit.control.name === "HOME_TEAM").text =
                  homeTeam.clockName;
                edits.find(
                  (edit) => edit.control.name === "HOME_TEAM_COLOUR"
                ).text = homeTeam.homeKit;
                preview();
              }}
            ></Kit>
            <Kit
              colour={homeTeam.awayKit}
              onClick={() => {
                edits.find((edit) => edit.control.name === "HOME_TEAM").colour =
                  homeTeam.awayColour;
                edits.find((edit) => edit.control.name === "HOME_TEAM").text =
                  homeTeam.clockName;
                edits.find(
                  (edit) => edit.control.name === "HOME_TEAM_COLOUR"
                ).text = homeTeam.awayKit;

                preview();
              }}
            ></Kit>
            <Kit
              colour={homeTeam.thirdKit}
              onClick={() => {
                edits.find((edit) => edit.control.name === "HOME_TEAM").colour =
                  homeTeam.thirdColour;
                edits.find((edit) => edit.control.name === "HOME_TEAM").text =
                  homeTeam.clockName;
                edits.find(
                  (edit) => edit.control.name === "HOME_TEAM_COLOUR"
                ).text = homeTeam.thirdKit;
                preview();
              }}
            ></Kit>
          </Row>
        )}
      </Team>
      <Team>
        <Dropown
          onChange={(e) => {
            setSelectedAwayTeam(e.currentTarget.value);
          }}
        >
          <option disabled selected value>
            -- select event --
          </option>
          {teamColours
            .sort((a, b) => {
              if (a.Name < b.Name) {
                return -1;
              }
              if (a.Name > b.Name) {
                return 1;
              }
              return 0;
            })
            .map((item) => {
              return (
                <DropownOption
                  value={item.Name}
                  selected={selectedAwayTeam === item.Name}
                >
                  {item.Name}
                </DropownOption>
              );
            })}
        </Dropown>

        {awayTeam && (
          <Row>
            <TeamName>{awayTeam.Name}</TeamName>
            <Kit
              colour={awayTeam.homeKit}
              onClick={() => {
                edits.find((edit) => edit.control.name === "AWAY_TEAM").colour =
                  awayTeam.homeColour;
                edits.find((edit) => edit.control.name === "AWAY_TEAM").text =
                  awayTeam.clockName;
                edits.find(
                  (edit) => edit.control.name === "AWAY_TEAM_COLOUR"
                ).text = awayTeam.homeKit;
                preview();
              }}
            ></Kit>
            <Kit
              colour={awayTeam.awayKit}
              onClick={() => {
                edits.find((edit) => edit.control.name === "AWAY_TEAM").colour =
                  awayTeam.awayColour;
                edits.find((edit) => edit.control.name === "AWAY_TEAM").text =
                  awayTeam.clockName;
                edits.find(
                  (edit) => edit.control.name === "AWAY_TEAM_COLOUR"
                ).text = awayTeam.awayKit;

                preview();
              }}
            ></Kit>
            <Kit
              colour={awayTeam.thirdKit}
              onClick={() => {
                edits.find((edit) => edit.control.name === "AWAY_TEAM").colour =
                  awayTeam.thirdColour;
                edits.find((edit) => edit.control.name === "AWAY_TEAM").text =
                  awayTeam.clockName;
                edits.find(
                  (edit) => edit.control.name === "AWAY_TEAM_COLOUR"
                ).text = awayTeam.thirdKit;
                preview();
              }}
            ></Kit>
          </Row>
        )}
      </Team>
    </EditArea>
  );
}
