import React, { useState, useEffect } from "react";
import teamsData from "../data/teams.json";
import moment from "moment";
import convert from "xml-js";

const OptaCricketContext = React.createContext([{}, () => {}]);

const OptaCricketProvider = (props) => {
  const [fixturesData, setFixturesData] = useState([]);
  const [scorecardData, setScorecardData] = useState([]);

  useEffect(() => {
    async function getOptaData() {
      let fetchJobs = [];
      return;
      fetchJobs.push(
        fetch("http://sky.hyperstudios.co.uk/optaCricketScorecard/s")
          .then((response) => response.text())
          .then((str) => {
            return convert.xml2js(str, { compact: true, spaces: 4 });
          })
          .then((data) => {
            setScorecardData(data);
          })
          .catch((err) => {
            console.log(err);
          })
      );

      // fetchJobs.push(
      //   fetch("http://sky.hyperstudios.co.uk/optaCricketFixtures")
      //     .then((response) => response.text())
      //     .then((str) => {
      //       return convert.xml2js(str, { compact: true, spaces: 4 });
      //     })
      //     .then((data) => {
      //       setFixturesData(data);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     })
      // );

      await Promise.all(fetchJobs);
    }

    getOptaData();
    let id = setInterval(getOptaData, 30000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <OptaCricketContext.Provider
      value={{
        scorecardData,
        fixturesData,
      }}
    >
      {props.children}
    </OptaCricketContext.Provider>
  );
};

export { OptaCricketContext, OptaCricketProvider };
