import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";
import { ProjectContext } from "../../contexts/ProjectContext";

const EditArea = styled.div`
  background-color: #191f27;
  display: flex;
`;
const TextEditor = styled.textarea`
  background-color: #191f27;
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  width: 80%;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
  height: 98px;
  outline: none;
`;

const Label = styled.div`
  background-color: #191f27;
  margin: 0.5em;
  min-width: 100px;
`;
const Property = styled.div`
  display: flex;
  align-items: center;
`;
const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #373b48;
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;
const Controls = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const TextInput = styled.input`
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
`;
export default function ClockEdit({
  selectedEdit,
  update,
  edits,
  selectedScene,
  animate,
}) {
  const { animateOff } = useContext(ControlContext);
  const { clear, selectedPage } = useContext(ProjectContext);
  const [paused, setPaused] = useState(false);
  const [count, setCount] = useState(false);

  useEffect(() => {
    if (selectedEdit) {
      setCount(selectedEdit.count);
    }
  }, [selectedEdit]);

  const start = useCallback(() => {
    let obj = edits.find(
      (edit) => edit.control.name === selectedEdit?.control?.name
    );

    obj.action = "start";

    if (selectedEdit.clock_type === "COUNTDOWN") {
      obj.time = count;
    } else {
      obj.time = 0;
    }

    update(selectedScene.name, edits);
    // animate(selectedScene.name, edits);
  }, [update, edits, count]);

  const resume = useCallback(() => {
    let obj = edits.find(
      (edit) => edit.control.name === selectedEdit?.control?.name
    );

    obj.action = "resume";
    update(selectedScene.name, edits);
    // animate(selectedScene.name, edits);
  }, [update, edits]);

  const reset = useCallback(() => {
    let obj = edits.find(
      (edit) => edit.control.name === selectedEdit?.control?.name
    );

    obj.action = "reset";
    if (selectedEdit.clock_type === "COUNTDOWN") {
      obj.time = count;
    } else {
      obj.time = 0;
    }

    update(selectedScene.name, edits);
  }, [update, edits, count]);

  const pause = useCallback(() => {
    let obj = edits.find(
      (edit) => edit.control.name === selectedEdit?.control?.name
    );

    obj.action = "stop";
    update(selectedScene.name, edits);
  }, [update, edits]);

  const animateGraphic = useCallback(() => {
    let newEdits = edits.filter((e) => e.type !== "CLOCK");
    animate(
      selectedScene.name,
      newEdits,
      selectedScene.data.transition_layer,
      selectedPage?.engine
    );
  }, [animate, edits]);
  const animateOffGraphic = useCallback(() => {
    animateOff(selectedScene.name, [], selectedPage?.engine);
    clear();
  }, [animateOff, edits]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        animateGraphic();
        e.stopPropagation();
        e.stopImmediatePropagation();
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        animateOffGraphic();
        e.stopPropagation();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <EditArea>
      <Controls>
        <Button
          onClick={() => {
            animateGraphic();
          }}
        >
          ANIMATE
        </Button>
        <Button
          onClick={() => {
            animateOffGraphic();
          }}
        >
          ANIMATE OFF
        </Button>
      </Controls>
      <Content>
        {selectedEdit.clock_type === "COUNTDOWN" && (
          <Property>
            <Label>Start Time (seconds)</Label>
            <TextInput
              type="text"
              value={count}
              onChange={(e) => {
                selectedEdit.count = e.currentTarget.value;
                setCount(e.currentTarget.value);
              }}
            />
          </Property>
        )}
        <Property>
          <Label>Clock Control</Label>
          <Button
            onClick={() => {
              setPaused(false);
              start();
            }}
          >
            START
          </Button>
          {paused && (
            <Button
              onClick={() => {
                setPaused(false);
                resume();
              }}
            >
              RESUME
            </Button>
          )}
          {!paused && (
            <Button
              onClick={() => {
                setPaused(true);
                pause();
              }}
            >
              PAUSE
            </Button>
          )}
          <Button
            onClick={() => {
              setPaused(false);
              reset();
            }}
          >
            RESET
          </Button>
        </Property>
      </Content>
    </EditArea>
  );
}
