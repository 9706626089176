import React, { useContext, useEffect, useState, useCallback } from "react";
import { OptaCricketContext } from "../../contexts/OptaCricketContext";
import Opta from "../Opta/Opta";
import styled from "styled-components";
import HeadToHead from "../Opta/HeadToHead";
import { ScrollBar } from "../../Theme/Hyper";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ControlContext } from "../../contexts/ControlContext";
import { FaColumns } from "react-icons/fa";
const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#373b48")};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export default function OptaCricketTeams() {
  const { scorecardData } = useContext(OptaCricketContext);
  const {
    setDataDrive,
    selectedPage,
    edits,
    clear,
    selectedScene,
  } = useContext(ProjectContext);
  const { preview, animateOff, animate, triggerAnimation } = useContext(
    ControlContext
  );
  const [innings, setInnings] = useState(1);
  const [team, setTeam] = useState(0);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        // animate("FF - Standings", edits, "IN");
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        // animateOff("FF - Standings", []);
        clear();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [edits]);

  const updateData = useCallback(() => {
    try {
      if (scorecardData) {
        for (let i = 1; i < 15; i++) {
          edits.find(
            (edit) => edit.control.name === "ROW_" + i
          ).visible = false;
          edits.find(
            (edit) => edit.control.name === i + "_SELECTED"
          ).visible = false;
          edits.find(
            (edit) => edit.control.name === "COLUMN_" + i + "_2_1"
          ).text = "";
          edits.find(
            (edit) => edit.control.name === "COLUMN_" + i + "_2_2"
          ).text = "";
          edits.find(
            (edit) => edit.control.name === i + "_SELECTED"
          ).visible = false;
          edits.find(
            (edit) => edit.control.name === "COLUMN_" + i + "_5_1"
          ).text = "";
          edits.find(
            (edit) => edit.control.name === "COLUMN_" + i + "_5_2"
          ).text = "";
        }
        let homeTeam = scorecardData.CricketMatchSummary.PlayerDetail.Team.find(
          (t) => t._attributes.home_or_away === "home"
        );
        let awayTeam = scorecardData.CricketMatchSummary.PlayerDetail.Team.find(
          (t) => t._attributes.home_or_away === "away"
        );

        edits.find((edit) => edit.control.name === "1_SELECTED").visible = true;
        edits.find((edit) => edit.control.name === "COLUMN_1_2_2").text =
          homeTeam._attributes.team_name;
        edits.find((edit) => edit.control.name === "COLUMN_1_5_2").text =
          awayTeam._attributes.team_name;
        homeTeam.Player.map((player, index) => {
          edits.find(
            (edit) => edit.control.name === "ROW_" + (index + 1)
          ).visible = true;
          edits.find(
            (edit) => edit.control.name === "COLUMN_" + (index + 2) + "_2_1"
          ).text = player._attributes.player_first_name;
          let name = player._attributes.player_last_name;
          if (player._attributes.captain === "-1") {
            name += " (c)";
          }
          if (player._attributes.keeper === "-1") {
            name += " (wk)";
          }
          edits.find(
            (edit) => edit.control.name === "COLUMN_" + (index + 2) + "_2_2"
          ).text = name;
        });

        awayTeam.Player.map((player, index) => {
          edits.find(
            (edit) => edit.control.name === "ROW_" + (index + 1)
          ).visible = true;
          edits.find(
            (edit) => edit.control.name === "COLUMN_" + (index + 2) + "_5_1"
          ).text = player._attributes.player_first_name;
          let name = player._attributes.player_last_name;
          if (player._attributes.captain === "-1") {
            name += " (c)";
          }
          if (player._attributes.keeper === "-1") {
            name += " (wk)";
          }
          edits.find(
            (edit) => edit.control.name === "COLUMN_" + (index + 2) + "_5_2"
          ).text = name;
        });

        preview("CRICKET_Teams", 25, edits, "IN");
      }
    } catch (err) {}
  }, [scorecardData, edits, innings, team]);

  useEffect(() => {
    if (selectedPage) {
      let dataDrive = [
        {
          name: "OptaLeague",
        },
      ];
      setDataDrive(dataDrive);
    } else if (selectedScene) {
      let dataDrive = [
        {
          name: "OptaLeague",
        },
      ];
      setDataDrive(dataDrive);
    }
  }, [selectedPage, selectedScene]);

  useEffect(() => {
    if (selectedPage && selectedPage.dataDrive) {
      let data = selectedPage.dataDrive.find((d) => d.name === "OptaLeague");
    }
  }, [selectedPage]);

  return (
    <EditArea>
      <Controls>
        <Column>
          <Button
            onClick={() => {
              updateData();
            }}
          >
            UPDATE DATA
          </Button>
        </Column>
      </Controls>
    </EditArea>
  );
}
