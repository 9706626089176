import React, { useContext, useState } from "react";
import styled from "styled-components";
import logo from "../../Images/HyperLogo.png";
import { ControlContext } from "../../contexts/ControlContext";
import { AiOutlineWindows } from "react-icons/ai";
import { UIContext } from "../../contexts/UIContext";
import { FaRegKeyboard } from "react-icons/fa";
import { UserContext } from "../../contexts/UserContext";
import Keyboard from "../Keyboard/Keyboard";
import { Button } from "../../Theme/Hyper";
const Main = styled.div`
  height: 40px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #030304;
`;

const HyperLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  height: 50%;
`;

const Profile = styled.div`
  margin-right: 0.5em;
  background-color: #a549de;
  color: white;
  border-radius: 50%;
  padding: 0.6em;
  height: 15px;
  width: 15px;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
`;

const ProfileEngine = styled.div`
  margin-right: 0.2em;
  background-color: #a549de;
  color: white;
  border-radius: 4px;
  padding: 0.6em;
  height: 12px;
  width: 12px;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
`;

const Column = styled.div`
  width: ${100 / 3}%;
  height: 100%;
  display: flex;
  justify-content: ${(props) => {
    if (props.right) return "flex-end";
    if (props.left) return "flex-start";
    return "center";
  }};
  align-items: center;
`;
const Engine = styled.div`
  margin-right: 1em;
  border-radius: 4px;
  display: flex;
  height: 80%;
  background-color: #0e1219;
  align-items: center;
`;
const Connection = styled.div`
  margin: 2px;
  border-radius: 4px;
  width: 3px;
  height: 90%;
  background-color: ${(props) => (props.connected ? "#49dea7" : "#de496b")};
`;
const EngineName = styled.div`
  padding-right: 0.5em;
  display: flex;
  align-items: center;
`;

const MultiViewIcon = styled(AiOutlineWindows)`
  font-size: 1.7em;
  margin-left: 0.5em;
`;

const KeyboardIcon = styled(FaRegKeyboard)`
  font-size: 1.7em;
  margin-left: 0.5em;
`;

const Popup = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;

const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;
const Error = styled.div`
  position: absolute;
  display: flex;
  background-color: #d81735;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  width: 600px;
  justify-content: center;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-right: 0.5em;
  left: calc((100vw / 2));
  top: 53px;
`;
const SignOut = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid red;
  padding: 8px;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    background-color: red;
  }
`;
export default function Header({ onChangeView, NCS }) {
  const { loggedIn, user, signOut } = useContext(UserContext);
  const { wsStatus, clear, connectEngine, usersOnAir } =
    useContext(ControlContext);
  const { view, setView } = useContext(UIContext);
  const [update, setUpdate] = useState(false);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [contextMenuItem, setContextMenuItem] = useState(null);

  function handleContextMenu(e, item) {
    setContextMenuItem({
      item: item,
      mouseX: e.clientX,
      mouseY: e.clientY,
      showContext: true,
    });
    e.preventDefault();
    window.addEventListener("click", handleClick);
  }

  function handleClick() {
    setContextMenuItem({
      ...contextMenuItem,
      showContext: false,
    });
    window.removeEventListener("click", handleClick);
  }

  return (
    <Main>
      {wsStatus === 0 && loggedIn && (
        <Error>
          You are not connected to the Server, Check your internet connection
        </Error>
      )}

      <Column left>
        {loggedIn && user && (
          <KeyboardIcon onClick={() => setShowShortcuts(true)} />
        )}

        <Button
          style={{ marginLeft: "5px" }}
          onClick={() => onChangeView("studio")}
        >
          Studio
        </Button>
        {!NCS && (
          <>
            {user?.features?.find((f) => f.name === "football") && (
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => onChangeView("football")}
              >
                Football
              </Button>
            )}
            {user?.features?.find((f) => f.name === "mlb") && (
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => onChangeView("mlb")}
              >
                MLB
              </Button>
            )}
            {user?.features?.find((f) => f.name === "rugby") && (
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => onChangeView("rugby")}
              >
                Rugby
              </Button>
            )}
            {user?.features?.find((f) => f.name === "golf") && (
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => onChangeView("golf")}
              >
                Golf
              </Button>
            )}
            {user?.features?.find((f) => f.name === "gaa") && (
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => onChangeView("gaa")}
              >
                GAA
              </Button>
            )}
            {user?.features?.find((f) => f.name === "basketball") && (
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => onChangeView("basketball")}
              >
                Basketball
              </Button>
            )}
            {user?.features?.find((f) => f.name === "management") && (
              <Button
                style={{ marginLeft: "5px" }}
                onClick={() => onChangeView("management")}
              >
                Management
              </Button>
            )}
          </>
        )}
      </Column>

      <Column>
        <HyperLogo src={logo} />
      </Column>
      {/* <Outputs>
        <Button
          onClick={() => {
            window.open(
              `http://localhost:3001?engine=true&project=SKY&preview=true&groupID=${window.id}`
            );
          }}
        >
          PREVIEW OUTPUT
        </Button>
        <Button
          onClick={() => {
            window.open(
              `http://localhost:3001?engine=true&project=SKY&preview=true&groupID=${window.id}`
            );
          }}
        >
          LIVE OUTPUT
        </Button>
      </Outputs> */}
      <Column right>
        {/* {loggedIn && user && user.roles.indexOf("Admin") !== -1 && (
          <div style={{ marginRight: "1em", display: "flex" }}>
            {usersOnAir.map((u) => {
              return (
                <ProfileEngine title={u.firstName + " " + u.familyName}>
                  {u.firstName[0]}
                  {u.familyName[0]}
                </ProfileEngine>
              );
            })}
          </div>
        )} */}
        {user && (
          <>
            {user.username}
            <SignOut
              onClick={() => {
                signOut();
              }}
            >
              Sign Out
            </SignOut>
          </>
        )}
      </Column>
      {contextMenuItem && contextMenuItem.showContext && (
        <Popup
          style={{
            position: "absolute",
            left: contextMenuItem.mouseX - 180,
            top: contextMenuItem.mouseY + 15,
          }}
        >
          <PopupGroup>
            <PopupItem
              onClick={() => {
                clear(contextMenuItem.item);
              }}
            >
              Clear Graphics
            </PopupItem>
          </PopupGroup>
        </Popup>
      )}
      {showShortcuts && (
        <Keyboard
          title={"Keyboard Shortcuts"}
          onClose={() => {
            setShowShortcuts(false);
          }}
        />
      )}
    </Main>
  );
}
