import React, { useState, useEffect } from "react";
import teamsData from "../data/teams.json";
import moment from "moment";

const OptaContext = React.createContext([{}, () => {}]);

const OptaProvider = (props) => {
  const [data, setData] = useState([]);
  const [teams, setTeams] = useState(teamsData);
  const [matchData, setMatchData] = useState([]);
  const [matchID, setMatchID] = useState();
  const [standings, setStandings] = useState();
  const [leagueID, setLeagueID] = useState(8);

  useEffect(() => {
    async function getOptaData() {
      let fetchJobs = [];
      return;
      fetchJobs.push(
        fetch("http://sky.hyperstudios.co.uk/opta?v=" + Date.now())
          .then((response) => response.json())
          .then((data) => {
            let newData = data.SoccerFeed.SoccerDocument.MatchData.filter(
              (match) => {
                try {
                  let now = moment().format("YYYY-MM-DD");

                  if (match.MatchInfo.Date["@value"]) {
                    if (match.MatchInfo.Date["@value"].indexOf(now) > -1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  if (
                    match.MatchInfo.Date &&
                    match.MatchInfo.Date.indexOf(now) > -1
                  ) {
                    return true;
                  }

                  return false;
                } catch (err) {
                  debugger;
                }
              }
            );

            setData(newData);

            // setTeams(data.SoccerFeed.SoccerDocument.Team);
          })
          .catch((err) => {
            console.log(err);
          })
      );

      fetchJobs.push(
        fetch(
          "http://sky.hyperstudios.co.uk/optaStandings/2020/" +
            leagueID +
            "?v=" +
            Date.now()
        )
          .then((response) => response.json())
          .then((data) => {
            try {
              let newData =
                data.SoccerFeed.SoccerDocument.Competition.TeamStandings
                  .TeamRecord;
              setStandings(newData);
            } catch (err) {}
          })
          .catch((err) => {
            console.log(err);
          })
      );

      await Promise.all(fetchJobs);
    }

    getOptaData();
    let id = setInterval(getOptaData, 30000);
    return () => {
      clearInterval(id);
    };
  }, [leagueID]);

  useEffect(() => {
    let id;
    if (matchID) {
      async function getOptaData() {
        let fetchJobs = [];
        return;
        fetchJobs.push(
          fetch(
            "http://sky.hyperstudios.co.uk/optaMatch/" +
              matchID +
              "?v=" +
              Date.now()
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.SoccerFeed) {
                setMatchData(data.SoccerFeed.SoccerDocument);
              } else {
                setMatchData({});
              }
            })
            .catch((err) => {
              console.log(err);
            })
        );
        await Promise.all(fetchJobs);
      }

      getOptaData();
      id = setInterval(getOptaData, 30000);
    }
    return () => {
      clearInterval(id);
    };
  }, [matchID]);

  return (
    <OptaContext.Provider
      value={{
        data,
        teams,
        matchID,
        setMatchID,
        matchData,
        standings,
        setLeagueID,
        leagueID,
      }}
    >
      {props.children}
    </OptaContext.Provider>
  );
};

export { OptaContext, OptaProvider };
