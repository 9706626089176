import React, { useState, useEffect } from "react";

const NeverNoContext = React.createContext([{}, () => {}]);
window.neverNoData = [];
window.neverNoID = 0;
const NeverNoProvider = (props) => {
  const [data, setData] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);

  async function getData() {
    let fetchJobs = [];
    let newData = [];

    if (window.neverNoID > 0) {
      fetchJobs.push(
        fetch(
          "http://skycg.hyperstudios.co.uk/neverno/" +
            window.neverNoID +
            "?v=" +
            Date.now(),
          {
            cache: "no-store",
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setDestinations(data.exports);
          })
          .catch((err) => {})
      );
    }
    if (window.neverNoDestination) {
      fetchJobs.push(
        fetch(
          "http://skycg.hyperstudios.co.uk/neverno/" +
            window.neverNoID +
            "/" +
            window.neverNoDestination +
            "?v=" +
            Date.now(),
          {
            cache: "no-store",
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setData(data);
            if (data && data.exports && data.exports.length > 0) {
              window.neverNoData = data.exports[0].items;
            } else {
              window.neverNoData = [];
            }
          })
          .catch((err) => {})
      );
    }

    await Promise.all(fetchJobs);

    setTimeout(getData, 5000);
  }

  useEffect(() => {
    getData();
  }, []);

  function start() {
    let index = 0;
    setSelectedItem(0);
    setInterval(() => {
      index++;
      setSelectedItem(index);
    }, 15000);
  }

  return (
    <NeverNoContext.Provider
      value={{
        data,
        start,
        selectedItem,
        destinations,
      }}
    >
      {props.children}
    </NeverNoContext.Provider>
  );
};

export { NeverNoContext, NeverNoProvider };
