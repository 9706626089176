import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { ResourcesContext } from "../../contexts/ResourcesContext";
import styled from "styled-components";
import { ScrollBar, Button } from "../../Theme/Hyper";
import { AiOutlineCloudUpload } from "react-icons/ai";
import ImageItem from "./ImageItem";
import NewFolder from "../NewFolder/NewFolder";
import { MdCreateNewFolder } from "react-icons/md";
import Popup from "../Popup/Popup";
import { FiList } from "react-icons/fi";
import { FaImages } from "react-icons/fa";
import { UserContext } from "../../contexts/UserContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImageViewer from "./ImageViewer";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;
const Content = styled.div`
  display: flex;
  height: 400px;
  background-color: #191f27;
`;

const ImageDiv = styled.div`
  min-width: 110px;
  margin: 1em;
  background-color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#0e1219"};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 60px;
  > img {
    max-width: 80px;
    max-height: 60px;
  }
`;
const ImageListDiv = styled.div`
  width: 100%;
  margin: 1px;
  background-color: ${(props) =>
    props.selected ? props.theme.highlightColour : "#0e1219"};
  border-radius: 4px;

  align-items: center;
  display: flex;
  height: 27px;
  font-size: 0.7em;
  padding-left: 0.5em;
`;

const UploadButton = styled(Button)`
  margin-top: auto;
  margin-left: auto;
  background-color: #0e1219;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1em;
  /* background-color: ${(props) => props.theme.secondaryBackgroundColour}; */
  :hover {
    color: ${(props) => props.theme.highlightColour};
  }
`;

const ImagesInner = styled(ScrollBar)`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  display: flex;
  place-content: flex-start;
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SelectedImage = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.7em;
  align-items: center;
  background-color: #0e1219;
  /* justify-content: center; */
  text-align: center;
  margin: 2px;
  width: 30%;
`;

const SelectedImageDiv = styled.div`
  margin: 1em;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 153px;
  font-size: 0.7em;
  > img {
    max-width: 249px;
    max-height: 145px;
  }
`;
const ActionPanel = styled.div`
  background-color: #0e1219;
  margin: 2px;
  margin-top: auto;
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  height: 30px;
`;

const FolderIcon = styled(MdCreateNewFolder)`
  :hover {
    color: ${(props) => props.theme.highlightColour};
  }
`;
const ListIcon = styled(FiList)`
  margin-right: 0.5em;
  color: ${(props) => (props.selected ? props.theme.highlightColour : "")};
  :hover {
    color: ${(props) => props.theme.highlightColour};
  }
`;
const ThumbnailIcon = styled(FaImages)`
  margin-right: 0.5em;
  color: ${(props) => (props.selected ? props.theme.highlightColour : "")};
  :hover {
    color: ${(props) => props.theme.highlightColour};
  }
`;

const PopupDiv = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;

const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;

const Row = styled.div`
  display: flex;
`;
export default function ImageEdit({ selectedEdit, preview }) {
  const {
    resources,
    uploadImage,
    selectedFolder,
    selectFolder,
    createNewFolder,
    deleteFolder,
    deleteImage,
  } = useContext(ResourcesContext);

  useEffect(() => {
    if (selectedEdit.src) {
      setSelectedImage(findImage(resources?.[0], selectedEdit.src));
      selectFolder("Root");
    } else {
      selectFolder("Root");
    }
  }, [selectedEdit, resources]);

  function findImage(item, name) {
    if (item?.name === name) {
      return item;
    }
    if (item?.children) {
      for (let i = 0; i < item.children.length; i++) {
        let f = item.children[i];
        let fold = findImage(f, name);
        if (fold) {
          return fold;
        } else if (fold && fold.children) {
          let child = findImage(fold, name);
          if (child) {
            return child;
          }
        }
      }
    }
  }
  const [update, setUpdate] = useState(false);
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showDeleteImagePopup, setShowDeleteImagePopup] = useState(false);
  const [contextMenuItem, setContextMenuItem] = useState(null);
  const [imageContextMenuItem, setImageContextMenuItem] = useState(null);
  const [folder, setFolder] = useState(null);
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [listView, setListView] = useState(false);
  const { user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const deleteFolderCallback = useCallback(() => {
    deleteFolder(folder);
    setShowDeletePopup(false);
  }, [folder]);

  const deleteImageCallback = useCallback(() => {
    deleteImage(image.name.replace(/ /g, "_"));
    setShowDeleteImagePopup(false);
  }, [image]);

  const inputRef = useRef();

  function handleAttachFile(e) {
    Array.from(e.target.files).forEach((file) => {
      uploadImage(file);
    });
  }

  function handleContextMenu(e, item) {
    setContextMenuItem({
      item: item,
      mouseX: e.clientX,
      mouseY: e.clientY,
      showContext: true,
    });
    setFolder(item.path);
    e.preventDefault();
    window.addEventListener("click", handleClick);
  }

  function handleClick() {
    setContextMenuItem({
      ...contextMenuItem,
      showContext: false,
    });
    setImageContextMenuItem({
      ...imageContextMenuItem,
      showContext: false,
    });
    window.removeEventListener("click", handleClick);
  }

  function handleImageContextMenu(e, item) {
    setImageContextMenuItem({
      item: item,
      mouseX: e.clientX,
      mouseY: e.clientY,
      showContext: true,
    });
    setImage(item);
    e.preventDefault();
    window.addEventListener("click", handleClick);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <Main>
      <Content>
        {
          // <Files>
          //   <ImageList>
          //     {resources?.map((resource, index) => {
          //       return (
          //         <ImageItem
          //           key={index}
          //           resource={resource}
          //           onContextMenu={(e, item) => {
          //             //  handleContextMenu(e, item);
          //           }}
          //         />
          //       );
          //     })}
          //   </ImageList>
          //   <ActionPanel>
          //     {/* <FolderIcon onClick={() => setShowNewFolder(true)} /> */}
          //     {/* <UploadButton
          //       onClick={() => {
          //         inputRef.current.click();
          //       }}
          //     >
          //       <AiOutlineCloudUpload />
          //     </UploadButton> */}
          //   </ActionPanel>
          // </Files>
        }
        <Images>
          <ImagesInner>
            <ImageViewer
              project={project}
              callback={(image, element) => {
                selectedEdit.image = image.src;
                const myImage = new Image();
                myImage.src = `${
                  window.ENV?.REACT_APP_PROJECT_SERVER ||
                  process.env.REACT_APP_PROJECT_SERVER
                }/api/assets/images/${project?.user?._id}/${encodeURIComponent(
                  selectedEdit?.image
                )}`;
                myImage.onload = function () {
                  let ratio = myImage.naturalWidth / myImage.naturalHeight;
                  selectedEdit.width = selectedEdit.height * ratio;

                  setSelectedImage(image);
                  preview();
                  setUpdate(!update);
                };
              }}
            />
          </ImagesInner>
        </Images>
        <SelectedImage>
          <SelectedImageDiv>
            <LazyLoadImage
              src={`${
                window.ENV?.REACT_APP_PROJECT_SERVER ||
                process.env.REACT_APP_PROJECT_SERVER
              }/api/assets/images/${project?.user?._id}/${encodeURIComponent(
                selectedEdit?.image || selectedEdit?.src
              )}`}
              alt={selectedEdit?.image}
            />
          </SelectedImageDiv>
          <Row>{selectedImage?.name}</Row>
          <Row> {formatBytes(selectedImage?.size)}</Row>
        </SelectedImage>
      </Content>

      <input
        ref={inputRef}
        type="file"
        multiple
        accept="image/*"
        onChange={handleAttachFile}
        style={{ display: "none" }}
      />
      {showNewFolder && (
        <NewFolder
          onSave={(value) => {
            createNewFolder(value);
            setShowNewFolder(false);
          }}
          onCancel={() => {
            setShowNewFolder(false);
          }}
        />
      )}
      {showDeletePopup && (
        <Popup
          title={"Delete Folder"}
          message={"DELETING THIS FOLDER WILL ALSO DELETE ITS CONTENTS!!"}
          onSave={deleteFolderCallback}
          onCancel={() => {
            setShowDeletePopup(false);
          }}
        />
      )}

      {contextMenuItem && contextMenuItem.showContext && (
        <PopupDiv
          style={{
            position: "absolute",
            left: contextMenuItem.mouseX + 10,
            top: contextMenuItem.mouseY,
          }}
        >
          <PopupGroup>
            <PopupItem
              onClick={() => {
                setShowDeletePopup(true);
              }}
            >
              Delete
            </PopupItem>
          </PopupGroup>
        </PopupDiv>
      )}

      {showDeleteImagePopup && (
        <Popup
          title={"Delete Image"}
          message={
            "THIS WILL DELETE THE IMAGE BE CAREFUL IF IT'S USED IN OTHER PAGES!!"
          }
          onSave={deleteImageCallback}
          onCancel={() => {
            setShowDeleteImagePopup(false);
          }}
        />
      )}

      {imageContextMenuItem && imageContextMenuItem.showContext && (
        <PopupDiv
          style={{
            position: "absolute",
            left: imageContextMenuItem.mouseX + 10,
            top: imageContextMenuItem.mouseY,
          }}
        >
          <PopupGroup>
            <PopupItem
              onClick={() => {
                setShowDeleteImagePopup(true);
              }}
            >
              Delete
            </PopupItem>
          </PopupGroup>
        </PopupDiv>
      )}
    </Main>
  );
}
