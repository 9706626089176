import React, { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

const LOGGED_IN = gql`
  query LoggedIn {
    me {
      _id
      username
      token
      roles {
        name
      }
      features {
        name
      }
    }
  }
`;

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      _id
    }
  }
`;

const LOGIN_WITH_MICROSOFT = gql`
  mutation LoginWithMicrosoft($token: String!) {
    loginWithMicrosoft(token: $token) {
      token
      _id
    }
  }
`;

const UserContext = React.createContext([{}, () => {}]);

const UserProvider = (props) => {
  const { data: loggedInUser } = useQuery(LOGGED_IN, {
    fetchPolicy: "network-first",
  });
  const [loginMutation, { data }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      localStorage.setItem("user-id", data.login._id);
      localStorage.setItem("token", data.login.token);

      window.groups = [
        {
          name: data.login._id,
          status: 1,
        },
      ];

      setUserToken(data.login.token);
      debugger;
      setLoggedIn(true);
    },
  });
  const [loginWithMicrosoftMutation, { data: data_microsoft }] = useMutation(
    LOGIN_WITH_MICROSOFT,
    {
      onCompleted: (data) => {
        localStorage.setItem("user-id", data.loginWithMicrosoft._id);
        localStorage.setItem("token", data.loginWithMicrosoft.token);

        window.groups = [
          {
            name: data.loginWithMicrosoft._id,
            status: 1,
          },
        ];

        setUserToken(data.loginWithMicrosoft.token);
        debugger;
        setLoggedIn(true);
      },
    }
  );
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState();
  const [signinError, setSigninError] = useState();
  const [userCollection, setUserCollection] = useState([]);
  const [token, setToken] = useState();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (isAuthenticated) {
      const currentAccount = instance.getActiveAccount();

      if (currentAccount) {
        const accessTokenRequest = {
          scopes: ["User.Read"],
          account: currentAccount,
        };

        instance
          .acquireTokenSilent(accessTokenRequest)
          .then(function (accessTokenResponse) {
            // Acquire token silent success
            // Call API with token

            let accessToken = accessTokenResponse.idToken;
            loginWithMicrosoftMutation({ variables: { token: accessToken } });

            // Call your API with token
            // callApi(accessToken);
          })
          .catch(function (error) {
            //Acquire token silent failure, and send an interactive request
          });
      }
    }
  }, [instance, isAuthenticated, setUser]);

  useEffect(() => {
    if (loggedInUser?.me) {
      window.groups = [
        {
          name: loggedInUser.me._id,
          status: 1,
        },
      ];

      setUserToken(loggedInUser.me.token);
      debugger;
      setLoggedIn(true);
    }
  }, [loggedInUser]);

  function login({ username, password }) {
    loginMutation({ variables: { username, password } });
  }

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  function setUserToken(token) {
    let new_user = parseJwt(token);

    if (new_user?.features?.find((f) => f.name === "multi_user")) {
      window.random = "_" + Math.floor(Math.random() * 10000000);
    } else {
      window.random = "";
    }

    setUser(new_user);
    setToken(token);
  }

  function signOut() {
    try {
      var football_iframe =
        window.document.getElementById("football").contentWindow;

      football_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_FOOTBALL_UI || window.ENV?.REACT_APP_FOOTBALL_UI
      );
    } catch (err) {
      console.log("couldnt logout football");
    }
    try {
      var basketball_iframe =
        window.document.getElementById("basketball").contentWindow;

      basketball_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_BASKETBALL_UI ||
          window.ENV?.REACT_APP_BASKETBALL_UI
      );
    } catch (err) {
      console.log("couldnt logout basketball");
    }
    try {
      var rugby_iframe = window.document.getElementById("rugby").contentWindow;

      rugby_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_RUGBY_UI || window.ENV?.REACT_APP_RUGBY_UI
      );
    } catch (err) {
      console.log("couldnt logout rugby");
    }
    try {
      var gaa_iframe = window.document.getElementById("gaa").contentWindow;

      gaa_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_GAA_UI || window.ENV?.REACT_APP_GAA_UI
      );
    } catch (err) {
      console.log("couldnt logout gaa");
    }
    try {
      var management_iframe =
        window.document.getElementById("management").contentWindow;

      management_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        window.ENV?.REACT_APP_MANAGEMENT_UI ||
          process.env.REACT_APP_MANAGEMENT_UI
      );
    } catch (err) {
      console.log("couldnt logout management");
    }
    try {
      var mlb_iframe = window.document.getElementById("mlb").contentWindow;

      mlb_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_MLB_UI || window.ENV?.REACT_APP_MLB_UI
      );
    } catch (err) {
      console.log("couldnt logout MLB");
    }
    try {
      var golf_iframe = window.document.getElementById("golf").contentWindow;

      golf_iframe.postMessage(
        JSON.stringify({
          event: "logout",
        }),
        process.env.REACT_APP_GOLF_UI || window.ENV?.REACT_APP_GOLF_UI
      );
    } catch (err) {
      console.log("couldnt logout golf");
    }

    localStorage.removeItem("user-id");
    localStorage.removeItem("token");
    try {
      instance["browserStorage"].clear();
    } catch (err) {}

    window.location.reload();
  }

  return (
    <UserContext.Provider
      value={{
        user,
        loggedIn,
        userCollection,
        login,
        signinError,
        signOut,
        token,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
