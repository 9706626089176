import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ChonkyActions,
  FileHelper,
  FullFileBrowser,
} from "@ashleyhyper/chonky";
import "./ImageViewer.css";

export default function ImageViewer({ callback, dnd, project }) {
  const [images, setImages] = useState([]);
  const [userId, setUserId] = useState();
  const [folders, setFolders] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [currentFolderId, setCurrentFolderId] = useState("#");
  const files = useFiles({ currentFolderId, images, folders });
  const folderChain = useFolderChain({ currentFolderId, images });
  const createFolder = useCallback(
    (folderName) => {
      let new_images = JSON.parse(JSON.stringify(images));
      let currentFolder = new_images[currentFolderId];
      let new_folder_id =
        "#" + (currentFolder.path + folderName)?.replace(/\//g, "#");
      new_images[new_folder_id] = {
        isDir: true,
        name: folderName,
        id: new_folder_id,
        selectable: false,
        childrenIds: [],
        draggable: false,
        droppable: true,
        parentId: currentFolderId,
        path: currentFolder.path + folderName + "/",
      };
      currentFolder.childrenIds.push(new_folder_id);
      console.log(currentFolder);
      console.log(new_images[new_folder_id]);
      setImages(new_images);
    },
    [currentFolderId, images]
  );

  const deleteImage = useCallback(
    (images) => {
      let promises = [];
      for (let i = 0; i < images.length; i++) {
        let image = images[i];
        promises.push(
          fetch(
            `${
              window.ENV?.REACT_APP_PROJECT_SERVER ||
              process.env.REACT_APP_PROJECT_SERVER
            }/api/assets/images/${
              project?.user?._id
            }/${window.encodeURIComponent(image.path + image.name)}`,
            {
              method: "DELETE",
            }
          ).catch((err) => {})
        );
      }

      Promise.all(promises).then((response) => {
        getImages(project?.user?._id);
      });
    },
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    [currentFolderId]
  );

  const moveFiles = useCallback(
    (files, src, dst) => {
      let user = localStorage.getItem("user-id");
      let promises = [];
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let src_filename = file.path + file.name;
        let dest_filename = dst.path + file.name;
        promises.push(
          fetch(
            `${
              window.ENV?.REACT_APP_PROJECT_SERVER ||
              process.env.REACT_APP_PROJECT_SERVER
            }/api/assets/images/move/${user}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ src_filename, dest_filename }),
            }
          ).catch((err) => {})
        );
      }
      Promise.all(promises).then(() => {
        getImages(project?.user?._id);
      });
    },
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
    [currentFolderId]
  );

  const handleFileAction = useFileActionHandler(
    setCurrentFolderId,
    setSelectedFile,
    createFolder,
    deleteImage,
    moveFiles
  );
  useEffect(() => {
    getImages(project?.user?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (selectedFile) {
      callback({
        src: `${selectedFile?.path + selectedFile.name}`,
        size: selectedFile.size,
        name: selectedFile.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const getImages = useCallback(
    (user) => {
      fetch(
        `${
          window.ENV?.REACT_APP_PROJECT_SERVER ||
          process.env.REACT_APP_PROJECT_SERVER
        }/api/assets/images/${user}`
      )
        .then((response) => response.json())
        .then((data) => {
          let new_images = {};

          new_images["#"] = {
            isDir: true,
            name: "Project",
            id: "#",
            selectable: false,
            childrenIds: [],
            draggable: false,
            droppable: true,
            path: "",
          };
          for (let i = 0; i < data.length; i++) {
            let image = data[i];
            let id = image?.name?.replace(/\//g, "#");
            let path = image.name
              ?.substring(0, image.name.lastIndexOf("/") + 1)
              ?.split("/");
            path = path?.filter((p) => p !== "");
            new_images[id] = {
              id,
              path: image.name?.substring(0, image.name.lastIndexOf("/") + 1),
              name: image.name.replace(/^.*[\\\/]/, ""),
              url: `${
                window.ENV?.REACT_APP_PROJECT_SERVER ||
                process.env.REACT_APP_PROJECT_SERVER
              }/api/assets/images/${user}/${image.name?.replace(/\//g, "%2F")}`,
              thumbnailUrl: `${
                window.ENV?.REACT_APP_PROJECT_SERVER ||
                process.env.REACT_APP_PROJECT_SERVER
              }/api/assets/images/thumbnail/${user}/${image.name?.replace(
                /\//g,
                "%2F"
              )}`,
              parentId:
                image.name
                  ?.substring(0, image.name.lastIndexOf("/"))
                  ?.replace(/\//g, "#") || "#",
              size: parseInt(image.size),
              modDate: image.last_updated,
              draggable: true,
              droppable: false,
            };

            let folder_path = "";
            let prev_id = "";
            if (path.length === 0) {
              new_images["#"].childrenIds.push(id);
            }

            for (let x = 0; x < path.length; x++) {
              let folder = path[x];
              folder_path += folder;
              let folder_id = ("#" + folder_path)?.replace(/\//g, "#");

              if (x === 0) {
                new_images["#"].childrenIds.push(folder_id);
              } else {
                new_images[prev_id].childrenIds.push(folder_id);
              }
              folder_path += "/";
              new_images[folder_id] = {
                isDir: true,
                name: folder,
                id: folder_id,
                path: folder_path,
                parentId: prev_id || "#",
                childrenIds: [...(new_images[folder_id]?.childrenIds || [])],
                selectable: false,
                draggable: false,
                droppable: true,
              };
              if (x === path.length - 1) {
                new_images[folder_id].childrenIds.push(id);
              }
              prev_id = folder_id;
            }
          }

          if (!new_images[currentFolderId]) {
            setCurrentFolderId(images[currentFolderId].parentId);
          }
          setImages(new_images);
        });
    },
    [currentFolderId, images]
  );

  function upload(e, currentFolderId) {
    let user = localStorage.getItem("user-id");
    const files = Array.from(e.target.files);

    const currentFolder = images?.[currentFolderId];
    let promises = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      const formData = new FormData();
      formData.append("file", file);
      formData.append(file.name, currentFolder.path + file.name);
      promises.push(
        fetch(
          `${
            window.ENV?.REACT_APP_PROJECT_SERVER ||
            process.env.REACT_APP_PROJECT_SERVER
          }/api/assets/images/${user}`,
          {
            method: "POST",
            body: formData,
          }
        ).catch((err) => {})
      );
    }
    Promise.all(promises).then(() => {
      getImages(user);
    });
  }

  const myFileActions = [
    ChonkyActions.UploadFiles,
    ChonkyActions.CreateFolder,
    ChonkyActions.DeleteFiles,
  ];
  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <FullFileBrowser
        files={files}
        folderChain={folderChain}
        onFileAction={handleFileAction}
        fileActions={myFileActions}
        disableDragAndDrop={dnd}
      />
      <input
        id="image-input"
        type="file"
        name="image"
        accept="image/*"
        multiple
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e, currentFolderId);
        }}
      />
    </div>
  );
}

const useFiles = ({ images, currentFolderId }) => {
  return useMemo(() => {
    const currentFolder = images[currentFolderId];
    const files = currentFolder?.childrenIds
      ? currentFolder.childrenIds.map((fileId) => images[fileId] ?? null)
      : [];
    return files;
  }, [currentFolderId, images]);
};

const useFolderChain = ({ currentFolderId, images }) => {
  return useMemo(() => {
    const currentFolder = images?.[currentFolderId];

    const folderChain = [currentFolder];

    let parentId = currentFolder?.parentId;
    while (parentId) {
      const parentFile = images[parentId];
      if (parentFile) {
        folderChain.unshift(parentFile);
        parentId = parentFile.parentId;
      } else {
        parentId = null;
      }
    }

    return folderChain;
  }, [currentFolderId, images]);
};

const useFileActionHandler = (
  setCurrentFolderId,
  setSelectedFile,
  createFolder,
  deleteImage,
  moveFiles
) => {
  return useCallback(
    (data) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        const { targetFile, files } = data.payload;
        const fileToOpen = targetFile ?? files[0];
        if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
          setCurrentFolderId(fileToOpen.id);
          return;
        } else {
          setSelectedFile(fileToOpen);
        }
      }
      if (data.id === ChonkyActions.UploadFiles.id) {
        document.getElementById("image-input").click();
      } else if (data.id === ChonkyActions.CreateFolder.id) {
        const folderName = prompt("Provide the name for your new folder:");
        if (folderName) createFolder(folderName);
      } else if (data.id === ChonkyActions.DeleteFiles.id) {
        deleteImage(data.state.selectedFiles);
      } else if (data.id === ChonkyActions.MoveFiles.id) {
        moveFiles(
          data.payload.files,
          data.payload.source,
          data.payload.destination
        );
      }

      //showActionNotification(data);
    },
    [setCurrentFolderId, setSelectedFile, createFolder, deleteImage, moveFiles]
  );
};
