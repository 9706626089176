import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";

import PubNub from "pubnub";
import { ControlContext } from "./ControlContext";
import { ProjectContext } from "./ProjectContext";

let pubnub;

async function getData() {
  if (window.program) {
    let fetchJobs = [];
    let newData = [];
    fetchJobs.push(
      fetch(
        "https://cf-blast.livelikecdn.com/api/v1/programs/" +
          window.program.id +
          "/widgets/?status=pending&v=" +
          Date.now(),
        { cache: "no-store" }
      )
        .then((response) => response.json())
        .then((data) => {
          data.results
            .filter((i) => {
              let duration = parseInt(i.timeout.split("M")[1].replace("S", ""));
              let mins = i.timeout.split("H")[1].split("M")[0].replace("M", "");
              if (mins) {
                duration += 60 * mins;
              }
              let diff = Date.now() - Date.parse(i.published_at);
              return (
                (diff < duration * 1000 || i.status === "pending") &&
                i.kind.indexOf("follow-up") === -1
              );
            })
            .filter((i) => {
              if (
                i.kind &&
                (i.kind === "text-poll" ||
                  i.kind === "image-poll" ||
                  i.kind === "text-quiz" ||
                  i.kind === "image-quiz" ||
                  i.kind === "text-prediction" ||
                  i.kind === "image-prediction")
              ) {
                return true;
              }
              return false;
            })
            .forEach((a) =>
              newData.push({
                ...a,
                options: a.options || a.choices,
              })
            );
        })
        .catch((err) => {})
    );
    fetchJobs.push(
      fetch(
        "https://cf-blast.livelikecdn.com/api/v1/programs/" +
          window.program.id +
          "/widgets/?ordering=recent&status=published&v=" +
          Date.now(),
        { cache: "no-store" }
      )
        .then((response) => response.json())
        .then((data) => {
          data.results
            .filter((i) => {
              let duration = parseInt(i.timeout.split("M")[1].replace("S", ""));
              let mins = i.timeout.split("H")[1].split("M")[0].replace("M", "");
              if (mins) {
                duration += 60 * mins;
              }
              let diff = Date.now() - Date.parse(i.published_at);
              return diff < duration * 1000;
            })
            .filter((i) => {
              if (
                i.kind &&
                (i.kind === "text-poll" ||
                  i.kind === "image-poll" ||
                  i.kind === "text-quiz" ||
                  i.kind === "image-quiz" ||
                  i.kind === "text-prediction" ||
                  i.kind === "image-prediction")
              ) {
                return true;
              }
              return false;
            })

            .forEach((a) =>
              newData.push({
                ...a,
                options: a.options || a.choices,
              })
            );
        })
        .catch((err) => {})
    );
    await Promise.all(fetchJobs);
    window.liveLikeData = newData;
  }
  setTimeout(getData, 100);
}

getData();

const LiveLikeContext = React.createContext([{}, () => {}]);

const LiveLikeProvider = (props) => {
  const [item, setItem] = useState();
  const [itemData, setItemData] = useState();
  const [program, selectProgram] = useState();
  const [data, setData] = useState([]);
  const [startTime, setStartTime] = useState();

  const [programs, setPrograms] = useState([]);
  const [options, setOptions] = useState([]);
  const [newPoll, setNewPoll] = useState([]);
  const [previous, setPrevious] = useState();
  const [showingOptions, setShowingOptions] = useState(false);
  const { triggerAnimation, scenesOnAir } = useContext(ControlContext);
  const { edits } = useContext(ProjectContext);
  const [subscribedTo, setSubscribedTo] = useState([]);
  const [latestData, setLatestData] = useState();

  const selectPoll = useCallback(
    (id) => {
      let i = data.find((item) => item.id === id);

      if (i) {
        setPrevious(itemData);
        window.pollID = i.id;
        setItem(i.id);
        setItemData(i);
        setStartTime(null);
        if (pubnub) {
          pubnub.unsubscribe({
            channels: subscribedTo.filter((s) => {
              return (
                s !== i.subscribe_channel &&
                s !== window.program.subscribe_channel
              );
            }),
          });
          pubnub.subscribe({
            channels: [i.subscribe_channel, window.program.subscribe_channel],
          });
        }
      } else {
        window.pollID = null;
        setPrevious(itemData);
        setItem(null);
        setOptions([]);
      }
    },
    [data, subscribedTo]
  );

  // useEffect(() => {
  //   if (previous) {
  //     if (pubnub) {
  //       pubnub.unsubscribe({
  //         channels: [previous.subscribe_channel],
  //       });
  //     }
  //   }
  // }, [data, previous]);

  useEffect(() => {
    let newData = [...data];
    let i = newData.find((item) => item.id === newPoll.id);
    if (i && !i.published_at_hyper) {
      i.published_at_hyper = newPoll.published_at_hyper;
      setData(newData);
    }
  }, [newPoll, data]);

  useEffect(() => {
    function onMessage(event) {
      console.log("LIVELIKE EVENT", JSON.stringify(event));
      setLatestData(JSON.stringify(event));
      if (
        event.message.event === "text-poll-created" ||
        event.message.event === "text-quiz-created" ||
        event.message.event === "image-quiz-created" ||
        event.message.event === "image-poll-created" ||
        event.message.event === "text-prediction-created" ||
        event.message.event === "image-prediction-created"
      ) {
        let now = new Date().toString();
        setNewPoll({
          ...event.message.payload,
          published_at_hyper: now,
          options:
            event.message.payload.options || event.message.payload.choices,
        });
        setStartTime(now);
      }
      if (
        event.message.event === "text-poll-results" ||
        event.message.event === "text-quiz-results" ||
        event.message.event === "image-poll-results" ||
        event.message.event === "image-quiz-results" ||
        event.message.event === "text-prediction-results" ||
        event.message.event === "image-prediction-results"
      ) {
        let newOptions = [];

        if (window.pollID && event.channel.split(".")[2]) {
          if (event.message.payload.options) {
            newOptions = event.message.payload.options.map((op) => {
              return {
                ...op,
                vote_count: op.vote_count || op.answer_count || 0,
                options: event.message.payload.options,
              };
            });
          } else if (event.message.payload.choices) {
            newOptions = event.message.payload.choices.map((op) => {
              return {
                ...op,
                vote_count: op.answer_count || 0,
                options: event.message.payload.choices,
              };
            });
          }
          setOptions(newOptions);
        }
      }
    }
    fetch(
      "https://cf-blast.livelikecdn.com/api/v1/applications/cD44lx6Vu9Zw3d9akZGQEOVslgQpnzajooaUGRdD/"
    )
      .then((response) => response.json())
      .then((data) => {
        pubnub = new PubNub({
          publishKey: data.pubnub_publish_key,
          subscribeKey: data.pubnub_subscribe_key,
          uuid: data.client_id,
        });
        pubnub.addListener({
          message: onMessage,
          status: function (event) {
            if (event.operation === "PNSubscribeOperation") {
              setSubscribedTo(event.subscribedChannels);
            }
            console.log("PUBNUB STATUS", event);
          },
        });
      });

    let id = setInterval(() => {
      if (window.liveLikeData) {
        setData(window.liveLikeData);
      }
    }, 500);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    getPrograms();
  }, [program]);

  function getPrograms() {
    fetch(
      "https://cf-blast.livelikecdn.com/api/v1/programs/?client_id=cD44lx6Vu9Zw3d9akZGQEOVslgQpnzajooaUGRdD"
    )
      .then((response) => response.json())
      .then((data) => {
        setPrograms(data.results);
      });
  }

  function setProgram(program) {
    if (window.program) {
      if (pubnub) {
        pubnub.unsubscribe({
          channels: [window.program.subscribe_channel],
        });
      }
    }

    window.program = program;
    selectProgram(program);
    if (pubnub) {
      pubnub.subscribe({
        channels: [program.subscribe_channel],
      });
    }
  }

  return (
    <LiveLikeContext.Provider
      value={{
        data,
        selectPoll,
        item,
        programs,
        setProgram,
        options,
        program,
        startTime,
        subscribedTo,
        latestData,
      }}
    >
      {props.children}
    </LiveLikeContext.Provider>
  );
};

export { LiveLikeContext, LiveLikeProvider };
