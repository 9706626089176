import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const EditArea = styled.div`
  background-color: #191f27;
`;
const TextEditor = styled.textarea`
  background-color: #191f27;
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  width: 80%;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
  height: 98px;
  outline: none;
`;

const Label = styled.div`
  background-color: #191f27;
  margin: 0.5em;
  min-width: 100px;
`;
const Property = styled.div`
  display: flex;
  align-items: center;
`;
const TextInput = styled.input`
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
  min-width: 500px;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #373b48;
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

export default function TwitterEdit({
  selectedEdit,
  preview,
  selectedScene,
  edits,
}) {
  const [update, setUpdate] = useState(false);
  const [url, setUrl] = useState("");
  const textRef = useRef();

  useEffect(() => {
    textRef.current.select();
  }, [selectedEdit]);

  function handleKeyDown(e) {
    if (e.keyCode >= 96 && e.keyCode <= 105) {
      e.preventDefault();
    } else if (e.keyCode === 13 && e.location === 3) {
      e.preventDefault();
    } else if (e.keyCode === 110) {
      e.preventDefault();
    }
  }

  function fetchData() {
    fetch(
      "http://" +
        window.location.hostname +
        ":6060/tweet/" +
        "?token=" +
        window.token,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: url.split("/").reverse()[0] || url,
        }),
      }
    )
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        try {
          edits.find((edit) => edit.control.name === "FIRST_NAME").text =
            data.user.name;
          edits.find((edit) => edit.control.name === "LINE 1").text =
            "@" + data.user.screen_name;
          edits.find((edit) => edit.control.name === "LINE 2").text =
            data.full_text;
          preview();
          setUpdate(!update);
        } catch (err) {}
      });
  }

  return (
    <EditArea>
      <Property>
        <Label>Twitter</Label>
        <TextInput
          ref={textRef}
          placeholder="url"
          value={url}
          onChange={(e) => setUrl(e.currentTarget.value)}
        />
      </Property>
      <Property>
        <Button onClick={fetchData}>Update Data</Button>
      </Property>
    </EditArea>
  );
}
