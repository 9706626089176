import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import "./App.css";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";
import styled, { ThemeProvider } from "styled-components";
import EditList from "./components/Edits/EditList";
import Stage from "./components/Stage/Stage";
import { ProjectProvider, ProjectContext } from "./contexts/ProjectContext";
import { ControlProvider, ControlContext } from "./contexts/ControlContext";
import Editor from "./components/Editor/Editor";
import Control from "./components/Control/Control";
import Hyper, { Button } from "./Theme/Hyper";
import { ResourcesProvider } from "./contexts/ResourcesContext";
import Loading from "./components/Loading/Loading";
import Save from "./components/Save/Save";
import { UIProvider, UIContext } from "./contexts/UIContext";
import MultiViewer from "./components/MultiViewer/MultiViewer";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AdminHeader from "./components/Header/AdminHeader";
import { UserProvider, UserContext } from "./contexts/UserContext";
import { OptaProvider } from "./contexts/OptaContext";
import { NeverNoProvider } from "./contexts/NeverNoContext";
import { OptaCricketProvider } from "./contexts/OptaCricketContext";
import { RenderProvider } from "./contexts/RenderContext";
import { MsalProvider } from "@azure/msal-react";
// import NCS from "./components/NCS/NCS";
// import { MOSProvider } from "./contexts/MOSContext";

const Row = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "")};
`;

const ButtonRow = styled.div`
  display: flex;
  margin: 0.5em;
  justify-content: ${(props) => (props.center ? "center" : "")};
`;
const PageButton = styled(Button)`
  margin-right: 5px;
  cursor: pointer;
  :hover {
    background-color: #d20a3e;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

function Main({ project, children }) {
  const {
    edits,
    selectedScene,
    selectScene,
    nextEdit,
    selectedEdit,
    savePage,
    pages,
    nextPage,
    nextPageInPlaylist,
    selectedPage,
    clear,
    selectPageNumber,
  } = useContext(ProjectContext);
  const { view, setPageNumber, pageNumber, setView } = useContext(UIContext);
  const {
    animate,
    animateOff,
    scenesOnAir,
    clearPreview,
    playlistOn,
    setPlaylistOn,
  } = useContext(ControlContext);
  const { user, token } = useContext(UserContext);

  const [saving, setSaving] = useState(false);
  const [savingNextAvailable, setSavingNextAvailable] = useState(false);
  const playlistTimeout = useRef(null); //now you can pass timer to another component

  const triggerPlaylist = useCallback(
    (prevPage) => {
      if (playlistOn) {
        let playlistItem = nextPageInPlaylist(prevPage.number);
        if (playlistItem && playlistItem.page) {
          playlistTimeout.current = setTimeout(() => {
            animate(
              playlistItem.page.template.name,
              playlistItem.edits,
              playlistItem.page.template.data.transition_layer,
              playlistItem.page.engine
            );
            triggerPlaylist(playlistItem.page);
          }, parseFloat(prevPage.duration) * 1000);
        } else {
          playlistTimeout.current = setTimeout(() => {
            animateOff(prevPage.template.name, [], prevPage?.engine);
            setPlaylistOn(false);
          }, parseFloat(prevPage.duration) * 1000);
        }
      }
    },
    [playlistOn, nextPageInPlaylist]
  );

  useEffect(() => {
    if (playlistOn === false) {
      clearTimeout(playlistTimeout.current);
    }
  }, [playlistOn]);

  useEffect(() => {
    try {
      let iframe;
      if (view === 1) {
        iframe = window.document.getElementById("rugby");
      }
      if (view === 2) {
        iframe = window.document.getElementById("football");
      }
      if (view === 3) {
        iframe = window.document.getElementById("golf");
      }
      if (view === 4) {
      }
      if (view === 5) {
        iframe = window.document.getElementById("management");
      }
      if (view === 6) {
        iframe = window.document.getElementById("mlb");
      }
      if (view === 7) {
        iframe = window.document.getElementById("gaa");
      }
      if (view === 8) {
        iframe = window.document.getElementById("basketball");
      }
      if (iframe) {
        iframe.contentWindow.focus();
      }
    } catch (err) {}
  }, [view]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (!user) {
        return;
      }
      if (e.keyCode >= 96 && e.keyCode <= 105) {
        if (!saving) {
          let number = pageNumber || "";
          setPageNumber(number + "" + (e.keyCode - 96));
        }
      } else if (e.keyCode === 81 && e.ctrlKey) {
        //Ctrl Q - clear preview
        clear();
        clearPreview();
      } else if (e.keyCode === 13 && e.location === 3) {
        e.preventDefault();
        if (!saving) {
          selectPageNumber(pageNumber);
        }
      } else if (e.keyCode === 110) {
        if (!saving) {
          setPageNumber(null);
        }
      } else if (e.keyCode === 119 || e.keyCode === 109) {
        if (selectedScene) {
          animate(
            selectedScene.name,
            edits,
            selectedScene.data.transition_layer,
            selectedPage?.engine
          );
          if (playlistOn) {
            triggerPlaylist(selectedPage);
          } else {
            selectScene(null);
          }
        }
        e.preventDefault();
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        if (scenesOnAir.length > 0) {
          let sceneName = scenesOnAir[scenesOnAir.length - 1];

          animateOff(sceneName.name, [], sceneName?.engine);
        }

        e.preventDefault();
      } else if (e.keyCode === 9) {
        nextEdit();
        e.preventDefault();
      } else if (e.keyCode === 83 && e.ctrlKey && !e.shiftKey) {
        //save
        setSavingNextAvailable(false);
        if (selectedPage || selectedScene) {
          setSaving(true);
        }
        e.preventDefault();
      } else if (e.keyCode === 83 && e.ctrlKey && e.shiftKey) {
        ///save as
        setSavingNextAvailable(true);
        if (selectedPage || selectedScene) {
          setSaving(true);
        }
        e.preventDefault();
      } else if (e.keyCode === 109) {
      } else if (e.keyCode === 107) {
        e.preventDefault();
        if (pageNumber) {
          selectPageNumber(pageNumber);
        } else {
          nextPage();
        }

        setPageNumber(null);
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    pageNumber,
    selectedScene,
    edits,
    selectedEdit,
    pages,
    scenesOnAir,
    saving,
    playlistOn,
  ]);

  useEffect(() => {
    if (user && token) {
      loginIframes({ token: token, id: user._id });
    }
  }, [user, token]);
  function loginIframes({ token, id }) {
    try {
      var football_iframe = window.document.getElementById("football");
      football_iframe.onload = () => {
        football_iframe = football_iframe.contentWindow;
        football_iframe.postMessage(
          JSON.stringify({
            event: "login",
            data: { token, id },
          }),
          window.ENV?.REACT_APP_FOOTBALL_UI || process.env.REACT_APP_FOOTBALL_UI
        );
      };
    } catch (err) {
      console.log("couldnt login football", err);
    }
    try {
      var rugby_iframe = window.document.getElementById("rugby");
      rugby_iframe.onload = () => {
        rugby_iframe = rugby_iframe.contentWindow;
        rugby_iframe.postMessage(
          JSON.stringify({
            event: "login",
            data: { token, id },
          }),
          window.ENV?.REACT_APP_RUGBY_UI || process.env.REACT_APP_RUGBY_UI
        );
      };
    } catch (err) {
      console.log("couldnt login rugby");
    }
    try {
      var gaa_iframe = window.document.getElementById("gaa");
      gaa_iframe.onload = () => {
        gaa_iframe = gaa_iframe.contentWindow;
        gaa_iframe.postMessage(
          JSON.stringify({
            event: "login",
            data: { token, id },
          }),
          window.ENV?.REACT_APP_GAA_UI || process.env.REACT_APP_GAA_UI
        );
      };
    } catch (err) {
      console.log("couldnt login gaa");
    }
    try {
      var management_iframe = window.document.getElementById("management");
      management_iframe.onload = () => {
        management_iframe = management_iframe.contentWindow;
        management_iframe.postMessage(
          JSON.stringify({
            event: "login",
            data: { token, id },
          }),
          window.ENV?.REACT_APP_MANAGEMENT_UI ||
            process.env.REACT_APP_MANAGEMENT_UI
        );
      };
    } catch (err) {
      console.log("couldnt login management");
    }
    try {
      var mlb_iframe = window.document.getElementById("mlb");
      mlb_iframe.onload = () => {
        mlb_iframe = mlb_iframe.contentWindow;
        mlb_iframe.postMessage(
          JSON.stringify({
            event: "login",
            data: { token, id },
          }),
          window.ENV?.REACT_APP_MLB_UI || process.env.REACT_APP_MLB_UI
        );
      };
    } catch (err) {
      console.log("couldnt login MLB");
    }
    try {
      var golf_iframe = window.document.getElementById("golf");
      golf_iframe.onload = () => {
        golf_iframe = golf_iframe.contentWindow;
        golf_iframe.postMessage(
          JSON.stringify({
            event: "login",
            data: { token, id },
          }),
          window.ENV?.REACT_APP_GOLF_UI || process.env.REACT_APP_GOLF_UI
        );
      };
    } catch (err) {
      console.log("couldnt login golf");
    }
    try {
      var basketball_iframe = window.document.getElementById("basketball");
      basketball_iframe.onload = () => {
        basketball_iframe = basketball_iframe.contentWindow;
        basketball_iframe.postMessage(
          JSON.stringify({
            event: "login",
            data: { token, id },
          }),
          window.ENV?.REACT_APP_BASKETBALL_UI ||
            process.env.REACT_APP_BASKETBALL_UI
        );
      };
    } catch (err) {
      console.log("couldnt login basketball", err);
    }
  }

  return (
    <>
      <Header
        onChangeView={(view) => {
          switch (view) {
            case "studio":
              setView(0);
              break;
            case "rugby":
              setView(1);
              break;
            case "football":
              setView(2);
              break;
            case "golf":
              setView(3);
              break;
            case "management":
              setView(5);
              break;
            case "mlb":
              setView(6);
              break;
            case "gaa":
              setView(7);
              break;
            case "basketball":
              setView(8);
              break;
            default:
              break;
          }
        }}
      />
      {user && (
        <div style={{ display: view === 0 ? "block" : "none" }}>
          {saving && (selectedPage || selectedScene) && (
            <Save
              pages={pages}
              nextAvailable={savingNextAvailable}
              pageNumber={selectedPage ? selectedPage.number : 0}
              onCancel={() => {
                setSaving(false);
              }}
              onSave={(number) => {
                savePage(number);
                setSaving(false);
              }}
            />
          )}

          <Row>
            <Column style={{ flexGrow: 1, width: "30%" }}>
              <Sidebar />
            </Column>
            <Column style={{ width: "70%" }}>
              <Row center={window.render}>
                {project && (
                  <Stage
                    group={window.groups[0].name}
                    view={"FULL"}
                    project={project}
                  />
                )}
              </Row>
              {
                <Row style={{ flexDirection: "Column" }}>
                  <Row>
                    <Control />
                  </Row>
                  {selectedScene && (
                    <Row>
                      <Column style={{ width: "30%" }}>
                        <ButtonRow>
                          <PageButton
                            onClick={() => {
                              setSavingNextAvailable(false);
                              if (selectedPage || selectedScene) {
                                setSaving(true);
                              }
                            }}
                          >
                            Save Page
                          </PageButton>
                          <PageButton
                            onClick={() => {
                              setSavingNextAvailable(true);
                              if (selectedPage || selectedScene) {
                                setSaving(true);
                              }
                            }}
                          >
                            Save Page as
                          </PageButton>
                        </ButtonRow>
                        <EditList />
                      </Column>
                      <Column style={{ width: "70%" }}>
                        <Editor view={view} />
                      </Column>
                    </Row>
                  )}
                </Row>
              }
            </Column>
          </Row>
        </div>
      )}
      {user?.features?.find((f) => f.name === "rugby") &&
        (window.ENV?.REACT_APP_RUGBY_UI || process.env.REACT_APP_RUGBY_UI) && (
          <div
            style={{ display: view === 1 ? "block" : "none", height: "100vh" }}
          >
            <iframe
              id="rugby"
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Hyper Rugby"
              src={
                (window.ENV?.REACT_APP_RUGBY_UI ||
                  process.env.REACT_APP_RUGBY_UI) +
                "/?show_header=false&command_server=" +
                window.command_server
              }
            />
          </div>
        )}
      {user?.features?.find((f) => f.name === "football") &&
        (window.ENV?.REACT_APP_FOOTBALL_UI ||
          process.env.REACT_APP_FOOTBALL_UI) && (
          <div
            style={{ display: view === 2 ? "block" : "none", height: "100vh" }}
          >
            <iframe
              id="football"
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Hyper Football"
              src={
                (window.ENV?.REACT_APP_FOOTBALL_UI ||
                  process.env.REACT_APP_FOOTBALL_UI) +
                "/?show_header=false&command_server=" +
                window.command_server
              }
            />
          </div>
        )}

      {user?.features?.find((f) => f.name === "golf") &&
        (window.ENV?.REACT_APP_GOLF_UI || process.env.REACT_APP_GOLF_UI) && (
          <div
            style={{ display: view === 3 ? "block" : "none", height: "100vh" }}
          >
            <iframe
              id="golf"
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Hyper Golf"
              src={
                (window.ENV?.REACT_APP_GOLF_UI ||
                  process.env.REACT_APP_GOLF_UI) +
                "/?show_header=false&command_server=" +
                window.command_server
              }
            />
          </div>
        )}
      {user?.features?.find((f) => f.name === "management") &&
        (window.ENV?.REACT_APP_MANAGEMENT_UI ||
          process.env.REACT_APP_MANAGEMENT_UI) && (
          <div
            style={{ display: view === 5 ? "block" : "none", height: "100vh" }}
          >
            <iframe
              id="management"
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Hyper Management"
              src={
                (window.ENV?.REACT_APP_MANAGEMENT_UI ||
                  process.env.REACT_APP_MANAGEMENT_UI) +
                "/rugby?show_header=false"
              }
            />
          </div>
        )}
      {user?.features?.find((f) => f.name === "gaa") &&
        (window.ENV?.REACT_APP_GAA_UI || process.env.REACT_APP_GAA_UI) && (
          <div
            style={{ display: view === 7 ? "block" : "none", height: "100vh" }}
          >
            <iframe
              id="gaa"
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Hyper GAA"
              src={
                (window.ENV?.REACT_APP_GAA_UI || process.env.REACT_APP_GAA_UI) +
                "/?show_header=false&command_server=" +
                window.command_server
              }
            />
          </div>
        )}
      {user?.features?.find((f) => f.name === "mlb") && (
        <div
          style={{ display: view === 6 ? "block" : "none", height: "100vh" }}
        >
          <iframe
            id="mlb"
            style={{ width: "100%", height: "100%", border: "none" }}
            title="Hyper MLB"
            src={window.ENV?.REACT_APP_MLB_UI || process.env.REACT_APP_MLB_UI}
          />
        </div>
      )}
      {user?.features?.find((f) => f.name === "basketball") &&
        (window.ENV?.REACT_APP_BASKETBALL_UI ||
          process.env.REACT_APP_BASKETBALL_UI) && (
          <div
            style={{ display: view === 8 ? "block" : "none", height: "100vh" }}
          >
            <iframe
              id="basketball"
              style={{ width: "100%", height: "100%", border: "none" }}
              title="Hyper Basketball"
              src={
                (window.ENV?.REACT_APP_BASKETBALL_UI ||
                  process.env.REACT_APP_BASKETBALL_UI) +
                "/?show_header=false&command_server=" +
                window.command_server
              }
            />
          </div>
        )}
    </>
  );
}

function Admin() {
  const { user } = useContext(UserContext);

  if (user && user.admin) {
    return (
      <>
        <AdminHeader />
      </>
    );
  }
  return <div>Not Authorized</div>;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App({ msalInstance }) {
  window.render =
    (window.ENV?.REACT_APP_RENDER ||
      // eslint-disable-next-line eqeqeq
      process.env.REACT_APP_RENDER) == "true";

  window.showFPS = getParameterByName("showFPS");
  window.project = getParameterByName("project");
  window.uhd = getParameterByName("4k");
  window.project_server =
    window.ENV?.REACT_APP_PROJECT_SERVER ||
    process.env.REACT_APP_PROJECT_SERVER;
  window.engine_url =
    getParameterByName("engine_server") ||
    window.ENV?.REACT_APP_ENGINE_SERVER ||
    process.env.REACT_APP_ENGINE_SERVER;
  window.command_server =
    getParameterByName("command_server") ||
    window.ENV?.REACT_APP_COMMAND_SERVER ||
    process.env.REACT_APP_COMMAND_SERVER;
  window.render_server =
    getParameterByName("render_server") ||
    window.ENV?.REACT_APP_RENDER_SERVER ||
    process.env.REACT_APP_RENDER_SERVER;
  window.render_queue =
    getParameterByName("render_queue") ||
    window.ENV?.REACT_APP_RENDER_QUEUE ||
    process.env.REACT_APP_RENDER_QUEUE;
  window.useRAF =
    getParameterByName("useRAF") ||
    window.ENV?.REACT_APP_USE_RAF ||
    process.env.REACT_APP_USE_RAF;
  window.ncsOrigin = getParameterByName("origin");
  const [project, setProject] = useState();

  // ["unload", "beforeunload"].forEach(function (eventName) {
  //   window.addEventListener(eventName, function () {
  //     debugger;
  //   });
  // });

  return (
    <div className="App">
      <MSALChecker msalInstance={msalInstance}>
        <ThemeProvider theme={Hyper}>
          <UIProvider>
            <UserProvider>
              <RenderProvider>
                <ControlProvider>
                  {/* <MOSProvider> */}
                  <ProjectProvider>
                    <ResourcesProvider>
                      <OptaProvider>
                        <OptaCricketProvider>
                          <NeverNoProvider>
                            <Router>
                              {!project && (
                                <Loading
                                  selectProject={(project) => {
                                    setProject(project);
                                  }}
                                />
                              )}
                              <Switch>
                                <Route path="/Admin">
                                  <Admin />
                                </Route>
                                <Route path="/">
                                  {!window.ncsOrigin && (
                                    <Main project={project} />
                                  )}
                                  {/* {window.ncsOrigin && (
                                    <NCS project={project} />
                                  )} */}
                                </Route>
                              </Switch>
                            </Router>
                          </NeverNoProvider>
                        </OptaCricketProvider>
                      </OptaProvider>
                    </ResourcesProvider>
                  </ProjectProvider>
                  {/* </MOSProvider> */}
                </ControlProvider>
              </RenderProvider>
            </UserProvider>
          </UIProvider>
        </ThemeProvider>
      </MSALChecker>
    </div>
  );
}

function MSALChecker({ msalInstance, children }) {
  if (msalInstance) {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
  } else {
    return <>{children}</>;
  }
}

export default App;
