import React, { useContext, useState } from "react";
import styled from "styled-components";
import logo from "../../Images/HyperLogo.png";
import { UIContext } from "../../contexts/UIContext";
import { UserContext } from "../../contexts/UserContext";
const Main = styled.div`
  height: 40px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #030304;
`;

const HyperLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  height: 50%;
`;

const Profile = styled.div`
  margin-right: 0.5em;
  background-color: #a549de;
  color: white;
  border-radius: 50%;
  padding: 0.6em;
  height: 15px;
  width: 15px;
  font-size: 0.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
`;

const Column = styled.div`
  width: ${100 / 3}%;
  height: 100%;
  display: flex;
  justify-content: ${(props) => {
    if (props.right) return "flex-end";
    if (props.left) return "flex-start";
    return "center";
  }};
  align-items: center;
`;

export default function AdminHeader() {
  const { loggedIn, user } = useContext(UserContext);
  return (
    <Main>
      <Column left>
        {/* <MultiViewIcon
          onClick={() => {
            setView(view === 0 ? 1 : 0);
          }}
        /> */}
      </Column>
      <Column>
        <HyperLogo src={logo} />
      </Column>
      {/* <Outputs>
        <Button
          onClick={() => {
            window.open(
              `http://localhost:3001?engine=true&project=SKY&preview=true&groupID=${window.id}`
            );
          }}
        >
          PREVIEW OUTPUT
        </Button>
        <Button
          onClick={() => {
            window.open(
              `http://localhost:3001?engine=true&project=SKY&preview=true&groupID=${window.id}`
            );
          }}
        >
          LIVE OUTPUT
        </Button>
      </Outputs> */}
      <Column right>
        {loggedIn && (
          <>
            {/* {window.groups.map((group, index) => {
              if (index > 0) {
                return (
                  <Engine
                    onClick={() => {
                      window.groups[index].status = !window.groups[index]
                        .status;
                      setUpdate(!update);
                    }}
                  >
                    <Connection connected={group.status} />
                    <EngineName>{group.name}</EngineName>
                  </Engine>
                );
              }
            })} */}
            <Profile>
              {user.displayName[0]}
              {user.displayName.split(" ")[1][0]}
            </Profile>
          </>
        )}
      </Column>
    </Main>
  );
}
