import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";

import useLocalStorage from "../../hooks/useLocalStorage";
import { Button } from "../../Theme/Hyper";
const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const EngineRow = styled.div`
  display: flex;
  background-color: #1d283c;
  padding: 5px;
  margin-bottom: 1px;
  align-items: center;
  width: 50%;
`;
const Name = styled.div`
  display: flex;
  min-width: 300px;
`;
const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

function EngineSelect({ engines, done }) {
  const { engineMap, setEngineMap } = useContext(ControlContext);

  useEffect(() => {
    if (engines) {
      let new_engines = engineMap.filter(
        (e) =>
          engines?.findIndex((engine) => engine._id === e._id) > -1 &&
          e.mapped_to > -1
      );

      if (engines.length === 1 && new_engines.length <= 1) {
        new_engines = [
          { engine: engines[0], mapped_to: 1, _id: engines[0]._id },
        ];

        setEngineMap(new_engines);
        done();
      }
      setEngineMap(new_engines);
      if (
        engines.length === 0 ||
        process.env.REACT_APP_RENDER == true ||
        window.ENV?.REACT_APP_RENDER == true
      ) {
        setEngineMap([]);
        done();
      }
    }
  }, [engines]);
  return (
    <Main>
      {new Array(5)?.fill(1)?.map((_, map_index) => {
        let map = engineMap?.find((m) => m?.mapped_to === map_index);
        return (
          <EngineRow>
            <Name>Output {map_index + 1}</Name>
            <Dropown
              onChange={(ev) => {
                let engine = engines?.find((e) => e?._id === ev.target.value);
                let newEngineMap = [...engineMap];
                newEngineMap = newEngineMap.filter(
                  (e) => parseInt(e.mapped_to) !== parseInt(map_index + 1)
                );
                let index = newEngineMap.findIndex((e) => e._id === engine._id);
                if (index > -1) {
                  newEngineMap[index].mapped_to = parseInt(map_index + 1);
                  newEngineMap[index].engine = engine;
                } else {
                  newEngineMap.push({
                    _id: engine._id,
                    engine: engine,
                    mapped_to: map_index + 1,
                  });
                }
                newEngineMap = newEngineMap.filter((e) => e.mapped_to > -1);
                setEngineMap(newEngineMap);
              }}
            >
              <DropownOption value={-1}>Dont Use</DropownOption>;
              {engines?.map((engine, index) => {
                let map = engineMap?.find((e) => e._id === engine._id);

                return (
                  <DropownOption
                    selected={parseInt(map?.mapped_to) === map_index + 1}
                    value={engine?._id}
                  >
                    {engine?.name}
                  </DropownOption>
                );
              })}
            </Dropown>
          </EngineRow>
        );
      })}

      <Button
        onClick={() => {
          done();
        }}
      >
        Done
      </Button>
    </Main>
  );
}

export default EngineSelect;
