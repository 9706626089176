import React from "react";
import styled from "styled-components";

const Main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.primaryBackgroundColour};
  opacity: 0.7;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid grey;
  padding: 0.5em;
  font-weight: 100;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.panelColour};
  width: 700px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.highlightColour};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5em;
`;

const Group = styled.div`
  display: flex;
  padding: 1em;
  border-bottom: 1px solid black;
  justify-content: center;
`;

const Command = styled.div`
  display: flex;
  padding: 1em;
`;
const Title = styled.div`
  display: flex;
  min-width: 240px;
  align-items: center;
`;

const Shortcut = styled.div`
  display: flex;
  padding: 0.5em;
  border-radius: 4px;
  background-color: ${(props) => props.theme.primaryBackgroundColour};
  min-width: 20px;
  justify-content: center;
`;
const FadeSmall = styled.div`
  color: grey;
  padding-left: 0.3em;
  padding-right: 0.3em;
`;
const Fade = styled.div`
  color: grey;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  align-items: center;
`;
export default function Keyboard({ title, onClose }) {
  return (
    <>
      <Screen></Screen>
      <Main>
        <Content>
          <Header>{title}</Header>
          <Group>Page Saving</Group>
          <Command>
            <Title>Save Page</Title>
            <Shortcut>
              Ctrl <FadeSmall>+</FadeSmall> s
            </Shortcut>
          </Command>
          <Command>
            <Title>Save page to next available number</Title>
            <Shortcut>
              Ctrl <FadeSmall>+</FadeSmall>
              Shift <FadeSmall>+</FadeSmall> s
            </Shortcut>
          </Command>
          <Group>Page Recall</Group>
          <Command>
            <Title>Clear Page Number</Title>
            <Shortcut>.</Shortcut>
          </Command>
          <Command>
            <Title>Page Number</Title>
            <Shortcut>NUMPAD DIGITS</Shortcut>
          </Command>
          <Command>
            <Title>Select Page</Title>
            <Shortcut>ENTER</Shortcut>
          </Command>
          <Command>
            <Title>Next Page</Title>
            <Shortcut>+</Shortcut>
          </Command>
          <Group>Playout</Group>
          <Command>
            <Title>Animate On</Title>
            <Shortcut>F8</Shortcut> <Fade>or</Fade> <Shortcut>-</Shortcut>
          </Command>
          <Command>
            <Title>Animate Off</Title>
            <Shortcut>F9</Shortcut> <Fade>or</Fade> <Shortcut>*</Shortcut>
          </Command>
          <Command>
            <Title>Clear Preview</Title>
            <Shortcut>
              Ctrl<FadeSmall>+</FadeSmall>q
            </Shortcut>
          </Command>
          <Group>Editing</Group>
          <Command>
            <Title>Next Edit</Title>
            <Shortcut>Tab</Shortcut>
          </Command>
          <Command>
            <Title>Change Number value</Title>
            <Shortcut>Mouse wheel</Shortcut>
          </Command>
          <Buttons>
            <Button onClick={onClose}>Close</Button>
          </Buttons>
        </Content>
      </Main>
    </>
  );
}
