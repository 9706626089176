import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";

const EditArea = styled.div`
  background-color: #191f27;
  display: flex;
`;
const Container = styled.div`
  background-color: #191f27;
  width: 300px;
  display: flex;
`;
const Colour = styled.div`
  width: 100px;
  height: 15px;
  border-radius: 4px;
  margin: 50px;
`;
export default function ColourEdit({ selectedEdit, preview, selectedScene }) {
  const [update, setUpdate] = useState(false);
  const [colour, setColour] = useState();

  useEffect(() => {
    let color =
      selectedEdit.tint != null && selectedEdit.tint !== undefined
        ? hex2string(selectedEdit.tint)
        : "";

    if (selectedEdit.type === "TEXT" || selectedEdit.type === "CLOCK") {
      color = selectedEdit.style._fill?.replace("white", "#ffffff");
    }
    if (selectedEdit.type === "RECTANGLE") {
      color = hex2string(selectedEdit.fillColor);
    } else if (selectedEdit.type === "CIRCLE") {
      color = hex2string(selectedEdit.fillColor);
    }
    setColour(color);
  }, [selectedEdit]);

  function handleColorChange(color, event) {
    selectedEdit.colour = color.hex;
    preview();
    setUpdate(!update);
  }
  function hex2string(hex) {
    var hexString = hex.toString(16);
    hexString = "000000".substr(0, 6 - hexString.length) + hexString;
    return "#" + hexString;
  }

  return (
    <EditArea>
      <Container>
        <SketchPicker
          disableAlpha={true}
          color={colour}
          onChange={(colour) => {
            setColour(colour.hex);
          }}
          onChangeComplete={(color, event) => handleColorChange(color, event)}
          styles={{
            picker: {
              backgroundColor: "#373a4a",
              padding: "10px 10px 0px",
              borderRadius: "4px",
              boxShadow:
                "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px",
            },
          }}
        />
      </Container>
    </EditArea>
  );
}
