import React, { useContext, useEffect, useState } from "react";
import {
  getApolloContext,
  createHttpLink,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import { ProjectContext } from "../../contexts/ProjectContext";

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #191f27;
  border-right: 1px solid #06080a;
  padding: 8px;
  min-width: 100px;
`;

const Item = styled.div`
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #06080a;
  background-color: ${(props) => (props.selected ? "#d20a3e" : "")};
`;

const Name = styled.div`
  min-width: 220px;
`;

const Number = styled.div`
  min-width: 30px;
  text-align: center;
  margin-right: 5px;
`;

const Objects = styled.div`
  min-width: 130px;
`;

const BuildButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
  border-radius: 4px;
  background-color: ${(props) => (props.enabled ? "#30c17e" : "#353a47")};
  color: ${(props) => (props.enabled ? "black" : "#fff")};
  cursor: pointer;
`;

const FLAGS = {
  AUS: "au.png",
  USA: "us.png",
  GBR: "gb.png",
  CAN: "ca.png",
  UKR: "ua.png",
  POL: "pl.png",
  MEX: "mx.png",
  ITA: "it.png",
  CZE: "cz.png",
  LAT: "lv.png",
  NZL: "nz.png",
  IRL: "ie.png",
  EST: "ee.png",
  GHA: "gh.png",
  RSA: "za.png",
};

const ICONS = {
  "Webster Stones": { image: "WebsterStones.png", width: 108, height: 108 },
  "Deadlift Ladder": { image: "DeadliftLadder.png", width: 108, height: 108 },
  "Sandbag Steeplechase": {
    image: "SandbagSteeplechase.png",
    width: 108,
    height: 108,
  },
  "Globe Viking Press": { image: "VikingPress.png", width: 80, height: 80 },
  "Car Walk": { image: "CarWalk.png", width: 108, height: 108 },
  "Stone Off": { image: "StoneOff.png", width: 108, height: 108 },
  "BFGoodrich Tires HD Terrain Deadlift": {
    image: "Deadlift.png",
    width: 108,
    height: 108,
  },
  "KNAACK Giant's Medley": {
    image: "GiantsMedley.png",
    width: 108,
    height: 108,
  },
  "Max Axle": {
    image: "MaxAxelPress.png",
    width: 108,
    height: 108,
  },
  "Keg Toss": {
    image: "KegToss.png",
    width: 108,
    height: 108,
  },
  "Reign Total Body Fuel Conan’s Wheel": {
    image: "ConansWheel.png",
    width: 108,
    height: 108,
  },
  "Atlas Stones": {
    image: "AtlasStones.png",
    width: 108,
    height: 108,
  },
};

const UNITS = {
  reps_and_time: "REPS",
  time_and_distance: "DISTANCE",
  reps: "REPS",
  max_weight: "",
  loading: "OBJECTS",
  max_time: "",
  stone_off: "STONES",
};

const RESULTS = gql`
  query Group {
    results {
      group {
        _id
        name
      }

      rounds {
        status
        round {
          _id

          event {
            _id
            event_type
            name
            unit_name
            objects
          }

          type

          order

          date

          time
        }

        standings {
          player {
            _id
            first_name
            nationality
            nationality_web
            last_name
            weight
            height
            appearance
            age
          }
          overall_status
          overall_position
          total_points
          overall_tied
          points

          position

          scoring
        }
      }
    }
  }
`;
const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_WSM_SERVER || process.env.REACT_APP_WSM_SERVER) +
    "/graphql",
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

export default function WSMEditor({ edits, preview, selectedScene }) {
  const [competition, setCompetition] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedRound, setSelectedRound] = useState();
  const [selectedPlayer, setSelectedPlayer] = useState();
  const {
    scenes,
    getEdits,
    selectScene,
    savePage,
    selectShow,
    selectedShow,
    setPages,
    changeSelectedShow,
  } = useContext(ProjectContext);

  useEffect(() => {
    client
      .query({
        query: RESULTS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return setCompetition(
          JSON.parse(JSON.stringify(response.data.results)).sort(
            (a, b) => a.group?.name - b.group?.name
          )
        );
      })
      .catch((err) => console.error(err));
  }, []);

  async function buildPlayerBios() {
    let bio_scene = scenes.find((s) => s.name === "LT_2Line");
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);
    let index = 0;

    for (let g = 0; g < competition.length; g++) {
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        index++;
        let players = group.rounds[0]?.standings?.map((s) => s.player);
        for (let p = 0; p < players.length; p++) {
          let player = players[p];
          let edit = edits?.find((e) => e.control.name === "NAME");
          if (edit) {
            edit.text =
              player?.first_name + " " + player?.last_name?.toUpperCase();
          }
          edit = edits?.find((e) => e.control.name === "FLAG");
          if (edit) {
            edit.image = FLAGS[player?.nationality];
          }

          edit = edits?.find((e) => e.control.name === "NAT");
          if (edit) {
            edit.text = "" + player?.nationality + "";
          }

          edit = edits?.find((e) => e.control.name === "LINE 1");
          if (edit) {
            edit.text =
              "AGE: " +
              player?.age +
              "      " +
              "HEIGHT: " +
              player?.height +
              "      " +
              "WEIGHT: " +
              player?.weight;
          }
          edit = edits?.find((e) => e.control.name === "LINE 2");
          if (edit) {
            edit.text = player?.appearance + " WSM appearance"?.toUpperCase();
          }

          await savePage(
            index,
            edits,
            player?.first_name + " " + player?.last_name,
            bio_scene
          );

          index++;
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildPlayerResults() {
    let bio_scene = scenes.find((s) => s.name === "LT_Result");
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);
    let index = 0;
    for (let g = 0; g < competition.length; g++) {
      index++;
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];

          let players = [...(round?.standings || [])]
            ?.sort((a, b) => {
              return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
            })
            ?.map((s) => s.player);

          let standings_sorted = [...(round?.standings || [])]?.sort((a, b) => {
            return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
          });

          for (let p = 0; p < players.length; p++) {
            let player = players[p];
            let player_standing = standings_sorted[p];
            let edit = edits?.find((e) => e.control.name === "NAME");
            if (edit) {
              edit.text =
                player?.first_name + " " + player?.last_name?.toUpperCase();
            }
            edit = edits?.find((e) => e.control.name === "FLAG");
            if (edit) {
              edit.image = FLAGS[player?.nationality];
            }

            edit = edits?.find((e) => e.control.name === "NAT");
            if (edit) {
              edit.text = "" + player?.nationality + "";
            }
            let pos = 0;
            let orders = round?.standings?.filter(
              (s) =>
                parseInt(s.scoring?.order) <=
                parseInt(player_standing?.scoring?.order)
            );

            let more_to_go =
              round?.standings?.filter(
                (s) =>
                  parseInt(s.scoring?.order) >
                  parseInt(player_standing?.scoring?.order)
              )?.length > 0;

            pos = orders?.findIndex((p) => p?.player?._id === player?._id) + 1;
            let tied = false;

            if (
              round.round?.event.event_type === "reps" &&
              orders?.findIndex(
                (o) =>
                  o?.scoring?.reps === player_standing?.scoring?.reps &&
                  o?.player?._id !== player_standing?.player?._id
              ) > -1
            ) {
              tied = true;
            }

            if (
              round.round?.event.event_type === "time_and_distance" &&
              orders?.findIndex(
                (o) =>
                  o?.scoring?.time === player_standing?.scoring?.time &&
                  o?.scoring?.distance === player_standing?.scoring?.distance &&
                  o?.player?._id !== player_standing?.player?._id
              ) > -1
            ) {
              tied = true;
            }
            if (
              round.round?.event.event_type === "max_distance" &&
              orders?.findIndex(
                (o) =>
                  o?.scoring?.distance === player_standing?.scoring?.distance &&
                  o?.player?._id !== player_standing?.player?._id
              ) > -1
            ) {
              tied = true;
            }

            edit = edits?.find((e) => e.control.name === "LINE 1");
            if (edit) {
              if (round.round?.event.event_type === "max_weight") {
                edit.text = round?.round.event.name?.toUpperCase();
              } else {
                edit.text = (
                  round?.round.event.name +
                  " - " +
                  (more_to_go ? "Currently  " : "Finished ") +
                  (tied ? "T" : "") +
                  ordinal_suffix_of(pos)
                )?.toUpperCase();
              }
            }

            edit = edits?.find((e) => e.control.name === "RESULT");
            if (round.round?.event.event_type === "loading") {
              if (edit) {
                edit.text =
                  player_standing?.scoring?.objects?.filter((o) => o.success)
                    ?.length +
                  " IN " +
                  player_standing?.scoring?.time?.replace("0:", "") +
                  (player_standing?.scoring?.time?.indexOf("1:") === -1
                    ? "s"
                    : "");
              }
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player?.first_name +
                  " " +
                  player?.last_name,
                bio_scene
              );
            } else if (round.round?.event.event_type === "reps") {
              if (edit) {
                edit.text =
                  player_standing?.scoring?.reps +
                  (round.round?.event.unit_name !== "Degrees" ? " " : "") +
                  round.round?.event.unit_name
                    ?.replace("Degrees", "°")
                    ?.toUpperCase();
              }
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player?.first_name +
                  " " +
                  player?.last_name,
                bio_scene
              );
            } else if (round.round?.event.event_type === "reps_and_time") {
              if (edit) {
                edit.text =
                  player_standing?.scoring?.reps +
                  " IN " +
                  player_standing?.scoring?.time?.replace("0:", "") +
                  (player_standing?.scoring?.time?.indexOf("1:") === -1
                    ? "s"
                    : "");
              }
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player?.first_name +
                  " " +
                  player?.last_name,
                bio_scene
              );
            } else if (round.round?.event.event_type === "max_weight") {
              if (edit) {
                let success =
                  player_standing?.scoring?.objects?.filter((o) => o.success)
                    .length - 1;

                let weight =
                  round.round?.event?.objects?.[success]?.name?.split(
                    " "
                  )?.[0] || "";

                edit.text = weight;
              }
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player?.first_name +
                  " " +
                  player?.last_name,
                bio_scene
              );
            } else if (round.round?.event.event_type === "time_and_distance") {
              if (edit) {
                edit.text = player_standing?.scoring?.distance
                  ? player_standing?.scoring?.distance + "m"
                  : player_standing?.scoring?.time?.replace("0:", "") +
                    (player_standing?.scoring?.time?.indexOf("1:") === -1
                      ? "s"
                      : "");
              }
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player?.first_name +
                  " " +
                  player?.last_name,
                bio_scene
              );
            } else if (round.round?.event.event_type === "max_distance") {
              if (edit) {
                edit.text = player_standing?.scoring?.distance + "m";
              }
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player?.first_name +
                  " " +
                  player?.last_name,
                bio_scene
              );
            }
            index++;
          }
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildResultBoards() {
    let bio_scene = scenes.find((s) => s.name === "FF_Event_Table_Heats");
    if (selectedGroup === 6) {
      bio_scene = scenes.find((s) => s.name === "FF_Event_Table_GF");
    }

    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);
    let index = 0;
    for (let g = 0; g < competition.length; g++) {
      index++;
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];
          if (round.round?.event.name === "Stone Off") {
            continue;
          }
          let players = [...(round?.standings || [])]
            ?.sort((a, b) => {
              return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
            })
            ?.map((s) => s.player);

          let standings_sorted = [...(round?.standings || [])]?.sort((a, b) => {
            return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
          });

          let unique_orders = standings_sorted
            ?.map((p) => p.scoring?.order)
            ?.filter((value, index, array) => {
              return array.indexOf(value) === index;
            });
          let number_of_athletes = 0;
          let loop = selectedGroup === 6 ? 10 : 6;
          for (let z = 1; z <= loop; z++) {
            ///blank out column
            let edit = edits?.find((e) => e.control.name === "COL1_" + z);
            if (edit) {
              edit.text = "";
            }
          }

          for (let o = 0; o < unique_orders?.length; o++) {
            number_of_athletes = 0;
            let filtered_players = [...(round?.standings || [])]
              ?.filter(
                (p) => parseInt(p.scoring?.order) <= parseInt(unique_orders[o])
              )
              ?.sort((a, b) => {
                if (a?.position > b?.position) {
                  return 1;
                }
                if (a?.position < b?.position) {
                  return -1;
                }
                return a.player?.last_name.localeCompare(b.player?.last_name);
              })
              ?.map((s) => s.player);
            let prev_player_standings;
            let tied_position = 0;

            for (let p = 0; p < filtered_players.length; p++) {
              number_of_athletes++;

              let player = filtered_players[p];
              let player_standing = [...(round?.standings || [])]?.find(
                (p) => p.player?._id === player?._id
              );

              let orders = standings_sorted?.filter(
                (s) =>
                  parseInt(s.scoring?.order) <=
                  parseInt(player_standing?.scoring?.order)
              );

              let row = p + 1;

              let edit = edits?.find((e) => e.control.name === "NAME_" + row);
              if (edit) {
                edit.text =
                  player?.first_name + " " + player?.last_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "REMAINING TEXT");
              if (edit) {
                edit.text =
                  standings_sorted?.filter((p) => p.overall_status !== "WD")
                    ?.length -
                    number_of_athletes >
                  0
                    ? standings_sorted?.filter((p) => p.overall_status !== "WD")
                        ?.length -
                      number_of_athletes +
                      " ATHLETES REMAIN"
                    : "";
              }

              edit = edits?.find((e) => e.control.name === "FLAG_" + row);
              if (edit) {
                edit.image = FLAGS[player?.nationality];
              }

              edit = edits?.find((e) => e.control.name === "NAT_" + row);
              if (edit) {
                edit.text = "" + player?.nationality + "";
              }
              let pos = 0;

              pos = p + 1;
              let tied = 0;

              if (round.round?.event.event_type === "reps") {
                tied =
                  prev_player_standings?.scoring?.reps ===
                  player_standing?.scoring?.reps;
              }

              if (!tied) {
                tied_position = pos;
              }

              edit = edits?.find((e) => e.control.name === "POS_" + row);
              if (edit) {
                edit.text = tied > 0 ? "T" + tied_position : "" + pos;
              }

              for (let ti = 1; ti <= tied; ti++) {
                edit = edits?.find(
                  (e) => e.control.name === "POS_" + (row - ti)
                );
                if (edit) {
                  edit.text = "T" + tied_position;
                }
              }

              edit = edits?.find((e) => e.control.name === "ROWS");
              if (edit) {
                edit.text = filtered_players?.length;
              }

              edit = edits?.find((e) => e.control.name === "COL2_" + row);
              if (round.round?.event.event_type === "loading") {
                if (edit) {
                  edit.text = player_standing?.scoring?.time;
                }

                edit = edits?.find((e) => e.control.name === "TIME");
                if (edit) {
                  edit.text = round.round?.event.unit_name?.toUpperCase();
                }
                edit = edits?.find((e) => e.control.name === "OBJECTS");
                if (edit) {
                  edit.text = "OBJECTS";
                }
              } else if (round.round?.event.event_type === "reps") {
                edit = edits?.find((e) => e.control.name === "COL1_" + row);
                if (edit) {
                  edit.text = "";
                }
                edit = edits?.find((e) => e.control.name === "COL2_" + row);
                if (edit) {
                  edit.text =
                    player_standing?.scoring?.reps +
                    (round.round?.event.unit_name === "Degrees" ? "°" : "");
                }
                edit = edits?.find((e) => e.control.name === "TIME");
                if (edit) {
                  edit.text = round.round?.event.unit_name?.toUpperCase();
                }
                edit = edits?.find((e) => e.control.name === "OBJECTS");
                if (edit) {
                  edit.text = "";
                }

                edit = edits?.find((e) => e.control.name === "COL_TITLE_1");
                if (edit) {
                  edit.text = "";
                }
                edit = edits?.find((e) => e.control.name === "COL_TITLE_2");
                if (edit) {
                  edit.text = round.round?.event.unit_name?.toUpperCase();
                }
              } else if (round.round?.event.event_type === "reps_and_time") {
                if (edit) {
                  edit.text = player_standing?.scoring?.time?.replace("0:", "");
                }

                edit = edits?.find((e) => e.control.name === "COL_TITLE_1");
                if (edit) {
                  edit.text = round.round?.event.unit_name?.toUpperCase();
                }
                edit = edits?.find((e) => e.control.name === "COL_TITLE_2");
                if (edit) {
                  edit.text = "TIME (s)";
                }

                edit = edits?.find((e) => e.control.name === "COL1_" + row);
                if (edit) {
                  edit.text = player_standing?.scoring?.reps;
                }

                edit = edits?.find((e) => e.control.name === "TIME");
                if (edit) {
                  edit.text = "TIME";
                }
                edit = edits?.find((e) => e.control.name === "OBJECTS");
                if (edit) {
                  edit.text = round.round?.event.unit_name?.toUpperCase();
                }
              } else if (round.round?.event.event_type === "max_weight") {
                let success =
                  player_standing?.scoring?.objects?.filter((o) => o.success)
                    .length - 1;

                let weight =
                  round.round?.event?.objects?.[success]?.name?.split(
                    " "
                  )?.[0] || "";

                if (edit) {
                  edit.text = weight;
                }
                edit = edits?.find((e) => e.control.name === "COL_TITLE_1");
                if (edit) {
                  edit.text = "";
                }
                edit = edits?.find((e) => e.control.name === "COL_TITLE_2");
                if (edit) {
                  edit.text =
                    round.round?.event.name?.toUpperCase()?.indexOf("KEG") > -1
                      ? "MAX HEIGHT"
                      : "MAX WEIGHT";
                }
                edit = edits?.find((e) => e.control.name === "COL1_" + row);
                if (edit) {
                  edit.text = "";
                }

                edit = edits?.find((e) => e.control.name === "TIME");
                if (edit) {
                  edit.text = "WEIGHT";
                }
                edit = edits?.find((e) => e.control.name === "OBJECTS");
                if (edit) {
                  edit.text = "";
                }
              } else if (
                round.round?.event.event_type === "time_and_distance"
              ) {
                debugger;
                edit = edits?.find((e) => e.control.name === "COL1_" + row);
                if (edit) {
                  edit.text = player_standing?.scoring?.distance || "";
                }

                edit = edits?.find((e) => e.control.name === "COL2_" + row);
                if (edit) {
                  edit.text =
                    player_standing?.scoring?.time?.replace("0:", "") || "";
                }

                edit = edits?.find((e) => e.control.name === "COL_TITLE_2");
                if (edit) {
                  edit.text = "TIME (s)";
                }
                edit = edits?.find((e) => e.control.name === "COL_TITLE_1");
                if (edit) {
                  edit.text = "DISTANCE (m)";
                }
              } else if (round.round?.event.event_type === "max_distance") {
                edit = edits?.find((e) => e.control.name === "TIME");
                if (edit) {
                  edit.text = round.round?.event.unit_name?.toUpperCase();
                }

                edit = edits?.find((e) => e.control.name === "COL1_" + row);
                if (edit) {
                  edit.text = "";
                }

                edit = edits?.find((e) => e.control.name === "COL2_" + row);
                if (edit) {
                  edit.text = player_standing?.scoring?.distance;
                }

                edit = edits?.find((e) => e.control.name === "COL_TITLE_1");
                if (edit) {
                  edit.text = "";
                }
                edit = edits?.find((e) => e.control.name === "COL_TITLE_2");
                if (edit) {
                  edit.text = "DISTANCE (m)";
                }
              }
              edit = edits?.find((e) => e.control.name === "TITLE");
              if (edit) {
                let text = "";
                if (group?.group?.name === "6") {
                  text = "THE GRAND FINAL - ";
                } else {
                  text = "HEAT " + group?.group?.name + " - ";
                }
                text = text + round.round?.event.name?.toUpperCase();
                edit.text = text;
              }

              edit = edits?.find((e) => e.control.name === "ICON");
              if (edit) {
                edit.image = ICONS[round.round?.event.name].image;
                edit.width = ICONS[round.round?.event.name].width;
                edit.height = ICONS[round.round?.event.name].height;
              }

              prev_player_standings = player_standing;
            }

            await savePage(
              index,
              edits,
              "Event " + round.round?.order + " - After " + number_of_athletes,
              bio_scene
            );
            index++;
          }
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildStandings() {
    let bio_scene = scenes.find((s) => s.name === "FF_Table_Heats");
    if (selectedGroup === 6) {
      bio_scene = scenes.find((s) => s.name === "FF_Table_GF");
    }
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);

    for (let g = 0; g < competition.length; g++) {
      let group = competition[g];
      let edit = edits?.find((e) => e.control.name === "OBJECTS");
      if (edit) {
        edit.text = "";
      }

      if (group?.group?.name === selectedGroup?.toString()) {
        let index = 1;
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];
          edit = edits?.find((e) => e.control.name === "REMAINING TEXT");
          if (edit) {
            edit.text = "";
          }

          edit = edits?.find((e) => e.control.name === "TIME");
          if (edit) {
            edit.text = round?.round?.order < 6 ? "POINTS" : "";
          }

          // edit = edits?.find((e) => e.control.name === "SUBTITLE");
          // if (edit) {
          //   edit.text =
          //     round?.round?.order < 6
          //       ? "AFTER EVENT " + round?.round?.order + " OF 6"
          //       : "FINAL STANDINGS";
          // }
          if (round?.round?.order < 6) {
            edit = edits?.find((e) => e.control.name === "TYPE");
            if (edit) {
              edit.text = "";
            }
            edit = edits?.find((e) => e.control.name === "SHOW_QUAL");
            if (edit) {
              edit.visible = false;
            }
          } else {
            edit = edits?.find((e) => e.control.name === "TYPE");
            if (edit) {
              edit.text = "FINAL";
            }
            edit = edits?.find((e) => e.control.name === "SHOW_QUAL");
            if (edit) {
              edit.visible = true;
            }
          }
          edit = edits?.find((e) => e.control.name === "TITLE");
          if (edit) {
            if (group?.group?.name === "6") {
              edit.text = "THE GRAND FINAL";
            } else {
              edit.text =
                "HEAT " +
                group?.group?.name +
                (round?.round?.order < 6
                  ? " - LEADERBOARD"
                  : " - FINAL STANDINGS");
            }
          }

          let standings = [...(round?.standings || [])]?.sort((a, b) => {
            if (a?.overall_position < b?.overall_position) {
              return -1;
            }
            if (a?.overall_position > b?.overall_position) {
              return 1;
            }
            return a.player?.last_name.localeCompare(b.player?.last_name);
          });
          edit = edits?.find((e) => e.control.name === "ROWS");
          if (edit) {
            edit.text = standings?.length;
          }

          for (let i = 0; i < standings.length; i++) {
            let player = standings[i]?.player;
            let row = i + 1;

            edit = edits?.find((e) => e.control.name === "COL1_" + row);
            if (edit) {
              edit.text =
                round?.round?.order < 6 || selectedGroup === 6
                  ? round?.round?.order
                  : "";
            }

            edit = edits?.find((e) => e.control.name === "NAME_" + row);
            if (edit) {
              edit.text =
                player?.first_name + " " + player?.last_name?.toUpperCase();
            }
            edit = edits?.find((e) => e.control.name === "FLAG_" + row);
            if (edit) {
              edit.image = FLAGS[player?.nationality];
            }

            edit = edits?.find((e) => e.control.name === "NAT_" + row);
            if (edit) {
              edit.text = "" + player?.nationality + "";
            }

            edit = edits?.find((e) => e.control.name === "POS_" + row);
            if (edit) {
              edit.text =
                standings[i]?.overall_status === "WD"
                  ? "WD"
                  : (standings[i]?.overall_tied ? "T" : "") +
                    standings[i]?.overall_position;
            }

            edit = edits?.find((e) => e.control.name === "COL2_" + row);
            if (edit) {
              edit.text = standings[i]?.total_points;
              // round?.round?.order < 6 || selectedGroup === 6
              //   ? standings[i]?.total_points
              //   : "";
            }
          }
          await savePage(
            index,
            edits,
            "After Event " + round.round?.order,
            bio_scene
          );
          index++;
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildSideTable() {
    let bio_scene = scenes.find((s) => s.name === "Side_Table_Standings");
    if (selectedGroup === 6) {
      bio_scene = scenes.find((s) => s.name === "Side_Table_Standings_GF");
    }
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);

    for (let g = 0; g < competition.length; g++) {
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        let index = 1;

        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];
          let edit = edits?.find((e) => e.control.name === "ICON");
          if (edit) {
            edit.image = ICONS[round.round?.event.name].image;
            edit.width = ICONS[round.round?.event.name].width * 3;
            edit.height = ICONS[round.round?.event.name].height * 3;
          }
          if (r === 0) {
            edit = edits?.find((e) => e.control.name === "TITLE");
            if (edit) {
              if (selectedGroup === 6) {
                edit.text = "THE GRAND FINAL";
              } else {
                edit.text = "HEAT " + group?.group?.name;
              }
            }
            let standings = [...(round?.standings || [])]?.sort((a, b) => {
              return a.player?.last_name.localeCompare(b.player?.last_name);
            });
            for (let i = 0; i < standings.length; i++) {
              let player = standings[i]?.player;
              let row = i + 1;

              edit = edits?.find((e) => e.control.name === "NAME_" + row);
              if (edit) {
                edit.text =
                  player?.first_name + " " + player?.last_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "FLAG_" + row);
              if (edit) {
                edit.image = FLAGS[player?.nationality];
              }

              edit = edits?.find((e) => e.control.name === "POINTS_" + row);
              if (edit) {
                edit.text = "";
              }
            }
            await savePage(index, edits, "Starting Heat", bio_scene);
            index++;
          }

          edit = edits?.find((e) => e.control.name === "TITLE");
          if (edit) {
            if (selectedGroup === 6) {
              edit.text = "THE GRAND FINAL";
            } else {
              edit.text = "HEAT " + group?.group?.name;
            }
          }
          let standings = [...(round?.standings || [])]?.sort((a, b) => {
            if (a?.overall_position < b?.overall_position) {
              return -1;
            }
            if (a?.overall_position > b?.overall_position) {
              return 1;
            }
            return a.player?.last_name.localeCompare(b.player?.last_name);
          });
          for (let i = 0; i < standings.length; i++) {
            let player = standings[i]?.player;
            let row = i + 1;

            edit = edits?.find((e) => e.control.name === "NAME_" + row);
            if (edit) {
              edit.text =
                player?.first_name + " " + player?.last_name?.toUpperCase();
            }
            edit = edits?.find((e) => e.control.name === "FLAG_" + row);
            if (edit) {
              edit.image = FLAGS[player?.nationality];
            }
            edit = edits?.find((e) => e.control.name === "POINTS_" + row);
            if (edit) {
              edit.text = "";
            }
            // edit = edits?.find((e) => e.control.name === "POS_" + row);
            // if (edit) {
            //   edit.text =
            //     (standings[i]?.overall_tied ? "T" : "") +
            //     standings[i]?.overall_position;
            // }
          }
          await savePage(
            index,
            edits,
            "After Event " + round.round?.order,
            bio_scene
          );
          index++;
          for (let i = 0; i < standings.length; i++) {
            let player = standings[i]?.player;
            let row = i + 1;

            edit = edits?.find((e) => e.control.name === "NAME_" + row);
            if (edit) {
              edit.text =
                player?.first_name + " " + player?.last_name?.toUpperCase();
            }
            edit = edits?.find((e) => e.control.name === "FLAG_" + row);
            if (edit) {
              edit.image = FLAGS[player?.nationality];
            }

            edit = edits?.find((e) => e.control.name === "POINTS_" + row);
            if (edit) {
              edit.text =
                standings[i]?.total_points +
                "pt" +
                (standings[i]?.total_points > 1 ? "s" : "");
            }
          }
          await savePage(
            index,
            edits,
            "After Event " + round.round?.order + " with points",
            bio_scene
          );
          index++;
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildEventBugs() {
    let bio_scene = scenes.find((s) => s.name === "Event Bug");
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);
    let index = 0;
    for (let g = 0; g < competition.length; g++) {
      index++;
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];

          let players = [...(round?.standings || [])]
            ?.sort((a, b) => {
              return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
            })
            ?.map((s) => s.player);

          let standings_sorted = [...(round?.standings || [])]?.sort((a, b) => {
            return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
          });

          for (let p = 0; p < players.length; p++) {
            let player = players[p];
            let player_standing = standings_sorted[p];
            let edit = edits?.find((e) => e.control.name === "EVENT NUM");
            if (edit) {
              edit.text = "EVENT " + round.round?.order;
            }

            edit = edits?.find((e) => e.control.name === "EVENT NAME");
            if (edit) {
              edit.text = round.round?.event?.name?.toUpperCase();
            }

            edit = edits?.find((e) => e.control.name === "TTB");
            if (edit) {
              edit.text = player_standing?.scoring?.time;
            }
            if (player_standing?.scoring?.time) {
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - TTB " +
                  player_standing?.scoring?.time,
                bio_scene
              );

              index++;
            }
          }
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildMiniTablesEvent() {
    let bio_scene = scenes.find((s) => s.name === "Mini Table");
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);
    let index = 0;
    for (let g = 0; g < competition.length; g++) {
      index++;
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];

          let players = [...(round?.standings || [])]
            ?.sort((a, b) => {
              return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
            })
            ?.map((s) => s.player);

          let standings_sorted = [...(round?.standings || [])]?.sort((a, b) => {
            return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
          });

          let unique_orders = standings_sorted
            ?.map((p) => p.scoring?.order)
            ?.filter((value, index, array) => {
              return array.indexOf(value) === index;
            });
          let number_of_athletes = 0;
          for (let o = 0; o < unique_orders?.length; o++) {
            number_of_athletes = 0;
            let filtered_players = [...(round?.standings || [])]
              ?.filter(
                (p) => parseInt(p.scoring?.order) <= parseInt(unique_orders[o])
              )
              ?.sort((a, b) => {
                if (a?.position > b?.position) {
                  return 1;
                }
                if (a?.position < b?.position) {
                  return -1;
                }
                return a.player?.last_name.localeCompare(b.player?.last_name);
              })
              ?.map((s) => {
                return { ...s.player, order: s.scoring?.order };
              });

            filtered_players = [
              ...filtered_players,
              ...[...(round?.standings || [])]
                ?.filter(
                  (p) => parseInt(p.scoring?.order) > parseInt(unique_orders[o])
                )
                ?.sort((a, b) => {
                  if (a?.scoring?.order > b?.scoring?.order) {
                    return 1;
                  }
                  if (a?.scoring?.order < b?.scoring?.order) {
                    return -1;
                  }
                  return a.player?.last_name.localeCompare(b.player?.last_name);
                })
                ?.map((s) => {
                  return { ...s.player };
                }),
            ];
            let prev_player_standings;
            let tied_position = 0;

            for (let p = 0; p < filtered_players.length; p++) {
              number_of_athletes++;
              let player = filtered_players[p];
              let player_standing = [...(round?.standings || [])]?.find(
                (p) => p.player?._id === player?._id
              );

              let orders = standings_sorted?.filter(
                (s) =>
                  parseInt(s.scoring?.order) <=
                  parseInt(player_standing?.scoring?.order)
              );

              let row = p + 1;
              let edit = edits?.find((e) => e.control.name === "NAME_" + row);
              if (edit) {
                edit.text = player?.last_name
                  ?.substring(0, 3)
                  ?.toUpperCase()
                  ?.replace("O'D", "O'DW");
              }
              edit = edits?.find((e) => e.control.name === "NAT_" + row);
              if (edit) {
                edit.image = FLAGS[player?.nationality];
              }

              let pos = 0;

              pos = p + 1;
              let tied = 0;

              if (round.round?.event.event_type === "reps") {
                tied =
                  prev_player_standings?.scoring?.reps ===
                  player_standing?.scoring?.reps;
              }

              edit = edits?.find((e) => e.control.name === "ROWS");
              if (edit) {
                edit.text = standings_sorted?.length;
              }

              edit = edits?.find((e) => e.control.name === "NUM_ATHLETES");
              if (edit) {
                edit.text = [...filtered_players]?.filter((p) => {
                  return p.order !== undefined;
                })?.length;
              }

              edit = edits?.find((e) => e.control.name === "COL2_" + row);
              if (edit) {
                edit.text = "";
              }

              if (player?.order) {
                if (round.round?.event.event_type === "loading") {
                  if (edit) {
                    edit.text = player_standing?.scoring?.time;
                  }
                } else if (round.round?.event.event_type === "reps") {
                  if (edit) {
                    edit.text =
                      player_standing?.scoring?.reps +
                      (round.round?.event.unit_name === "Degrees" ? "°" : "");
                  }
                } else if (round.round?.event.event_type === "reps_and_time") {
                  if (edit) {
                    edit.text = player_standing?.scoring?.time;
                  }
                } else if (round.round?.event.event_type === "max_weight") {
                  let success =
                    player_standing?.scoring?.objects?.filter((o) => o.success)
                      .length - 1;

                  let weight =
                    round.round?.event?.objects?.[success]?.name?.split(
                      " "
                    )?.[2] || "";
                  if (edit) {
                    edit.text = weight;
                  }
                } else if (
                  round.round?.event.event_type === "time_and_distance"
                ) {
                  if (edit) {
                    edit.text = player_standing?.scoring?.time;
                  }
                } else if (round.round?.event.event_type === "max_distance") {
                  if (edit) {
                    edit.text = player_standing?.scoring?.distance;
                  }
                }
              }

              prev_player_standings = player_standing;
            }
            await savePage(
              index,
              edits,
              "Event " +
                round.round?.order +
                " - After " +
                [...filtered_players]?.filter((p) => {
                  return p.order !== undefined;
                })?.length,
              bio_scene
            );
            index++;
          }
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildMiniStandings() {
    let bio_scene = scenes.find((s) => s.name === "Mini Table");
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);

    for (let g = 0; g < competition.length; g++) {
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        let index = 1;
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];
          let edit = edits?.find((e) => e.control.name === "TIME");
          if (edit) {
            edit.text = round?.round?.order < 6 ? "POINTS" : "";
          }

          edit = edits?.find((e) => e.control.name === "SUBTITLE");
          if (edit) {
            edit.text =
              round?.round?.order < 6
                ? "AFTER EVENT " + round?.round?.order + " OF 6"
                : "FINAL STANDINGS";
          }
          edit = edits?.find((e) => e.control.name === "ROWS");
          if (edit) {
            edit.text = selectedGroup === 6 ? 10 : 5;
          }
          edit = edits?.find((e) => e.control.name === "NUM_ATHLETES");
          if (edit) {
            edit.text = 10;
          }

          edit = edits?.find((e) => e.control.name === "HEAT");
          if (edit) {
            edit.text = "HEAT " + group?.group?.name;
          }
          let standings = [...(round?.standings || [])]?.sort((a, b) => {
            if (a?.overall_position < b?.overall_position) {
              return -1;
            }
            if (a?.overall_position > b?.overall_position) {
              return 1;
            }
            return a.player?.last_name.localeCompare(b.player?.last_name);
          });
          for (let i = 0; i < standings.length; i++) {
            let player = standings[i]?.player;
            let row = i + 1;

            edit = edits?.find((e) => e.control.name === "NAME_" + row);
            if (edit) {
              edit.text = edit.text = player?.last_name
                ?.substring(0, 3)
                ?.toUpperCase()
                ?.replace("O'D", "O'DW");
            }
            edit = edits?.find((e) => e.control.name === "NAT_" + row);
            if (edit) {
              edit.image = FLAGS[player?.nationality];
            }

            edit = edits?.find((e) => e.control.name === "POS_" + row);
            if (edit) {
              edit.text =
                (standings[i]?.overall_tied ? "T" : "") +
                standings[i]?.overall_position;
            }

            edit = edits?.find((e) => e.control.name === "COL1_" + row);
            if (edit) {
              edit.text = "";
            }

            edit = edits?.find((e) => e.control.name === "COL2_" + row);
            if (edit) {
              edit.text =
                round?.round?.order < 6 || selectedGroup === 6
                  ? standings[i]?.total_points
                  : "";
            }
          }
          await savePage(
            index,
            edits,
            "After Event " + round.round?.order,
            bio_scene
          );
          index++;
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildReps() {
    let scene_left = scenes.find((s) => s.name === "Rep_Counter_Left");
    let scene_right = scenes.find((s) => s.name === "Rep_Counter_Right");
    selectScene(scene_left);

    let index = 0;
    for (let g = 0; g < competition.length; g++) {
      index++;
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];

          let players = [...(round?.standings || [])]
            ?.sort((a, b) => {
              return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
            })
            ?.map((s) => s.player);

          let standings_sorted = [...(round?.standings || [])]?.sort((a, b) => {
            return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
          });

          let rtb = 0;
          for (let p = 0; p < standings_sorted.length; p++) {
            for (let y = 0; y < 2; y++) {
              let edits = [];
              if (y === 0) {
                getEdits(scene_left?.data?.scene?.children, edits);
              } else {
                getEdits(scene_right?.data?.scene?.children, edits);
              }

              let player = standings_sorted[p];
              if (
                round.round?.event.event_type === "reps" &&
                round.round?.event.unit_name === "Reps"
              ) {
                let edit = edits?.find((e) => e.control.name === "NAME");
                if (edit) {
                  edit.text =
                    (player?.player?.last_name?.toUpperCase() === "STOLTMAN"
                      ? player?.player?.first_name
                          ?.toUpperCase()
                          ?.replace("LUKE", "L. ")
                          ?.replace("TOM", "T. ")
                      : "") + player?.player?.last_name?.toUpperCase();
                }

                edit = edits?.find((e) => e.control.name === "TOBEAT");
                if (edit) {
                  edit.text =
                    parseInt(player?.scoring?.order) !== 1
                      ? "REPS TO BEAT: " + rtb
                      : "";
                }

                edit = edits?.find((e) => e.control.name === "ICON");
                if (edit) {
                  edit.image = ICONS[round.round?.event.name].image;
                  edit.width = ICONS[round.round?.event.name].width;
                  edit.height = ICONS[round.round?.event.name].height;
                }

                edit = edits?.find((e) => e.control.name === "REPS");
                if (edit) {
                  edit.text = player?.scoring?.reps;
                }

                edit = edits?.find((e) => e.control.name === "SHOW_RTB");

                if (edit) {
                  edit.visible = true || parseInt(player?.scoring?.order) !== 1;
                }
                if (y === 1) {
                  rtb = Math.max(rtb, parseInt(player?.scoring?.reps));
                }
                await savePage(
                  index,
                  edits,
                  "Event " +
                    round.round?.order +
                    " - " +
                    player?.player?.last_name?.toUpperCase() +
                    " - " +
                    (y === 0 ? "LEFT" : "RIGHT"),
                  y === 0 ? scene_left : scene_right
                );
                index++;
              }
            }
          }
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildDegreeCounters() {
    let scene_left = scenes.find((s) => s.name === "Degree_Counter");
    selectScene(scene_left);

    let index = 0;
    for (let g = 0; g < competition.length; g++) {
      index++;
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];

          let players = [...(round?.standings || [])]
            ?.sort((a, b) => {
              return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
            })
            ?.map((s) => s.player);

          let standings_sorted = [...(round?.standings || [])]?.sort((a, b) => {
            return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
          });

          let rtb = 0;
          for (let p = 0; p < standings_sorted.length; p++) {
            let edits = [];

            getEdits(scene_left?.data?.scene?.children, edits);

            let player = standings_sorted[p];
            if (
              round.round?.event.event_type === "reps" &&
              round.round?.event.unit_name === "Degrees"
            ) {
              let edit = edits?.find((e) => e.control.name === "TOBEAT");
              if (edit) {
                edit.text =
                  parseInt(player?.scoring?.order) !== 1 ? rtb + "°" : "";
              }

              edit = edits?.find((e) => e.control.name === "ICON");
              if (edit) {
                edit.image = ICONS[round.round?.event.name].image;
                edit.width = ICONS[round.round?.event.name].width;
                edit.height = ICONS[round.round?.event.name].height;
              }

              edit = edits?.find((e) => e.control.name === "TARGET");
              if (edit) {
                edit.text = player?.scoring?.reps;
              }

              rtb = Math.max(rtb, parseInt(player?.scoring?.reps));

              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player?.player?.last_name?.toUpperCase(),
                scene_left
              );
              index++;
            }
          }
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  async function buildHeadToHead() {
    let bio_scene = scenes.find((s) => s.name === "FF - Head2Head");
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);

    let index = 0;
    for (let g = 0; g < competition.length; g++) {
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];
          if (
            round.round?.event.name === "Log Ladder" ||
            round.round?.event.name === "Webster Stones" ||
            round.round?.event.name === "Deadlift Ladder" ||
            round.round?.event.name === "Sandbag Steeplechase" ||
            round.round?.event.name === "Globe Viking Press" ||
            round.round?.event.name === "Car Walk" ||
            round.round?.event.name === "Stone Off"
          ) {
            index++;
            let standings_sorted = [...(round?.standings || [])]
              ?.filter((s) => {
                return s.scoring;
              })
              ?.sort((a, b) => {
                return (
                  parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order)
                );
              });
            for (let p = 0; p < standings_sorted.length; p++) {
              let player_1 = standings_sorted[p];
              let edit = edits?.find(
                (e) => e.control.name === "FIRST_NAME_LEFT"
              );
              if (edit) {
                edit.text = player_1?.player?.first_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "LAST_NAME_LEFT");
              if (edit) {
                edit.text = player_1?.player?.last_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "FLAG_LEFT");
              if (edit) {
                edit.image = FLAGS[player_1?.player?.nationality];
              }
              edit = edits?.find((e) => e.control.name === "LEFT_ATHLETE");
              if (edit) {
                let image = player_1?.player?.last_name
                  ?.toUpperCase()
                  ?.replace("O'DWYER", "ODWYER");
                if (
                  player_1?.player?.last_name?.toUpperCase() === "STOLTMAN" &&
                  player_1?.player?.first_name?.toUpperCase() === "TOM"
                ) {
                  image = "TSTOLTMAN";
                }
                if (
                  player_1?.player?.last_name?.toUpperCase() === "STOLTMAN" &&
                  player_1?.player?.first_name?.toUpperCase() === "LUKE"
                ) {
                  image = "LSTOLTMAN";
                }
                edit.image = image + ".png";
              }
              p++;
              let player_2 = standings_sorted[p];
              edit = edits?.find((e) => e.control.name === "FIRST_NAME_RIGHT");
              if (edit) {
                edit.text = player_2?.player?.first_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "LAST_NAME_RIGHT");
              if (edit) {
                edit.text = player_2?.player?.last_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "FLAG_RIGHT");
              if (edit) {
                edit.image = FLAGS[player_2?.player?.nationality];
              }
              edit = edits?.find((e) => e.control.name === "RIGHT_ATHLETE");
              if (edit) {
                let image = player_2?.player?.last_name
                  ?.toUpperCase()
                  ?.replace("O'DWYER", "ODWYER");
                if (
                  player_2?.player?.last_name?.toUpperCase() === "STOLTMAN" &&
                  player_2?.player?.first_name?.toUpperCase() === "TOM"
                ) {
                  image = "TSTOLTMAN";
                }
                if (
                  player_2?.player?.last_name?.toUpperCase() === "STOLTMAN" &&
                  player_2?.player?.first_name?.toUpperCase() === "LUKE"
                ) {
                  image = "LSTOLTMAN";
                }
                edit.image = image + ".png";
              }
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player_1?.player?.last_name +
                  " vs " +
                  player_2?.player?.last_name,
                bio_scene
              );

              index++;

              edit = edits?.find((e) => e.control.name === "FIRST_NAME_LEFT");
              if (edit) {
                edit.text = player_2?.player?.first_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "LAST_NAME_LEFT");
              if (edit) {
                edit.text = player_2?.player?.last_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "FLAG_LEFT");
              if (edit) {
                edit.image = FLAGS[player_2?.player?.nationality];
              }
              edit = edits?.find((e) => e.control.name === "LEFT_ATHLETE");
              if (edit) {
                let image = player_2.player?.last_name
                  ?.toUpperCase()
                  ?.replace("O'DWYER", "ODWYER");
                if (
                  player_2?.player?.last_name?.toUpperCase() === "STOLTMAN" &&
                  player_2?.player?.first_name?.toUpperCase() === "TOM"
                ) {
                  image = "TSTOLTMAN";
                }
                if (
                  player_2?.player?.last_name?.toUpperCase() === "STOLTMAN" &&
                  player_2?.player?.first_name?.toUpperCase() === "LUKE"
                ) {
                  image = "LSTOLTMAN";
                }
                edit.image = image + ".png";
              }

              edit = edits?.find((e) => e.control.name === "FIRST_NAME_RIGHT");
              if (edit) {
                edit.text = player_1?.player?.first_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "LAST_NAME_RIGHT");
              if (edit) {
                edit.text = player_1?.player?.last_name?.toUpperCase();
              }
              edit = edits?.find((e) => e.control.name === "FLAG_RIGHT");
              if (edit) {
                edit.image = FLAGS[player_1?.player?.nationality];
              }
              edit = edits?.find((e) => e.control.name === "RIGHT_ATHLETE");
              if (edit) {
                let image = player_1?.player?.last_name
                  ?.toUpperCase()
                  ?.replace("O'DWYER", "ODWYER");
                if (
                  player_1?.player?.last_name?.toUpperCase() === "STOLTMAN" &&
                  player_1?.player?.first_name?.toUpperCase() === "TOM"
                ) {
                  image = "TSTOLTMAN";
                }
                if (
                  player_1?.player?.last_name?.toUpperCase() === "STOLTMAN" &&
                  player_1?.player?.first_name?.toUpperCase() === "LUKE"
                ) {
                  image = "LSTOLTMAN";
                }
                edit.image = image + ".png";
              }
              await savePage(
                index,
                edits,
                "Event " +
                  round.round?.order +
                  " - " +
                  player_2?.player?.last_name +
                  " vs " +
                  player_1?.player?.last_name,
                bio_scene
              );

              index++;
              if (round.round?.event.name === "Stone Off") {
                p = 100;
              }
            }
          }
        }
      }
    }

    changeSelectedShow(selectedShow);
  }

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  async function buildSideTableOrders() {
    let bio_scene = scenes.find((s) => s.name === "Side_Table_Orders");
    if (selectedGroup === 6) {
      bio_scene = scenes.find((s) => s.name === "Side_Table_Orders");
    }
    selectScene(bio_scene);
    let edits = [];
    getEdits(bio_scene?.data?.scene?.children, edits);

    for (let g = 0; g < competition.length; g++) {
      let group = competition[g];

      if (group?.group?.name === selectedGroup?.toString()) {
        let index = 1;

        for (let r = 0; r < group.rounds.length; r++) {
          let round = group.rounds[r];

          let edit = edits?.find((e) => e.control.name === "TITLE");
          if (edit) {
            if (selectedGroup === 6) {
              edit.text = "THE GRAND FINAL";
            } else {
              edit.text = "HEAT " + group?.group?.name;
            }
          }
          edit = edits?.find((e) => e.control.name === "ICON");
          if (edit) {
            edit.image = ICONS[round.round?.event.name].image;
            edit.width = ICONS[round.round?.event.name].width * 3;
            edit.height = ICONS[round.round?.event.name].height * 3;
          }

          let players = [...(round?.standings || [])]
            ?.sort((a, b) => {
              return parseInt(a?.scoring?.order) - parseInt(b?.scoring?.order);
            })
            ?.map((s) => s.player);

          let standings = [...(round?.standings || [])]?.sort((a, b) => {
            if (parseInt(a?.scoring?.order) > parseInt(b?.scoring?.order)) {
              return 1;
            }
            if (parseInt(a?.scoring?.order) < parseInt(b?.scoring?.order)) {
              return -1;
            }
            if (a?.scoring?.lane > b?.scoring?.lane) {
              return 1;
            }
            if (a?.scoring?.lane < b?.scoring?.lane) {
              return -1;
            }
          });

          for (let i = 0; i < standings.length; i++) {
            let player = standings[i]?.player;
            let row = i + 1;

            edit = edits?.find((e) => e.control.name === "NAME_" + row);
            if (edit) {
              edit.text =
                player?.first_name + " " + player?.last_name?.toUpperCase();

              edit.visible = round?.round?.order === 6 && i > 1 ? false : true;
            }
            edit = edits?.find((e) => e.control.name === "FLAG_" + row);
            if (edit) {
              edit.image = FLAGS[player?.nationality];
              edit.visible = round?.round?.order === 6 && i > 1 ? false : true;
            }
          }

          await savePage(index, edits, round.round?.event?.name, bio_scene);
          index++;
        }
      }

      changeSelectedShow(selectedShow);
    }
  }

  return (
    <Main>
      <Row>
        <BuildButton
          enabled={selectedGroup === 1}
          onClick={() => {
            setSelectedGroup(1);
          }}
        >
          Group 1
        </BuildButton>
        <BuildButton
          enabled={selectedGroup === 2}
          onClick={() => {
            setSelectedGroup(2);
          }}
        >
          Group 2
        </BuildButton>
        <BuildButton
          enabled={selectedGroup === 3}
          onClick={() => {
            setSelectedGroup(3);
          }}
        >
          Group 3
        </BuildButton>
        <BuildButton
          enabled={selectedGroup === 4}
          onClick={() => {
            setSelectedGroup(4);
          }}
        >
          Group 4
        </BuildButton>
        <BuildButton
          enabled={selectedGroup === 5}
          onClick={() => {
            setSelectedGroup(5);
          }}
        >
          Group 5
        </BuildButton>
        <BuildButton
          enabled={selectedGroup === 6}
          onClick={() => {
            setSelectedGroup(6);
          }}
        >
          Final
        </BuildButton>
      </Row>
      <Row>
        <BuildButton
          onClick={() => {
            buildPlayerBios();
          }}
        >
          Build Athlete Intros
        </BuildButton>
        <BuildButton
          onClick={() => {
            buildPlayerResults();
          }}
        >
          Build Athlete Results
        </BuildButton>
        <BuildButton
          onClick={() => {
            buildResultBoards();
          }}
        >
          Build Event Tables
        </BuildButton>
        <BuildButton
          onClick={() => {
            buildStandings();
          }}
        >
          Build FF Standings
        </BuildButton>
        <BuildButton
          onClick={() => {
            buildSideTableOrders();
          }}
        >
          Build Side Tables Orders
        </BuildButton>
      </Row>
      <Row>
        <BuildButton
          onClick={() => {
            buildSideTable();
          }}
        >
          Build Side Tables Standings
        </BuildButton>
        {/* <BuildButton
          onClick={() => {
            buildEventBugs();
          }}
        >
          Build Event Bugs
        </BuildButton>
        <BuildButton
          onClick={() => {
            buildMiniTablesEvent();
          }}
        >
          Build Event Mini Tables
        </BuildButton> */}
        {/* <BuildButton
          onClick={() => {
            buildMiniStandings();
          }}
        >
          Build Mini Standings
        </BuildButton> */}
        <BuildButton
          onClick={() => {
            buildReps();
          }}
        >
          Build Rep Counters
        </BuildButton>
        <BuildButton
          onClick={() => {
            buildDegreeCounters();
          }}
        >
          Build Degree Counters
        </BuildButton>
        {/* <BuildButton
          onClick={() => {
            buildHeadToHead();
          }}
        >
          Build Head to Head
        </BuildButton> */}
      </Row>
    </Main>
  );
}
