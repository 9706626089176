import React, { useContext, useEffect, useState, useCallback } from "react";
import { OptaCricketContext } from "../../contexts/OptaCricketContext";
import Opta from "../Opta/Opta";
import styled from "styled-components";
import HeadToHead from "../Opta/HeadToHead";
import { ScrollBar } from "../../Theme/Hyper";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ControlContext } from "../../contexts/ControlContext";

const dismmisallID = [
  {
    id: 0,
    description: "not out",
    graphicDescription: "not out",
  },
  {
    id: 1,
    description: "bowled",
    graphicDescription: "",
  },
  {
    id: 2,
    description: "caught and bowled",
    graphicDescription: "",
  },
  {
    id: 3,
    description: "caught",
    graphicDescription: "c",
  },
  {
    id: 4,
    description: "handled the ball",
    graphicDescription: "handled the ball",
  },
  {
    id: 5,
    description: "hit ball twice",
    graphicDescription: "hit ball twice",
  },
  {
    id: 6,
    description: "hit wicket",
    graphicDescription: "hit wicket",
  },
  {
    id: 7,
    description: "lbw",
    graphicDescription: "lbw",
  },
  {
    id: 8,
    description: "obstructing the field",
    graphicDescription: "obstructing the field",
  },
  {
    id: 9,
    description: "retired hurt",
    graphicDescription: "retired hurt",
  },
  {
    id: 10,
    description: "retired out",
    graphicDescription: "retired out",
  },
  {
    id: 11,
    description: "run out",
    graphicDescription: "run out",
  },
  {
    id: 12,
    description: "stumped",
    graphicDescription: "stumped",
  },
  {
    id: 13,
    description: "timed out",
    graphicDescription: "timed out",
  },
  {
    id: 14,
    description: "caught (sub)",
    graphicDescription: "caught (sub)",
  },
  {
    id: 15,
    description: "run out (sub)",
    graphicDescription: "run out (sub)",
  },
  {
    id: 16,
    description: "absent hurt",
    graphicDescription: "absent hurt",
  },
  {
    id: 17,
    description: "retired not out",
    graphicDescription: "retired not out",
  },
  {
    id: 18,
    description: "stumped (sub)",
    graphicDescription: "stumped (sub)",
  },
];

const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#373b48")};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
  width: 100%;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;
export default function OptaCricketBatting() {
  const { scorecardData, fixturesData } = useContext(OptaCricketContext);
  const {
    setDataDrive,
    selectedPage,
    edits,
    clear,
    selectedScene,
  } = useContext(ProjectContext);
  const { preview, animateOff, animate, triggerAnimation } = useContext(
    ControlContext
  );
  const [innings, setInnings] = useState(1);
  const [team, setTeam] = useState(1);
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        // animate("FF - Standings", edits, "IN");
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        // animateOff("FF - Standings", []);
        clear();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [edits]);

  const updateData = useCallback(() => {
    try {
      if (scorecardData) {
        for (let i = 1; i < 12; i++) {
          edits.find((edit) => edit.control.name === i + "_LAST").text = "";
          edits.find((edit) => edit.control.name === i + "_FIRST").text = "";
          edits.find((edit) => edit.control.name === i + "_HOW").text = "";
          edits.find((edit) => edit.control.name === i + "_HOW_NAME").text = "";
          edits.find((edit) => edit.control.name === i + "_HOW_2").text = "";
          edits.find((edit) => edit.control.name === i + "_HOW_BOWL").text = "";
          edits.find((edit) => edit.control.name === i + "_RUNS").text = "";
          edits.find((edit) => edit.control.name === i + "_BALLS").text = "";
          edits.find((edit) => edit.control.name === i + "_RUNS").text = "";
          edits.find((edit) => edit.control.name === i + "_RUNS").text = "";
        }
        try {
          edits.find((edit) => edit.control.name === "12_LAST").text = "";
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === "12_HOW_NAME").text = "";
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === "12_RUNS").text = "";
        } catch (err) {}

        try {
          edits.find((edit) => edit.control.name === "HEADER_TXT_1").text =
            innings === 1 ? "1st Innings" : "2nd Innings";
        } catch (err) {}

        let battingTeam = scorecardData.CricketMatchSummary.PlayerDetail.Team.find(
          (t) => t._attributes.home_or_away === (team === 1 ? "home" : "away")
        );
        let bowlingTeam = scorecardData.CricketMatchSummary.PlayerDetail.Team.find(
          (t) => t._attributes.home_or_away === (team === 1 ? "away" : "home")
        );

        let batting = [];
        if (Array.isArray(scorecardData.CricketMatchSummary.Innings)) {
          batting = scorecardData.CricketMatchSummary.Innings.filter(
            (i) =>
              i._attributes.batting_team_id === battingTeam._attributes.team_id
          );
        } else {
          batting.push(scorecardData.CricketMatchSummary.Innings);
        }

        batting = batting[innings - 1];
        batting.Batsmen.Batsman.map((batter, index) => {
          let row = index + 1;
          let name = battingTeam.Player.find(
            (p) => p._attributes.id === batter._attributes.id
          );

          let caughtName = bowlingTeam.Player.find(
            (p) => p._attributes.id === batter._attributes.caught_by
          );
          let bowledName = bowlingTeam.Player.find(
            (p) => p._attributes.id === batter._attributes.bowled_by
          );

          let notOut = batter._attributes.how_out === "Not Out";

          try {
            edits.find(
              (edit) => edit.control.name === row + "_SELECTED"
            ).text = notOut ? 1 : 0;
          } catch (err) {}

          try {
            edits.find((edit) => edit.control.name === row + "_LAST").text =
              name._attributes.player_last_name;
          } catch (err) {}
          try {
            edits.find((edit) => edit.control.name === row + "_FIRST").text =
              name._attributes.player_first_name[0];
          } catch (err) {}
          try {
            edits.find(
              (edit) => edit.control.name === row + "_HOW"
            ).text = notOut
              ? "not out"
              : getHowOut(batter._attributes.dismissal_id);
          } catch (err) {}
          try {
            edits.find(
              (edit) => edit.control.name === row + "_HOW_NAME"
            ).text = caughtName ? caughtName._attributes.player_last_name : "";
          } catch (err) {}
          try {
            edits.find(
              (edit) => edit.control.name === row + "_HOW_2"
            ).text = bowledName ? "b" : "";
          } catch (err) {}
          try {
            edits.find(
              (edit) => edit.control.name === row + "_HOW_BOWL"
            ).text = bowledName ? bowledName._attributes.player_last_name : "";
          } catch (err) {}

          try {
            edits.find((edit) => edit.control.name === row + "_RUNS").text =
              batter._attributes.runs_scored;
          } catch (err) {}
          try {
            edits.find((edit) => edit.control.name === row + "_BALLS").text =
              batter._attributes.balls_faced;
          } catch (err) {}
        });
        try {
          edits.find((edit) => edit.control.name === "12_LAST").text =
            batting.Extras._attributes.total_extras;
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === "12_HOW_NAME").text =
            batting.Total._attributes.overs;
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === "12_RUNS").text =
            batting.Total._attributes.runs_scored +
            (batting.Total._attributes.wickets !== "10"
              ? "-" + batting.Total._attributes.wickets
              : "");
        } catch (err) {}
        preview("CRICKET_Batting_Card", 25, edits, "IN");
      }
    } catch (err) {
      preview("CRICKET_Batting_Card", 25, edits, "IN");
    }
  }, [scorecardData, edits, innings, team]);

  function getHowOut(id) {
    return dismmisallID.find((d) => d.id === parseInt(id)).graphicDescription;
  }

  useEffect(() => {
    if (selectedPage) {
      let dataDrive = [
        {
          name: "OptaCricket",
          innings: innings,
          team: team,
        },
      ];
      setDataDrive(dataDrive);
    } else if (selectedScene) {
      let dataDrive = [
        {
          name: "OptaCricket",
          innings: innings,
          team: team,
        },
      ];
      setDataDrive(dataDrive);
    }
  }, [selectedPage, selectedScene, innings, team]);

  useEffect(() => {
    if (selectedPage && selectedPage.dataDrive) {
      let data = selectedPage.dataDrive.find((d) => d.name === "OptaCricket");
      if (data) {
        setInnings(data.innings);
        setTeam(data.team);
      }
    }
  }, [selectedPage]);

  return (
    <EditArea>
      {/* <Dropown
        onChange={(e) => {
          // setMatchID(e.currentTarget.value);
        }}
      >
        <option disabled selected value>
          -- select event --
        </option>
        {fixturesData.map((item) => {
          return (
            <DropownOption
              value={item["@attributes"].uID.replace("g", "")}
              // selected={matchID === item["@attributes"].uID.replace("g", "")}
            >
              OPtion
            </DropownOption>
          );
        })}
      </Dropown> */}
      <Controls>
        <Column>
          <Button
            onClick={() => {
              updateData();
            }}
          >
            UPDATE DATA
          </Button>
        </Column>
        <Column>
          <Button
            selected={innings === 1}
            onClick={() => {
              setInnings(1);
            }}
          >
            1ST INNINGS
          </Button>
          <Button
            selected={innings === 2}
            onClick={() => {
              setInnings(2);
            }}
          >
            2ND INNINGS
          </Button>
        </Column>
        <Column>
          <Button
            selected={team === 1}
            onClick={() => {
              setTeam(1);
            }}
          >
            HOME TEAM
          </Button>
          <Button
            selected={team === 2}
            onClick={() => {
              setTeam(2);
            }}
          >
            AWAY TEAM
          </Button>
        </Column>
      </Controls>
    </EditArea>
  );
}
