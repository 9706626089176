import React, { useState, useContext } from "react";
import styled from "styled-components";
import NewShow from "../NewShow/NewShow";
import FolderItem from "./FolderItem";
import { FiArchive, FiPlusCircle } from "react-icons/fi";
import NewFolder from "../NewFolder/NewFolder";
import PopupOverlay from "../Popup/Popup";
import JSZip from "jszip";

const Main = styled.div`
  display: flex;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  height: 25px;
  font-size: 0.8em;
  align-items: center;
  padding-left: 0.5em;
  :nth-of-type(even) {
    background-color: #1c222b;
  }
  :hover {
    background-color: #d11e42;
  }
`;

const Popup = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;

const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;

const FolderList = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #2e3a50;
  width: 40%;
  height: 100%;
`;

const Shows = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0.5em;
`;

const PlusIcon = styled(FiPlusCircle)`
  margin-left: ${(props) => (props.alignright ? "auto" : "")};
  :hover {
    color: #d11e42;
  }
`;
const ArchiveIcon = styled(FiArchive)`
  margin-left: auto;
  margin-right: 1em;
  color: ${(props) => (props.selected ? "#d11e42" : "")};
  :hover {
    color: #d11e42;
  }
`;

const ActionPanel = styled.div`
  display: flex;
  padding: 0.5em;
  border-bottom: 1px solid #202731;
`;

const Colour = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.colour};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 0.5em;
`;
export default function ShowList({
  shows,
  selectShow,
  deleteShow,
  renameShow,
  renameFolder,
  duplicateShow,
  themes,
  archiveShow,
  selectedShowFolder,
  user,
  createShow,
  setShowView,
  createShowFolder,
  folders,
  deleteFolder,
  exportShow,
  importShow,
  importPages,
}) {
  const [contextMenuItem, setContextMenuItem] = useState(null);
  const [contextFolderMenuItem, setContextFolderMenuItem] = useState(null);
  const [showRename, setShowRename] = useState(false);
  const [folderRename, setFolderRename] = useState(false);
  const [archive, setShowArchive] = useState(false);
  const [newShow, setNewShow] = useState(false);
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [selectedShow, setSelectedShow] = useState();
  const [selectedFolder, setSelectedFolder] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  function handleContextMenu(e, item, theme, show) {
    setContextMenuItem({
      item: item,
      theme: theme,
      mouseX: e.clientX,
      mouseY: e.clientY,
      showContext: true,
    });
    setSelectedShow(show);
    e.preventDefault();
    window.addEventListener("click", handleClick);
  }

  function handleContextFolderMenu(e, item, theme, folder) {
    setContextFolderMenuItem({
      item: item,
      mouseX: e.clientX,
      mouseY: e.clientY,
      showContext: true,
    });
    setSelectedFolder(item);
    e.preventDefault();
    window.addEventListener("click", handleClick);
  }

  function handleClick() {
    setContextMenuItem({
      ...contextMenuItem,
      showContext: false,
    });
    setContextFolderMenuItem({
      ...contextFolderMenuItem,
      showContext: false,
    });
    window.removeEventListener("click", handleClick);
  }

  let sorted = [
    ...shows.map((show) => ({
      name: show.folder,
    })),
  ];
  if (shows && shows.children) {
    sorted = shows.children.sort((a, b) => {
      var nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  function processZip(e) {
    var file = e.target.files[0];
    let index = 0;

    let promises = [];
    let pages = [];
    JSZip.loadAsync(file) // 1) read the Blob
      .then(function (zip) {
        zip.forEach(async (relativePath, zipEntry) => {
          if (
            relativePath.indexOf("pages") === 0 &&
            zipEntry.dir === false &&
            index === 0
          ) {
            promises.push(
              zip
                .file(relativePath)
                .async("string")
                .then(function (data) {
                  let json = JSON.parse(data);
                  pages.push(json);
                })
            );
          }
        });
        Promise.all(promises).then(async () => {
          await importPages(selectedShow, pages);
          selectShow(selectedShow);
          setShowView(0);
        });
      });
  }

  function processZipForShow(e) {
    var file = e.target.files[0];
    let index = 0;

    let promises = [];
    let pages = [];
    JSZip.loadAsync(file) // 1) read the Blob
      .then(function (zip) {
        zip.forEach(async (relativePath, zipEntry) => {
          if (
            relativePath.indexOf("pages") === 0 &&
            zipEntry.dir === false &&
            index === 0
          ) {
            promises.push(
              zip
                .file(relativePath)
                .async("string")
                .then(function (data) {
                  let json = JSON.parse(data);
                  pages.push(json);
                })
            );
          }
        });
        Promise.all(promises).then(async () => {
          let new_show = await importShow(pages);
          selectShow(new_show);
          setShowView(0);
        });
      });
  }

  return (
    <>
      <Main>
        <FolderList>
          <ActionPanel>
            <PlusIcon
              alignRight={"true"}
              onClick={() => setShowNewFolder(true)}
            />
          </ActionPanel>
          {[...(folders || [])]
            ?.sort((a, b) => {
              debugger;
              var nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();
              if (nameA < nameB)
                //sort string ascending
                return -1;
              if (nameA > nameB) return 1;
              return 0; //default return value (no sorting)
            })
            ?.map((resource, index) => {
              return (
                <FolderItem
                  key={index}
                  resource={resource}
                  onContextMenu={(e, item) => {
                    handleContextFolderMenu(e, resource);
                  }}
                />
              );
            })}
        </FolderList>
        <Shows>
          <ActionPanel>
            {/* <ArchiveIcon
            selected={archive}
            onClick={() => setShowArchive(!archive)}
          /> */}
            {selectedShowFolder && (
              <PlusIcon alignright={true} onClick={() => setNewShow(true)} />
            )}
          </ActionPanel>

          {selectedShowFolder &&
            shows
              .filter((shows) => {
                return shows.folder?._id === selectedShowFolder._id;
              })
              .filter((show) => {
                return !show.archived || show.archived === archive;
              })
              .sort((a, b) => {
                var nameA = a.name.toLowerCase(),
                  nameB = b.name.toLowerCase();
                if (nameA < nameB)
                  //sort string ascending
                  return -1;
                if (nameA > nameB) return 1;
                return 0; //default return value (no sorting)
              })
              .map((show) => {
                return (
                  <Row
                    key={show._id}
                    onClick={() => {
                      selectShow(show);
                    }}
                    onContextMenu={(e) => {
                      handleContextMenu(e, show.name, show.theme, show);
                    }}
                  >
                    <Colour
                      colour={
                        themes
                          .find((theme) => theme.name === show.theme)
                          ?.data.find((d) => d.name === "primaryBlockColour")
                          .value
                      }
                    ></Colour>
                    {show.name}
                  </Row>
                );
              })}
          {contextMenuItem && contextMenuItem.showContext && (
            <Popup
              style={{
                position: "absolute",
                left: contextMenuItem.mouseX + 10,
                top: contextMenuItem.mouseY,
              }}
            >
              <PopupGroup>
                <PopupItem
                  onClick={() => {
                    setShowRename(true);
                  }}
                >
                  Rename
                </PopupItem>
                <PopupItem
                  onClick={() => {
                    duplicateShow(selectedShow._id, contextMenuItem.item);
                  }}
                >
                  Duplicate
                </PopupItem>
                <PopupItem
                  onClick={() => {
                    deleteShow(selectedShow._id);
                  }}
                >
                  Delete
                </PopupItem>
                <PopupItem
                  onClick={() => {
                    exportShow(selectedShow._id, selectedShow.name);
                  }}
                >
                  Export
                </PopupItem>
                <PopupItem
                  onClick={async () => {
                    document.getElementById("import_pages").onchange = (e) => {
                      processZip(e);
                    };
                    document.getElementById("import_pages").click();
                    document.getElementById("import_pages").value = null;
                  }}
                >
                  Import pages
                </PopupItem>
              </PopupGroup>
              <input
                type="file"
                id="import_pages"
                name="file"
                accept=".studio"
                style={{ display: "none" }}
              />
            </Popup>
          )}
          {contextFolderMenuItem && contextFolderMenuItem.showContext && (
            <Popup
              style={{
                position: "absolute",
                left: contextFolderMenuItem.mouseX + 10,
                top: contextFolderMenuItem.mouseY,
              }}
            >
              <PopupGroup>
                <PopupItem
                  onClick={() => {
                    setFolderRename(true);
                  }}
                >
                  Rename
                </PopupItem>
                <PopupItem
                  onClick={() => {
                    setShowDeletePopup(true);
                  }}
                >
                  Delete
                </PopupItem>
                <PopupItem
                  onClick={async () => {
                    document.getElementById("import_show").onchange = (e) => {
                      processZipForShow(e);
                    };
                    document.getElementById("import_show").click();
                    document.getElementById("import_show").value = null;
                  }}
                >
                  Import show
                </PopupItem>
              </PopupGroup>
              <input
                type="file"
                id="import_show"
                name="file"
                accept=".studio"
                style={{ display: "none" }}
              />
            </Popup>
          )}
          {showRename && (
            <NewShow
              themes={themes}
              title={"Rename Show"}
              value={contextMenuItem.item}
              theme={contextMenuItem.theme}
              onCancel={() => setShowRename(false)}
              onSave={(value, oldValue, theme) => {
                renameShow(selectedShow._id, value, theme);
                setShowRename(false);
              }}
            />
          )}
          {folderRename && (
            <NewShow
              themes={themes}
              title={"Rename Folder"}
              placeholder="New name..."
              value={contextFolderMenuItem.item?.name}
              onCancel={() => setFolderRename(false)}
              onSave={(value, oldValue, theme) => {
                renameFolder(selectedFolder._id, value);
                setFolderRename(false);
              }}
            />
          )}
        </Shows>
        {newShow && (
          <NewShow
            themes={themes}
            title={"Create Show"}
            onCancel={() => setNewShow(false)}
            onSave={async (value, oldValue, theme) => {
              await createShow(value, theme);

              setNewShow(false);
              setShowView(0);
            }}
          />
        )}
        {showNewFolder && (
          <NewFolder
            onSave={(value) => {
              createShowFolder(value);
              setShowNewFolder(false);
            }}
            onCancel={() => {
              setShowNewFolder(false);
            }}
          />
        )}
        {showDeletePopup && (
          <PopupOverlay
            title={"Delete Folder"}
            message={"DELETING THIS FOLDER WILL ALSO DELETE ITS CONTENTS!!"}
            onSave={() => {
              deleteFolder(selectedFolder._id);
              setShowDeletePopup(false);
            }}
            onCancel={() => {
              setShowDeletePopup(false);
            }}
          />
        )}
      </Main>
    </>
  );
}
