import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const EditArea = styled.div`
  background-color: #191f27;
`;
const TextEditor = styled.textarea`
  background-color: #191f27;
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  width: 80%;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
  height: 98px;
  outline: none;
`;

const Label = styled.div`
  background-color: #191f27;
  margin: 0.5em;
  min-width: 100px;
`;
const Property = styled.div`
  display: flex;
  align-items: center;
`;
const TextInput = styled.input`
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
`;

export default function TextEdit({ selectedEdit, preview, selectedScene }) {
  const [update, setUpdate] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    textRef.current.select();
  }, [selectedEdit]);

  function handleKeyDown(e) {
    if (e.keyCode >= 96 && e.keyCode <= 105) {
      e.preventDefault();
    } else if (e.keyCode === 13 && e.location === 3) {
      e.preventDefault();
    } else if (e.keyCode === 110) {
      e.preventDefault();
    }
  }

  return (
    <EditArea>
      {selectedScene && selectedScene.name !== "LT" && (
        <Property>
          <Label>Font Size</Label>
          <TextInput
            type="number"
            value={selectedEdit?.fontSize || selectedEdit.style?._fontSize}
            min="1"
            onChange={(e) => {
              selectedEdit.fontSize = Math.max(
                1,
                parseInt(e.currentTarget.value)
              );

              preview();
              setUpdate(!update);
            }}
            onWheel={(e) => {
              if (e.deltaY > 0) {
                selectedEdit.fontSize = Math.max(
                  1,
                  parseInt(e.currentTarget.value) - 1
                );
              } else {
                selectedEdit.fontSize = Math.max(
                  1,
                  parseInt(e.currentTarget.value) + 1
                );
              }
            }}
            onKeyDown={handleKeyDown}
          />
        </Property>
      )}

      <TextEditor
        id="TextEdit"
        ref={textRef}
        onChange={(ev) => {
          selectedEdit.text = ev.currentTarget.value;
          preview();
          setUpdate(!update);
        }}
        value={selectedEdit.text}
        onKeyDown={handleKeyDown}
      />
    </EditArea>
  );
}
