import React, { useContext, useEffect, useState, useCallback } from "react";
import { OptaCricketContext } from "../../contexts/OptaCricketContext";
import Opta from "../Opta/Opta";
import styled from "styled-components";
import HeadToHead from "../Opta/HeadToHead";
import { ScrollBar } from "../../Theme/Hyper";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ControlContext } from "../../contexts/ControlContext";
import { FaColumns } from "react-icons/fa";
const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#373b48")};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export default function OptaCricketMiniCard() {
  const { scorecardData } = useContext(OptaCricketContext);
  const {
    setDataDrive,
    selectedPage,
    edits,
    clear,
    selectedScene,
  } = useContext(ProjectContext);
  const { preview, animateOff, animate, triggerAnimation } = useContext(
    ControlContext
  );
  const [innings, setInnings] = useState(1);
  const [team, setTeam] = useState(0);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        // animate("FF - Standings", edits, "IN");
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        // animateOff("FF - Standings", []);
        clear();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [edits]);

  const updateData = useCallback(() => {
    try {
      if (scorecardData) {
        for (let i = 1; i < 12; i++) {
          edits.find((edit) => edit.control.name === i + "_SELECTED").text =
            "0";
          edits.find((edit) => edit.control.name === i + "_LAST").text = "";
          edits.find((edit) => edit.control.name === i + "_HOW").text = "";
          edits.find((edit) => edit.control.name === i + "_HOW_2").text = "";
          edits.find((edit) => edit.control.name === i + "_FIRST").text = "";
          edits.find((edit) => edit.control.name === i + "_LAST_2").text = "";
          edits.find((edit) => edit.control.name === i + "_RUNS").text = "";
        }

        let inningArray = [];
        let numOfRows = 1;
        if (Array.isArray(scorecardData.CricketMatchSummary.Innings)) {
          inningArray = scorecardData.CricketMatchSummary.Innings;
        } else {
          inningArray.push(scorecardData.CricketMatchSummary.Innings);
        }
        inningArray = inningArray.slice(0, innings);

        switch (inningArray.length) {
          case 1:
            numOfRows = 7;
            break;
          case 2:
            numOfRows = 4;
            break;
          case 3:
            numOfRows = 2;
            break;
          case 4:
            numOfRows = 1;
            break;
        }
        let startRow = 1;
        inningArray.map((inn, index) => {
          updateInning(inn, edits, startRow, numOfRows);
          startRow += numOfRows + 1;
        });

        // let inn = innings === 0 ? 1 : innings;
        // if (innings !== 3) {
        //   if (team === 1 || team === 0) {
        //     let rows = 10;

        //     if (innings === 0 && team === 0) {
        //       rows = 1;
        //     } else if (team === 0) {
        //       rows = 4;
        //     } else if (team === 1 && innings === 0) {
        //       rows = 4;
        //     }

        //     updateInning(edits, homeTeam, inn, 1, rows);
        //   }
        //   if (team === 2 || team === 0) {
        //     let rows = 10;
        //     let startRow = 1;
        //     if (innings === 0 && team === 0) {
        //       rows = 1;
        //       startRow = 3;
        //     } else if (team === 0) {
        //       rows = 4;
        //       startRow = 6;
        //     } else if (team === 2 && innings === 0) {
        //       rows = 4;
        //       startRow = 1;
        //     }
        //     updateInning(edits, awayTeam, inn, startRow, rows);
        //   }
        // }
        // if (innings === 0 && team === 0) {
        //   updateInning(edits, homeTeam, 2, 5, 1);
        //   updateInning(edits, awayTeam, 2, 7, 1);
        // } else if (innings === 0 && team === 1) {
        //   updateInning(edits, homeTeam, 2, 6, 4);
        // } else if (innings === 0 && team === 2) {
        //   updateInning(edits, awayTeam, 2, 6, 4);
        // } else if (innings === 3) {
        //   let numOfInnings = 1;
        //   if (Array.isArray(scorecardData.CricketMatchSummary.Innings)) {
        //     numOfInnings = scorecardData.CricketMatchSummary.Innings.length;
        //   }
        //   let inningArray = [];
        //   if (Array.isArray(scorecardData.CricketMatchSummary.Innings)) {
        //     inningArray = scorecardData.CricketMatchSummary.Innings;
        //   } else {
        //     inningArray.push(scorecardData.CricketMatchSummary.Innings);
        //   }
        //   inningArray.map((inn, index) => {
        //     if (index !== 3) {
        //       let team =
        //         inn._attributes.batting_team_id === homeTeam._attributes.team_id
        //           ? homeTeam
        //           : awayTeam;
        //       updateInning(edits, team, index < 2 ? 1 : 2, index * 3 + 1, 2);
        //     }
        //   });
        // }

        try {
          if (innings === 1) {
            edits.find(
              (edit) => edit.control.name === "FOOTER_TEXT"
            ).text = scorecardData.CricketMatchSummary.MatchDetail._attributes.toss.replace(
              ".",
              ""
            );
          } else {
            edits.find((edit) => edit.control.name === "FOOTER_TEXT").text =
              scorecardData.CricketMatchSummary.MatchDetail._attributes.result;
          }
        } catch (err) {}

        preview("CRICKET_MiniCard1st", 25, edits, "IN");
      }
    } catch (err) {
      preview("CRICKET_MiniCard1st", 25, edits, "IN");
    }
  }, [scorecardData, edits, innings, team]);

  function updateInning(inningData, edits, startRow, numOfRows) {
    let batting = [];

    let battingTeam = scorecardData.CricketMatchSummary.PlayerDetail.Team.find(
      (t) => t._attributes.team_id === inningData._attributes.batting_team_id
    );
    let bowlingTeam = scorecardData.CricketMatchSummary.PlayerDetail.Team.find(
      (t) => t._attributes.team_id === inningData._attributes.bowling_team_id
    );

    try {
      edits.find(
        (edit) => edit.control.name === startRow + "_SELECTED"
      ).text = 1;
    } catch (err) {}
    try {
      edits.find((edit) => edit.control.name === startRow + "_LAST").text =
        battingTeam._attributes.team_name;
    } catch (err) {}
    try {
      edits.find((edit) => edit.control.name === startRow + "_HOW_2").text =
        inningData._attributes.id === "1" || inningData._attributes.id === "2"
          ? "1st Innings"
          : "2nd Innings";
    } catch (err) {}

    try {
      edits.find((edit) => edit.control.name === startRow + "_HOW").text =
        inningData.Total._attributes.runs_scored +
        (inningData.Total._attributes.wickets !== "10"
          ? "-" + inningData.Total._attributes.wickets
          : "");
    } catch (err) {}

    let sortedBatters = inningData.Batsmen.Batsman.sort(
      (a, b) => b._attributes.runs_scored - a._attributes.runs_scored
    );

    sortedBatters = sortedBatters.slice(0, numOfRows);

    sortedBatters.map((batter, index) => {
      index = startRow + 1 + index;
      if (batter._attributes.balls_faced !== "") {
        let name = battingTeam.Player.find(
          (p) => p._attributes.id === batter._attributes.id
        );
        try {
          edits.find((edit) => edit.control.name === index + "_FIRST").text =
            name._attributes.player_first_name;
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === index + "_LAST").text =
            name._attributes.player_last_name;
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === index + "_HOW").text =
            batter._attributes.runs_scored +
            (batter._attributes.how_out === "Not Out" ? "*" : "");
        } catch (err) {}
      }
    });

    let sortedBowlers = inningData.Bowlers.Bowler.sort((a, b) => {
      if (a._attributes.wickets_taken < b._attributes.wickets_taken) {
        return 1;
      } else if (a._attributes.wickets_taken > b._attributes.wickets_taken) {
        return -1;
      }

      // Else go to the 2nd item
      if (a._attributes.runs_conceded < b._attributes.runs_conceded) {
        return -1;
      } else if (a._attributes.runs_conceded > b._attributes.runs_conceded) {
        return 1;
      } else {
        // nothing to split them
        return 0;
      }
    });

    sortedBowlers = sortedBowlers.slice(0, numOfRows);

    sortedBowlers.map((bowler, index) => {
      index = startRow + 1 + index;
      let name = bowlingTeam.Player.find(
        (p) => p._attributes.id === bowler._attributes.id
      );

      try {
        edits.find((edit) => edit.control.name === index + "_HOW_2").text =
          name._attributes.player_first_name;
      } catch (err) {}
      try {
        edits.find((edit) => edit.control.name === index + "_LAST_2").text =
          name._attributes.player_last_name;
      } catch (err) {}
      try {
        edits.find((edit) => edit.control.name === index + "_RUNS").text =
          bowler._attributes.wickets_taken +
          "-" +
          bowler._attributes.runs_conceded;
      } catch (err) {}
    });
  }

  useEffect(() => {
    if (selectedPage) {
      let dataDrive = [
        {
          name: "OptaCricket",
          innings: innings,
          team: team,
        },
      ];
      setDataDrive(dataDrive);
    } else if (selectedScene) {
      let dataDrive = [
        {
          name: "OptaCricket",
          innings: innings,
          team: team,
        },
      ];
      setDataDrive(dataDrive);
    }
  }, [selectedPage, selectedScene, innings, team]);

  useEffect(() => {
    if (selectedPage && selectedPage.dataDrive) {
      let data = selectedPage.dataDrive.find((d) => d.name === "OptaCricket");
      if (data) {
        setInnings(data.innings);
        setTeam(data.team);
      }
    }
  }, [selectedPage]);

  return (
    <EditArea>
      <Controls>
        <Button
          onClick={() => {
            updateData();
          }}
        >
          UPDATE DATA
        </Button>
        <Column>
          <Button
            selected={innings === 1}
            onClick={() => {
              setInnings(1);
            }}
          >
            1ST
          </Button>
          <Button
            selected={innings === 2}
            onClick={() => {
              setInnings(2);
            }}
          >
            2ND
          </Button>
          <Button
            selected={innings === 3}
            onClick={() => {
              setInnings(3);
            }}
          >
            3RD
          </Button>
          <Button
            selected={innings === 4}
            onClick={() => {
              setInnings(4);
            }}
          >
            4TH
          </Button>
        </Column>
        {/* <Column>
          <Button
            selected={team === 0}
            onClick={() => {
              setTeam(0);
            }}
          >
            BOTH TEAMS
          </Button>
          <Button
            selected={team === 1}
            onClick={() => {
              setTeam(1);
            }}
          >
            HOME TEAM
          </Button>
          <Button
            selected={team === 2}
            onClick={() => {
              setTeam(2);
            }}
          >
            AWAY TEAM
          </Button>
        </Column> */}
      </Controls>
    </EditArea>
  );
}
