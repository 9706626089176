import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "./UserContext";

const RenderContext = React.createContext([{}, () => {}]);

const RenderProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const { loggedIn, user } = useContext(UserContext);
  useEffect(() => {
    if (loggedIn) {
      connect();
    }
  }, [loggedIn]);

  function connect() {
    if (window.render_server) {
      console.log("CG connecting to render server");

      setWS(new WebSocket(window.render_server));
    }
  }

  useEffect(() => {
    if (ws) {
      let timeout;
      let interval;
      ws.onopen = () => {
        setWSStatus(1);
        console.log("Render on open");

        // console.log("connected");
        clearInterval(interval);
        interval = setInterval(() => {
          sendData({
            keep_alive: Date.now(),
            group: window.id,
          });
        }, 20000);
      };
      ws.onmessage = (data) => {
        console.log("Render on message");
      };
      ws.onerror = (err) => {
        console.log("Render on error");
        ws.close();
      };
      ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Render on close");
        clearTimeout(timeout);
        clearInterval(interval);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function render({ filename, template, project, queue, data }) {
    sendData({
      filename,
      template,
      project,
      queue: window.render_queue ?? queue,
      data: updateData(data),
    });
  }
  function sendData(data) {
    if (ws) {
      ws.send(JSON.stringify(data));
    }
  }

  function updateData(data) {
    if (data && data.length > 0) {
      return data.map((item) => {
        if (item.type === "CLOCK") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              action: item.action,
              time: item.time,
            },
          };
        } else if (item.type === "TEXT") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              text: item.text,
              colour: item.colour || item.style?._fill,
              fontSize: item.fontSize || item.style?._fontSize,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        } else if (item.type === "IMAGE") {
          let col = item.colour || hex2string(item.tint);
          if (col?.toString()?.indexOf("#") === -1) {
            col = hex2string(item.colour || item.tint);
          }
          let original_src = item.original_image || item.original_src;
          let src = item.image || item.src;

          let obj = {
            name: item.name,
            value: {
              visible: item.visible,
              image:
                original_src !== src
                  ? "${user}" + (item.image || item.src)
                  : item.original_src,
              position: {
                x: item.x,
                y: item.y,
              },
              colour: col,
              width: item.width,
              height: item.height,
            },
          };
          if (item.neverno_image) {
            obj.value.neverno_image = item.neverno_image;
          }
          return obj;
        } else if (item.type === "GROUP") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        } else if (item.type === "VIDEO") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              video: item.video || item.src,
              position: {
                x: item.x,
                y: item.y,
              },
              // width: item.width,
              // height: item.height,
            },
          };
        } else if (item.type === "RECTANGLE" || item.type === "CIRCLE") {
          let obj = {
            name: item.name,
            value: {
              position: {
                x: item.x,
                y: item.y,
              },
              colour: item.colour || hex2string(item.fillColor),
            },
          };

          return obj;
        }
      });
    }
    return [];
  }

  function hex2string(hex) {
    var hexString = hex.toString(16);
    hexString = "000000".substr(0, 6 - hexString.length) + hexString;
    return "#" + hexString;
  }

  return (
    <RenderContext.Provider
      value={{
        sendData,
        wsStatus,
        render,
      }}
    >
      {props.children}
    </RenderContext.Provider>
  );
};

export { RenderContext, RenderProvider };
