import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { LiveLikeContext } from "../../contexts/LiveLikeContext";
import { ControlContext } from "../../contexts/ControlContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import Fanzone from "../Fanzone/Fanzone";
let prevMessage = "";
let prevData = "";
const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
`;

const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #373b48;
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

const FanzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const LiveLikeLog = styled.div`
  font-size: 0.5em;
`;

window.showHeadshots = false;

export default function LiveLikeEdit({
  selectedEdit,
  selectedScene,
  edits,
  setCurrentTimeline,
  selectedPage,
}) {
  const {
    data,
    selectPoll,
    programs,
    setProgram,
    item,
    options,
    program,
    startTime,
    latestData,
  } = useContext(LiveLikeContext);
  const {
    preview,
    animate,
    triggerAnimation,
    animateOff,
    clearPreview,
    udpdate,
    scenesOnAir,
  } = useContext(ControlContext);
  const { clear, setDataDrive } = useContext(ProjectContext);
  const [animatedState, setAnimatedState] = useState(0);
  const [localEdits, setLocalEdits] = useState([]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        if (animatedState === 0) {
          let animated = false;
          if (window.optaOnAir) {
            animateOff("WatchalongOpta");
            animated = true;
          }
          if (window.twitterOnAir) {
            animateOff("WatchalongTwitter");
            animated = true;
          }
          if (window.WatchalongPromoOnAir) {
            animateOff("WatchalongPromo");
            animated = true;
          }

          if (!animated) {
            animate("LiveLike", []);
            setTimeout(() => {
              setTimeout(() => {
                triggerAnimation("LiveLike", edits, "QUESTION");
                setAnimatedState(1);
                setCurrentTimeline("OPTIONS");
              }, 700);
            }, 2000);
          } else {
            setTimeout(() => {
              animate("LiveLike", []);
              setTimeout(() => {
                setTimeout(() => {
                  triggerAnimation("LiveLike", edits, "QUESTION");
                  setAnimatedState(1);
                  setCurrentTimeline("OPTIONS");
                }, 700);
              }, 2000);
            }, 1000);
          }
        } else if (animatedState === 1) {
          if (window.showHeadshots) {
            edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
            edits.find((edit) => edit.control.name === "IMAGE1_RIGHT").x = 162;
            triggerAnimation("LiveLike", edits, "OPTIONS");
          } else {
            edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
            edits.find((edit) => edit.control.name === "IMAGE1_RIGHT").x = 162;
            triggerAnimation("LiveLike", edits, "OPTIONS_NO_IMAGES");
          }
          setAnimatedState(2);
          setCurrentTimeline("ANSWER");
        } else if (animatedState === 2) {
          setAnimatedState(3);
          if (
            edits.find((edit) => edit.control.name === "WINNER_TXT").text ===
            "DRAW"
          ) {
            triggerAnimation("LiveLike", edits, "DRAW");
          } else {
            if (window.showHeadshots) {
              triggerAnimation("LiveLike", edits, "WINNER");
            } else {
              triggerAnimation("LiveLike", edits, "WINNER_NO_IMAGES");
            }
          }

          clearPreview();
        }
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        animateOff("LiveLike");
        setAnimatedState(-1);
        setCurrentTimeline("QUESTION");
        selectPoll(null);
        clear();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [animatedState, edits, scenesOnAir]);

  useEffect(() => {
    let published = data.find((d) => d.id === item);
    let dataString = JSON.stringify(published);

    if (prevData !== dataString) {
      prevData = dataString;
      if (published) {
        try {
          if (published.question.toUpperCase() === "CHOOSE THE CHANT") {
            edits.find(
              (edit) => edit.control.name === "QUESTION"
            ).visible = false;
            edits.find(
              (edit) => edit.control.name === "CHANT_TEXT"
            ).visible = true;
          } else {
            edits.find(
              (edit) => edit.control.name === "QUESTION"
            ).visible = true;
            edits.find(
              (edit) => edit.control.name === "CHANT_TEXT"
            ).visible = false;
          }

          let duration = parseInt(
            published.timeout.split("M")[1].replace("S", "")
          );
          let mins = published.timeout
            .split("H")[1]
            .split("M")[0]
            .replace("M", "");
          if (mins) {
            duration += 60 * mins;
          }
          edits.find((edit) => edit.control.name === "DURATION").text =
            duration;
          edits.find((edit) => edit.control.name === "TIMER_TOGGLE").text = "0";
          if (animatedState === 0) {
            try {
              edits.find((edit) => edit.control.name === "START_TIME").text =
                "";
            } catch (err) {}
            // edits.find((edit) => edit.control.name === "IMAGE_1").visible = false;
            // edits.find((edit) => edit.control.name === "IMAGE_2").visible = false;
            // edits.find((edit) => edit.control.name === "IMAGE_3").visible = false;
            // edits.find((edit) => edit.control.name === "IMAGE_4").visible = false;
            // debugger;
            // edits.find(
            //   (edit) => edit.control.name === "HOME_TEAM_IMG"
            // ).visible = true;
            // edits.find(
            //   (edit) => edit.control.name === "AWAY_TEAM_IMG"
            // ).visible = true;

            edits.find(
              (edit) => edit.control.name === "OPTION1_GRP"
            ).visible = false;
            edits.find(
              (edit) => edit.control.name === "OPTION2_GRP"
            ).visible = false;
            edits.find(
              (edit) => edit.control.name === "OPTION3_GRP"
            ).visible = false;
            edits.find(
              (edit) => edit.control.name === "OPTION4_GRP"
            ).visible = false;

            edits.find((edit) => edit.control.name === "QUESTION").text =
              published.question;
            edits.find((edit) => edit.control.name === "SMALL_QUESTION").text =
              published.question;
            edits.find((edit) => edit.control.name === "QUESTION_3_LINE").text =
              published.question;
            if (
              published.kind === "text-quiz" ||
              published.kind === "image-quiz"
            ) {
              let correct = published.options.find((o) => o.is_correct);
              if (correct) {
                edits.find((edit) => edit.control.name === "WINNER_TXT").text =
                  correct.description.toUpperCase();

                edits.find(
                  (edit) => edit.control.name === "WINNER_LEFT"
                ).image = correct.image_url;
                edits.find(
                  (edit) => edit.control.name === "WINNER_RIGHT"
                ).image = correct.image_url;
              } else {
                edits.find((edit) => edit.control.name === "WINNER_TXT").text =
                  "";
              }

              edits.find((edit) => edit.control.name === "TITLE").text =
                "ANSWER";
            } else {
              edits.find((edit) => edit.control.name === "WINNER_TXT").text =
                "DRAW";
              edits.find((edit) => edit.control.name === "TITLE").text = "DRAW";
            }
            published.options.map((option, index) => {
              edits.find(
                (edit) => edit.control.name === "OPTION" + (index + 1) + "_GRP"
              ).visible = true;
              edits.find(
                (edit) => edit.control.name === "OPTION" + (index + 1) + "_NAME"
              ).text = option.description;
              edits.find(
                (edit) =>
                  edit.control.name === "OPTION" + (index + 1) + "_VALUE"
              ).text = "0%";
              window.showHeadshots = false;
              if (option.image_url) {
                window.showHeadshots = true;

                edits.find(
                  (edit) => edit.control.name === "IMAGE_" + (index + 1)
                ).width = 193;
                edits.find(
                  (edit) => edit.control.name === "IMAGE_" + (index + 1)
                ).height = 193;
                edits.find(
                  (edit) => edit.control.name === "IMAGE_" + (index + 1)
                ).image = option.image_url;
                edits.find(
                  (edit) => edit.control.name === "IMAGE_" + (index + 1)
                ).visible = true;
                if (published.options.length === 3) {
                  edits.find(
                    (edit) => edit.control.name === "IMAGE_4"
                  ).visible = false;
                }
                if (published.options.length === 2) {
                  edits.find(
                    (edit) => edit.control.name === "IMAGE_3"
                  ).visible = false;
                  edits.find(
                    (edit) => edit.control.name === "IMAGE_4"
                  ).visible = false;
                  edits.find(
                    (edit) => edit.control.name === "IMAGE_" + (index + 1)
                  ).x = 163;
                } else {
                  edits.find(
                    (edit) => edit.control.name === "IMAGE_" + (index + 1)
                  ).x = index === 0 || index === 1 ? 104 : 228;
                  edits.find(
                    (edit) => edit.control.name === "IMAGE_" + (index + 1)
                  ).x = index === 2 || index === 3 ? 228 : 104;
                }
              }
            });
          }
          if (animatedState === 0) {
            let message = JSON.stringify(edits);
            if (prevMessage !== message) {
              preview("LiveLike", 85, edits, "QUESTION");

              prevMessage = message;
            }
          }
        } catch (err) {}
      }
    }
  }, [data, item, edits]);

  useEffect(() => {
    if (startTime) {
      try {
        edits.find((edit) => edit.control.name === "TIMER_TOGGLE").text = "1";
      } catch (err) {}
      if (animatedState === 1) {
        if (window.showHeadshots) {
          edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
          edits.find((edit) => edit.control.name === "IMAGE1_RIGHT").x = 162;
          preview("LiveLike", 84, edits, "OPTIONS");
        } else {
          edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
          edits.find((edit) => edit.control.name === "IMAGE1_RIGHT").x = 162;
          preview("LiveLike", 84, edits, "OPTIONS_NO_IMAGES");
        }
      } else if (animatedState === 2) {
        udpdate("LiveLike", edits);
        edits.find((edit) => edit.control.name === "TIMER_TOGGLE").text = "2";
      }
    }
  }, [startTime, edits, animatedState]);

  useEffect(() => {
    if (animatedState === 0) {
      preview("LiveLike", 85, edits, "QUESTION"); //QUESTION
    } else if (animatedState === 1) {
      edits.find((edit) => edit.control.name === "TIMER_TOGGLE").text = "0";
      if (window.showHeadshots) {
        edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
        edits.find((edit) => edit.control.name === "IMAGE1_RIGHT").x = 162;
        preview("LiveLike", 84, edits, "OPTIONS");
      } else {
        edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
        edits.find((edit) => edit.control.name === "IMAGE1_RIGHT").x = 162;
        preview("LiveLike", 84, edits, "OPTIONS_NO_IMAGES");
      }
    } else if (animatedState === 2) {
      preview("LiveLike", 3, edits, "T12"); //WINNER
    }
  }, [animatedState]);

  useEffect(() => {
    let published = data.find((d) => d.id === item);
    if (published) {
      if (options && options.length > 0) {
        try {
          let total = 0;
          let winner = { description: "draw", value: 0 };
          options.forEach((o) => {
            let value =
              o.vote_count !== undefined ? o.vote_count : o.answer_count;
            total += value;
          });

          options.map((option, index) => {
            if (total > 0) {
              if (
                !winner ||
                winner.value < Math.round((option.vote_count / total) * 100)
              ) {
                let op = published.options[index];
                if (op) {
                  winner = {
                    ...op,
                    value: Math.round((option.vote_count / total) * 100),
                  };
                }
              } else if (
                !winner ||
                winner.value === Math.round((option.vote_count / total) * 100)
              ) {
                winner = { description: "draw", value: 0 };
              }

              edits.find(
                (edit) =>
                  edit.control.name === "OPTION" + (index + 1) + "_VALUE"
              ).text = Math.round((option.vote_count / total) * 100) + "%";
            } else {
              edits.find(
                (edit) =>
                  edit.control.name === "OPTION" + (index + 1) + "_VALUE"
              ).text = "0%";
            }
          });

          if (animatedState === 1) {
            if (window.showHeadshots) {
              edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
              edits.find(
                (edit) => edit.control.name === "IMAGE1_RIGHT"
              ).x = 162;
              preview("LiveLike", 84, edits, "OPTIONS");
            } else {
              edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
              edits.find(
                (edit) => edit.control.name === "IMAGE1_RIGHT"
              ).x = 162;

              preview("LiveLike", 84, edits, "OPTIONS_NO_IMAGES");
            }
          }
          if (animatedState === 2) {
            edits.find((edit) => edit.control.name === "TIMER_TOGGLE").text =
              "2";
            udpdate("LiveLike", edits);

            edits.find((edit) => edit.control.name === "WINNER_TXT").text =
              winner.description.toUpperCase();

            if (winner.description.toUpperCase() === "DRAW") {
              edits.find((edit) => edit.control.name === "TITLE").text = "DRAW";
            } else {
              edits.find((edit) => edit.control.name === "TITLE").text =
                "WINNER";
            }
            if (winner.image_url) {
              edits.find((edit) => edit.control.name === "WINNER_LEFT").image =
                winner.image_url;
              edits.find((edit) => edit.control.name === "WINNER_RIGHT").image =
                winner.image_url;
            }
            if (
              published.kind === "text-quiz" ||
              published.kind === "image-quiz"
            ) {
              edits.find((edit) => edit.control.name === "TITLE").text =
                "ANSWER";
              let correct = published.options.find((o) => o.is_correct);
              if (correct) {
                edits.find((edit) => edit.control.name === "WINNER_TXT").text =
                  correct.description.toUpperCase();

                edits.find(
                  (edit) => edit.control.name === "WINNER_LEFT"
                ).image = correct.image_url;
                edits.find(
                  (edit) => edit.control.name === "WINNER_RIGHT"
                ).image = correct.image_url;
              } else {
                edits.find((edit) => edit.control.name === "WINNER_TXT").text =
                  "";
              }
            }
            // if (winner.description.length > "you'll never walk alone".length) {
            //   edits.find((edit) => edit.control.name === "WINNER_TXT").y = -15;
            // } else {
            //   edits.find((edit) => edit.control.name === "WINNER_TXT").y = 25;
            // }
            preview("LiveLike", 3, edits, "T12");
          }
        } catch (err) {}
      }
    }
  }, [options, edits, animatedState]);

  useEffect(() => {
    if (program) {
      let poll = data.find((d) => d.id === item);
      if (selectedPage) {
        let dataDrive = [
          {
            name: "LiveLike",
            programID: program.id,
            itemID: poll ? poll.question : "",
          },
        ];
        setDataDrive(dataDrive);
      } else if (selectedScene) {
        let dataDrive = [
          {
            name: "LiveLike",
            programID: program.id,
            itemID: poll ? poll.question : "",
          },
        ];
        setDataDrive(dataDrive);
      }
    }
  }, [program, item, data, selectedPage, selectedScene]);

  useEffect(() => {
    prevMessage = "";
    if (selectedPage && selectedPage.dataDrive) {
      selectPoll(null);
      let data = selectedPage.dataDrive.find((d) => d.name === "LiveLike");
      if (data) {
        setProgram(programs.find((p) => p.id === data.programID));
      }
    }
  }, [selectedPage]);
  useEffect(() => {
    if (selectedPage && !item && data && data.length > 0) {
      let d = selectedPage.dataDrive.find((d) => d.name === "LiveLike");
      if (d) {
        let poll = data.find((poll) => poll.question === d.itemID);

        if (poll) {
          selectPoll(poll.id);
        }
      }
    }
  }, [data, item]);

  return (
    <EditArea>
      <Controls>
        <Button
          onClick={() => {
            let animated = false;
            if (window.optaOnAir) {
              animateOff("WatchalongOpta");
              animated = true;
            }
            if (window.TwitterOnAir) {
              animateOff("WatchalongTwitter");
              animated = true;
            }
            if (window.WatchalongPromoOnAir) {
              animateOff("WatchalongPromo");
              animated = true;
            }

            if (!animated) {
              animate("LiveLike", []);
              setTimeout(() => {
                setTimeout(() => {
                  triggerAnimation("LiveLike", edits, "QUESTION");
                  setAnimatedState(1);
                  setCurrentTimeline("OPTIONS");
                }, 700);
              }, 2000);
            } else {
              setTimeout(() => {
                animate("LiveLike", []);
                setTimeout(() => {
                  setTimeout(() => {
                    triggerAnimation("LiveLike", edits, "QUESTION");
                    setAnimatedState(1);
                    setCurrentTimeline("OPTIONS");
                  }, 700);
                }, 2000);
              }, 1000);
            }
          }}
        >
          ANIMATE
        </Button>
        <Button
          onClick={() => {
            if (window.showHeadshots) {
              edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
              edits.find(
                (edit) => edit.control.name === "IMAGE1_RIGHT"
              ).x = 162;
              triggerAnimation("LiveLike", edits, "OPTIONS");
            } else {
              edits.find((edit) => edit.control.name === "IMAGE1_LEFT").x = 159;
              edits.find(
                (edit) => edit.control.name === "IMAGE1_RIGHT"
              ).x = 162;
              triggerAnimation("LiveLike", edits, "OPTIONS_NO_IMAGES");
            }

            setAnimatedState(2);
            setCurrentTimeline("ANSWER");
          }}
        >
          SHOW OPTIONS
        </Button>
        <Button
          onClick={() => {
            setAnimatedState(3);
            if (
              edits.find((edit) => edit.control.name === "WINNER_TXT").text ===
              "DRAW"
            ) {
              triggerAnimation("LiveLike", edits, "DRAW");
            } else {
              if (window.showHeadshots) {
                triggerAnimation("LiveLike", edits, "WINNER");
              } else {
                triggerAnimation("LiveLike", edits, "WINNER_NO_IMAGES");
              }
            }

            setCurrentTimeline("QUESTION");
            clearPreview();
          }}
        >
          SHOW WINNER
        </Button>
        <Button
          onClick={() => {
            animateOff("LiveLike");
            setAnimatedState(0);
            setCurrentTimeline("OUT");
            selectPoll(null);
            clear();
          }}
        >
          ANIMATE OFF
        </Button>
      </Controls>
      <FanzoneContainer>
        <Fanzone />
        {/* {latestData && <LiveLikeLog>{latestData}</LiveLikeLog>} */}
      </FanzoneContainer>
    </EditArea>
  );
}
