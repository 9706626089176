import React, { useContext } from "react";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";
import { ProjectContext } from "../../contexts/ProjectContext";

const Main = styled.div`
  padding-left: 1em;
  padding-top: 1em;
  cursor: pointer;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-size: 0.8em;
  background-color: ${(props) => (props.altColour ? "#1c222b" : "")};
  :hover {
    background-color: #d11e42;
  }
`;

export default function TemplateItem({ scene, altColour }) {
  const { selectScene } = useContext(ProjectContext);

  function previewTemplate() {
    selectScene(scene);
  }
  return (
    <Main
      altColour={altColour}
      onDoubleClick={() => {
        previewTemplate();
      }}
    >
      {scene.name}
    </Main>
  );
}
