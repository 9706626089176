import React, { useContext, useState, useEffect, useRef } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";
import Button from "./Button";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";
import { RenderContext } from "../../contexts/RenderContext";
import { UserContext } from "../../contexts/UserContext";

const Main = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #020304;
  padding-bottom: 0.5em;
  height: 45px;
`;
const Column = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.render ? "100%" : "50%")};
`;

const AnimateButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
  border-radius: 4px;
  background-color: ${(props) => (props.enabled ? "#30c17e" : "#191f2b")};
  color: ${(props) => (props.enabled ? "black" : "#616161")};
  cursor: pointer;
`;

const PageNumber = styled.input`
  background-color: #191f2b;
  color: #fff;
  border-radius: 4px;
  border: none;
  padding-left: 1em;
  margin-left: 0.5em;
  font-size: 1.2em;
  width: 87px;
`;
export default function Control() {
  const {
    scenes,
    pages,
    selectedScene,
    selectScene,
    selectedPage,
    selectedShow,
    edits,
    pageNumber,
    setPageNumber,
    project,
  } = useContext(ProjectContext);
  const { animate, scenesOnAir } = useContext(ControlContext);
  const { render } = useContext(RenderContext);
  const { user } = useContext(UserContext);
  function handleKeyDown(e) {
    if (e.keyCode >= 48 && e.keyCode <= 57) {
    } else if (e.keyCode === 13) {
    } else if (e.keyCode === 110) {
    }
  }

  function renderStill() {
    var iframe = window.document.getElementById("PreviewEngine").contentWindow;

    iframe.postMessage(
      JSON.stringify({
        event: "snapshot",
        data: {
          name: "#" + selectedPage.number + " - " + selectedPage?.name,
        },
      }),
      window.engine_url
    );
  }

  function renderMovie() {
    render({
      data: edits,
      filename:
        selectedShow?.name +
        " - " +
        selectedPage?.number +
        " - " +
        selectedPage?.name +
        ".mov",
      template: selectedScene?.name,
      project: project?.name,
      queue: user?._id,
    });
  }
  return (
    <Main>
      <Column render={window.render}>
        {/* <Column style={{ justifyContent: "flex-start" }}>
          <PageNumber value={pageNumber} onKeyDown={handleKeyDown} />
        </Column> */}

        {window.render && selectedPage && selectedScene && (
          <AnimateButton
            enabled={selectedScene !== null}
            onClick={() => {
              renderStill();
            }}
          >
            Render Image
          </AnimateButton>
        )}
        {user?.features?.find((f) => f.name === "video_render") &&
          window.render &&
          selectedPage &&
          selectedScene && (
            <AnimateButton
              style={{ marginLeft: "10px" }}
              enabled={selectedScene !== null}
              onClick={() => {
                renderMovie();
              }}
            >
              Render Movie
            </AnimateButton>
          )}

        {!window.render && selectedScene && (
          <AnimateButton
            enabled={selectedScene !== null}
            onClick={() => {
              if (selectedScene) {
                animate(
                  selectedScene.name,
                  edits,
                  selectedScene.data.transition_layer,
                  selectedPage?.engine
                );
                selectScene(null);
              }
            }}
          >
            Animate
          </AnimateButton>
        )}

        {/* <Column></Column> */}
      </Column>
      {!window.render && (
        <Column style={{ justifyContent: "flex-start", marginLeft: "1em" }}>
          {scenesOnAir.map((scene, index) => {
            return <Button scene={scene} key={index} />;
          })}
        </Column>
      )}
    </Main>
  );
}
