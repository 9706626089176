import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { IoMdUnlock, IoMdLock } from "react-icons/io";
import { Button } from "../../Theme/Hyper";
import { useContext } from "react/cjs/react.development";
import { ControlContext } from "../../contexts/ControlContext";

const EditArea = styled.div`
  background-color: #191f27;
  display: flex;
  padding: 5px;
`;

const Label = styled.div`
  background-color: #191f27;
  margin: 0.5em;
  min-width: 100px;
`;
const Property = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
`;
const TextInput = styled.input`
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
`;
const ImageTransform = styled.div`
  display: flex;
  align-items: center;
`;
const Properties = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LockIcon = styled(IoMdLock)`
  padding: 0.5em;
  font-size: 2em;
  color: ${(props) => props.theme.highlightColour};
  cursor: pointer;
`;
const UnLockIcon = styled(IoMdUnlock)`
  padding: 0.5em;
  font-size: 2em;
  cursor: pointer;
`;
export default function TransitionEdit({
  selectedPage,
  selectedScene,
  edits,
  triggerAnimation,
}) {
  return (
    <EditArea>
      {selectedScene?.data?.timelines.map((tl) => {
        return (
          <Property>
            <Button
              onClick={() => {
                triggerAnimation(
                  selectedScene.name,
                  edits,
                  tl.name,
                  selectedPage?.engine
                );
              }}
            >
              {tl.name}
            </Button>
          </Property>
        );
      })}
    </EditArea>
  );
}
