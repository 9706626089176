import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ProjectContext } from "../../contexts/ProjectContext";
import { FiImage } from "react-icons/fi";
import { MdFontDownload, MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdCheckboxOutline } from "react-icons/io";
import { ControlContext } from "../../contexts/ControlContext";
import { FaFolder } from "react-icons/fa";
import { ScrollBar } from "../../Theme/Hyper";
import { HiClock } from "react-icons/hi";
const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  background-color: #191f27;
  margin: 0.5em;
  overflow-y: scroll;
  min-height: 440px;
  height: 440px;
`;

const EditItem = styled.div`
  padding-left: 1em;

  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #1f212b;
  border-top: 1px solid #1f212b;
  display: flex;

  background-color: ${(props) => (props.selected ? "#d20a3e" : "")};
`;
const Icon = styled.div`
  display: flex;
  min-width: 20px;
  color: #5e616f;
  margin-right: 0.5em;
  justify-content: center;
`;
const Name = styled.div`
  display: flex;
`;
const ID = styled.div`
  color: #5e616f;
  min-width: 35px;
  margin-right: 0.5em;
`;

const ImageIcon = styled(FiImage)`
  color: ${(props) => props.theme.imageItemColour};
`;

const TextIcon = styled(MdFontDownload)`
  color: ${(props) => props.theme.textItemColour};
`;

const GroupIcon = styled(FaFolder)`
  color: ${(props) => props.theme.groupItemColour};
`;

const ClockIcon = styled(HiClock)`
  color: ${(props) => props.theme.clockItemColour};
`;

const Visible = styled.div`
  display: flex;
  margin-right: 0.5em;
  font-size: 1.2em;
  margin-left: auto;
`;

export default function EditList() {
  const { edits, setSelectedEdit, selectedEdit, selectedScene, selectedPage } =
    useContext(ProjectContext);
  const { preview } = useContext(ControlContext);
  const [update, setUpdate] = useState(false);

  function previewGraphic() {
    let tl = selectedScene.data.timelines.find((tl) => tl.name === "IN");
    preview(
      selectedScene.name,
      tl.duration - 1,
      edits,
      null,
      selectedPage?.engine
    );
  }

  return (
    <Main>
      {edits
        .filter((item) => item.control.name !== "")
        .sort((a, b) => {
          return parseInt(a.control.id) - parseInt(b.control.id);
        })
        .map((item) => {
          return (
            <EditItem
              onClick={() => {
                setSelectedEdit(item);
              }}
              selected={selectedEdit === item}
            >
              <ID>{item.control.id}</ID>
              <Icon>
                {item.type === "CLOCK" && <ClockIcon />}
                {item.type === "GROUP" && <GroupIcon />}
                {item.type === "IMAGE" && <ImageIcon />}
                {item.type === "VIDEO" && <ImageIcon />}
                {item.type === "TEXT" && <TextIcon />}
                {item.type === "RECTANGLE" && (
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#5e616f",
                    }}
                  ></div>
                )}
              </Icon>

              <Name>{item.control.name}</Name>
              <Visible
                onClick={(e) => {
                  item.visible = !item.visible;
                  previewGraphic();
                  setUpdate(!update);
                  e.stopPropagation();
                }}
              >
                {item.visible === true && <IoMdCheckboxOutline />}
                {item.visible === false && <MdCheckBoxOutlineBlank />}
              </Visible>
            </EditItem>
          );
        })}
    </Main>
  );
}
