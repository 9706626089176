import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import styled from "styled-components";
import { NeverNoContext } from "../../contexts/NeverNoContext";
import { ControlContext } from "../../contexts/ControlContext";
import { FiChevronsRight } from "react-icons/fi";
import { GiPauseButton } from "react-icons/gi";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ScrollBar } from "../../Theme/Hyper";

const EditArea = styled.div`
  background-color: #191f27;
  display: flex;
`;

const Row = styled.div`
  padding-left: 1em;
  cursor: pointer;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.altColour ? "#1c222b" : "")};
  overflow: hidden;
  white-space: nowrap;
  min-height: 25px;
`;

const RowContent = styled.div`
  width: 100%;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  :hover {
    background-color: #d11e42;
  }
`;

const Selected = styled(FiChevronsRight)`
  color: #30c17e;
  font-size: 1.5em;
`;

const Paused = styled(GiPauseButton)`
  color: ${(props) => (props.selected ? "#d20a3e" : "#373b48")};
  font-size: 1.5em;
  cursor: pointer;
  :hover {
    color: #d20a3e;
  }
`;

const Arrow = styled.div`
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Circle = styled.div`
  min-width: 10px;
  min-height: 10px;
  display: flex;
  background-color: #d20a3e;
  border-radius: 50%;
`;

const Username = styled.div`
  min-width: 135px;
  display: flex;
  align-items: center;
  color: #ffeb00;
`;
const TimerOptions = styled.div`
  display: flex;
  padding: 0.5em;
`;

const Option = styled.div`
  display: flex;
  background-color: ${(props) => (props.selected ? "#d20a3e" : "#0e1219")};
  border-radius: 4px;
  cursor: pointer;
  width: 80px;
  padding: 0.5em;
  justify-content: center;
  margin-right: 0.5em;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #373b48;
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;
const NeverNo = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const TwitterContent = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  width: 100%;
`;
const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DataItems = styled(ScrollBar)`
  display: flex;
  max-height: 320px;
  overflow-y: auto;
  flex-direction: column;
`;
let playingIndex = -1;
let onAirIndex = -1;
let pauseIndex = 10000;
let playingDuration = 5;
let timeout;
export default function NeverNoEditLT({ selectedEdit, selectedScene }) {
  const { preview, animate, animateOff } = useContext(ControlContext);
  const { edits, clear } = useContext(ProjectContext);
  const [state, setState] = useState();
  const [neverNoDestination, setNeverNoDestination] = useState();
  const [destinations, setDestinations] = useState();
  const [duration, setDuration] = useState(5);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [paused, setPaused] = useState();
  const [update, setUpdate] = useState();

  const [loop, setLoop] = useState(false);

  useEffect(() => {
    if (selectedScene) {
      playingIndex = -1;
      onAirIndex = -1;
      pauseIndex = 10000;
      playingDuration = 5;
      if (selectedScene.data?.neverno?.server) {
        fetch(
          (window.ENV?.REACT_APP_PROJECT_SERVER ||
            process.env.REACT_APP_PROJECT_SERVER) + "/api/neverno",
          {
            headers: {
              neverno: selectedScene.data?.neverno?.server,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setDestinations(
              data.exports
                ?.filter((item) => item.story)
                ?.map((item) => ({
                  url: item.link?.url,
                  title: item.story?.title,
                  format: item.story?.format?.title,
                }))
            );
          });
      }
    }
  }, [selectedScene]);

  useEffect(() => {
    getData();
    let interval;
    if (neverNoDestination) {
      interval = setInterval(() => {
        getData();
      }, 10000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [neverNoDestination, selectedItem]);

  function getData() {
    if (neverNoDestination) {
      fetch(
        (window.ENV?.REACT_APP_PROJECT_SERVER ||
          process.env.REACT_APP_PROJECT_SERVER) + "/api/neverno",
        {
          headers: {
            neverno: neverNoDestination,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          let newData = data.exports?.[0]?.items?.map((item, index) => ({
            index: index,
            id: item.id,
            author: item.author,
            message: item.content?.message?.original,
            hashtags: item.content?.hashtags,
            platform: item.source?.platform?.type,
            fullName: item.author?.fullName,
            followers: item.author?.engagement?.followers,
            attachment_1: item.content?.attachments?.[0] || "",
          }));
          setData(newData);

          if (!selectedItem) {
            playingIndex = 0;
            updateContent(newData?.[0]);
            setSelectedItem(newData?.[0]);
          }
        });
    }
  }

  function updateContent(content) {
    let obj = edits.find(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_TWITTER" && v.field === "Username"
        ) > -1
    );
    if (obj) {
      obj.text = content?.author?.username || content?.author?.fullName || "";
    }

    obj = edits.find(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_TWITTER" && v.field === "Message"
        ) > -1
    );
    if (obj) {
      obj.text = content?.message || "";
    }
    obj = edits.find(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_TWITTER" && v.field === "Hashtags"
        ) > -1
    );
    if (obj) {
      obj.text = content?.hashtags?.map((h) => "#" + h).join(" ") || "";
    }

    obj = edits.find(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_TWITTER" && v.field === "Platform"
        ) > -1
    );
    if (obj) {
      obj.text = content?.platform || "";
    }

    obj = edits.find(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_TWITTER" && v.field === "Full Name"
        ) > -1
    );
    if (obj) {
      obj.text = content?.fullName || "";
    }

    obj = edits.find(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_TWITTER" && v.field === "Followers"
        ) > -1
    );
    if (obj) {
      obj.text = content?.followers || "";
    }

    obj = edits.find(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_TWITTER" && v.field === "Attachment_1"
        ) > -1
    );
    if (obj) {
      let extension = [".jpg", ".jpeg", ".png"];
      let hasExtension = false;
      extension.forEach((ext) => {
        if (content?.attachment_1?.url?.indexOf(ext) > -1) {
          hasExtension = true;
        }
      });
      if (!hasExtension && content.attachment_1.url) {
        content.attachment_1.url = content.attachment_1.url + "_noext.jpg";
      }

      obj.neverno_image = content?.attachment_1?.url || "";
      obj.width = content?.attachment_1?.width;
      obj.height = content?.attachment_1?.height;
    }

    let obj_index = edits.findIndex(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_TWITTER" && v.field === "Profile Image"
        ) > -1
    );

    if (obj_index > -1) {
      edits[obj_index] = {
        ...edits[obj_index],
        neverno_image:
          content?.author?.attachments?.find(
            (a) => a.context === "profile image"
          )?.url || "empty",
      };
    }

    preview(selectedScene.name, 100, edits);
  }

  useEffect(() => {
    if (loop) {
      runLoop();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [loop]);

  function runLoop() {
    if (pauseIndex <= playingIndex) {
      if (pauseIndex === playingIndex) {
        onAirIndex = playingIndex;
        animate(selectedScene.name, edits, selectedScene.data.transition_layer);
        playingIndex++;
        setSelectedItem(data[playingIndex]);
        updateContent(data[playingIndex]);
      }
      timeout = setTimeout(() => {
        runLoop();
      }, playingDuration * 1000);
    } else {
      animate(selectedScene.name, edits, selectedScene.data.transition_layer);
      onAirIndex = playingIndex;
      playingIndex += 1;
      if (data.length <= playingIndex) {
        playingIndex = 0;
      }

      setSelectedItem(data[playingIndex]);
      updateContent(data[playingIndex]);

      timeout = setTimeout(() => {
        runLoop();
      }, playingDuration * 1000);
    }
  }

  return (
    <EditArea>
      <Controls>
        <Button
          onClick={() => {
            if (selectedItem) {
              setLoop(false);
              animate(
                selectedScene.name,
                edits,
                selectedScene.data.transition_layer
              );
              playingIndex = selectedItem?.index;
              onAirIndex = playingIndex;
            }
          }}
        >
          ANIMATE SINGLE
        </Button>
        <Button
          onClick={() => {
            if (selectedItem) {
              playingIndex = selectedItem?.index;
              setLoop(true);
            }
          }}
        >
          ANIMATE LOOP
        </Button>
        <Button
          onClick={() => {
            animateOff(selectedScene.name, []);
            clear();
          }}
        >
          ANIMATE OFF
        </Button>
      </Controls>
      <NeverNo>
        <Dropown
          onChange={(e) => {
            setNeverNoDestination(e.target.value);
          }}
        >
          <DropownOption value="" selected disabled>
            Choose Destination
          </DropownOption>
          {destinations?.map((destination) => {
            return (
              <DropownOption
                value={destination.url}
                selected={neverNoDestination === destination.url}
              >
                {destination.format} - {destination.title}
              </DropownOption>
            );
          })}
        </Dropown>

        <TimerOptions>
          <Option
            selected={duration === 5}
            onClick={() => {
              setDuration(5);
              playingDuration = 5;
            }}
          >
            5s
          </Option>
          <Option
            selected={duration === 7}
            onClick={() => {
              setDuration(7);
              playingDuration = 7;
            }}
          >
            7s
          </Option>
          <Option
            selected={duration === 10}
            onClick={() => {
              setDuration(10);
              playingDuration = 10;
            }}
          >
            10s
          </Option>
          <Option
            selected={duration === 12}
            onClick={() => {
              setDuration(12);
              playingDuration = 12;
            }}
          >
            12s
          </Option>
          <Option
            selected={duration === 15}
            onClick={() => {
              setDuration(15);
              playingDuration = 15;
            }}
          >
            15s
          </Option>
          <Option
            selected={duration === 20}
            onClick={() => {
              setDuration(20);
              playingDuration = 20;
            }}
          >
            20s
          </Option>
        </TimerOptions>
        <DataItems>
          {data?.map((d, index) => {
            return (
              <Row altColour={index % 2 === 0} key={index}>
                <div
                  onClick={() => {
                    if (pauseIndex === d.index) {
                      pauseIndex = 10000;
                      setPaused(10000);
                    } else {
                      pauseIndex = d.index;
                      setPaused(d.index);
                    }
                  }}
                >
                  <Paused selected={pauseIndex === d.index} />
                </div>

                <Arrow>
                  {index === onAirIndex && <Selected>></Selected>}
                  {index === playingIndex && <Circle />}
                </Arrow>
                <RowContent>
                  <TwitterContent
                    onClick={() => {
                      playingIndex = d.index;
                      setSelectedItem(d);
                      updateContent(d);
                    }}
                  >
                    <Username>
                      {d.author?.username || d.author?.fullName}
                    </Username>
                    <div>{d.message}</div>
                  </TwitterContent>
                </RowContent>
              </Row>
            );
          })}
        </DataItems>
      </NeverNo>
    </EditArea>
  );
}
