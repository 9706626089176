import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ProjectContext } from "../../contexts/ProjectContext";
import Popup from "../Popup/Popup";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { ControlContext } from "../../contexts/ControlContext";
const Main = styled.div`
  display: flex;
  justify-content: center;
`;

const Player = styled.div`
  /* padding-left: 17em;
  padding-top: 4em; */
  position: relative;
  z-index: 0;
`;

const Engine = styled.iframe`
  box-sizing: border-box;
  width: ${(props) => props.width + "px"};
  height: ${(props) => props.height + "px"};
  background-color: #191f2b;
  z-index: -1;
  /* border-radius: 8px; */
  /* box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12); */
  border: none;
  overflow: hidden;
  margin: 5px;
  pointer-events: none;
  border: ${({ connected }) => (connected === true ? "2px solid #30c17e" : "")};
  border-radius: 4px;
`;
const TextInput = styled.input`
  margin: 0.5em;
  background-color: #06080a;
  border: none;
  border-radius: 3px;
  color: white;
  font-family: Helvetica;
  padding: 0.5em;
  width: 70%;
`;
export default function Stage({ group, view, project }) {
  const { engines } = useContext(ProjectContext);
  const { engineMap } = useContext(ControlContext);
  const [toggle, setToggle] = useState(false);
  const [selectedEngine, setSelectedEngine] = useState();
  const [showEngineLinks, setShowEngineLinks] = useState(false);
  let width = 1920 / 2.9;
  let height = 1080 / 2.9;
  let previewW = 1920 / 2.9;
  let previewH = 1080 / 2.9;
  if (view !== "FULL") {
    width = 1920 / 1.5;
    height = 1080 / 1.5;
  }

  if (window.render) {
    previewW = 1920 / 1.5;
    previewH = 1080 / 1.5;
  }

  return (
    <Main>
      <Player
      // onClick={() => {
      //   window.open(
      //     `http://${window.location.hostname}:3001?engine=true&project=SKY&preview=true&groupID=${window.id}`
      //   );
      // }}
      >
        <Engine
          width={previewW}
          height={previewH}
          id="PreviewEngine"
          name="preview"
          title="Preview"
          src={`${window.engine_url}/preview/${
            localStorage.getItem("user-id") + window.random
          }/${project?._id}?width=${previewW}&height=${previewH}&showFPS=${
            window.showFPS
          }&command_server=${window.command_server}&useRAF=${
            window.useRAF
          }&4k=${window.uhd === "true" ? true : false}`}
          // style={{
          //   backgroundImage:
          //     window.projectName !== "WSM"
          //       ? "url(http://grabyo.hyperstudios.co.uk:6060/assets/images/GRABYO/Background.png"
          //       : "url(http://grabyo.hyperstudios.co.uk:6060/assets/images/WSM/PalmTrees.jpg"
          // }}
        ></Engine>
      </Player>

      {view === "FULL" && !window.render && (
        <Player onClick={() => setToggle(false)}>
          <Engine
            connected={engines?.[0]?.status === "connected"}
            width={width}
            height={height}
            id="RenderEngine"
            title="Preview"
            src={`${window.engine_url}/live/${
              localStorage.getItem("user-id") + window.random
            }/${project?._id}?width=${previewW}&height=${previewH}&showFPS=${
              window.showFPS
            }&command_server=${window.command_server}&useRAF=${
              window.useRAF
            }&4k=${window.uhd === "true" ? true : false}`}
            // style={{
            //   backgroundImage:
            //     window.projectName !== "WSM"
            //       ? "url(http://grabyo.hyperstudios.co.uk:6060/assets/images/GRABYO/Background.png"
            //       : "url(http://grabyo.hyperstudios.co.uk:6060/assets/images/WSM/PalmTrees.jpg"
            // }}
          ></Engine>
          <EnginesContainer>
            {engineMap
              .filter((e) => {
                return Number.isInteger(parseInt(e.mapped_to));
              })
              .map((engine) => {
                return (
                  <EngineConnection
                    engine={engine?.engine}
                    toggle={toggle}
                    onToggle={setToggle}
                    onShowURL={(engine) => {
                      setSelectedEngine(engine);
                      setShowEngineLinks(true);
                    }}
                  />
                );
              })}
          </EnginesContainer>
        </Player>
      )}
      {showEngineLinks && (
        <Popup
          min_width="500px"
          showConfirmButton={false}
          title={"Engine URL"}
          cancelButton="Close"
          onCancel={() => {
            setSelectedEngine();
            setShowEngineLinks(false);
          }}
        >
          <URLRow>
            <Column style={{ width: "50px" }}>Preview </Column>

            <TextInput
              disabled={true}
              value={`${
                window.ENV?.REACT_APP_ENGINE_SERVER ||
                process.env.REACT_APP_ENGINE_SERVER
              }/preview/${selectedEngine?.key}/${project._id}`}
              id="engine-url-preview"
            />
            <Icon>
              <HiOutlineClipboardCopy
                style={{ fontSize: "20px" }}
                onClick={() => {
                  /* Get the text field */
                  var copyText = document.getElementById("engine-url-preview");

                  /* Select the text field */
                  copyText.select();
                  copyText.setSelectionRange(0, 99999); /* For mobile devices */

                  /* Copy the text inside the text field */
                  navigator.clipboard.writeText(copyText.value);
                  let response = document.getElementById("copy-preview");
                  response.innerText = "Copied";
                  setTimeout(() => {
                    response.innerText = "";
                  }, 2000);
                }}
              />
            </Icon>
            <span id="copy-preview"></span>
          </URLRow>
          <URLRow>
            <Column style={{ width: "50px" }}>Live </Column>

            <TextInput
              disabled={true}
              value={`${
                window.ENV?.REACT_APP_ENGINE_SERVER ||
                process.env.REACT_APP_ENGINE_SERVER
              }/live/${selectedEngine?.key}/${project._id}`}
              id="engine-url-live"
            />
            <Icon>
              <HiOutlineClipboardCopy
                style={{ fontSize: "20px" }}
                onClick={() => {
                  /* Get the text field */
                  var copyText = document.getElementById("engine-url-live");

                  /* Select the text field */
                  copyText.select();
                  copyText.setSelectionRange(0, 99999); /* For mobile devices */

                  /* Copy the text inside the text field */
                  navigator.clipboard.writeText(copyText.value);
                  let response = document.getElementById("copy-live");
                  response.innerText = "Copied";
                  setTimeout(() => {
                    response.innerText = "";
                  }, 2000);
                }}
              />
            </Icon>
            <span id="copy-live"></span>
          </URLRow>
        </Popup>
      )}
    </Main>
  );
}

const Icon = styled.div`
  cursor: pointer;
  :hover {
    color: red;
  }
`;
const URLRow = styled.div`
  font-size: 10px;
  padding: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid black;
  width: 100%;
  align-items: center;
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const EnginesContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1000;
  display: flex;
`;

const Container = styled.div`
  margin-left: 5px;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #52637b;
  border-radius: 4px;
  margin-top: 2px;
  width: 100px;
  overflow: hidden;
`;

const Row = styled.div`
  font-size: 10px;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: ${({ textAlign }) => textAlign};
  border-bottom: 1px solid black;
  width: 100%;
  :hover {
    background-color: #30c17e;
  }
`;

const Connection = styled.div`
  display: flex;
  border-radius: 3px;
  font-size: 10px;
  padding: 3px;
  background-color: ${({ connected }) => (connected ? "#30c17e" : "red")};
  color: ${({ connected }) => (connected ? "#000" : "#fff")};
  opacity: 0.7;
  z-index: 1000;
  :hover {
    opacity: 1;
  }
`;

function EngineConnection({ engine, onToggle, toggle, onShowURL }) {
  const { updateEngine } = useContext(ProjectContext);
  const { reload, clear, engineMap } = useContext(ControlContext);

  return (
    <>
      <Container>
        <Connection
          connected={engine?.status === "connected"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onToggle(engine);
          }}
        >
          {engine?.name}
        </Connection>
        {toggle._id === engine?._id && (
          <Overlay>
            {/* <Row
              textAlign={"right"}
              onClick={() => {
                setEngineMap({});
                updateEngine({ ...engine, status: "connected" });
              }}
            >
              Connect
            </Row>
            <Row
              textAlign={"right"}
              onClick={() => {
                updateEngine({ ...engine, status: "disconnected" });
              }}
            >
              Disconnect
            </Row> */}
            <Row
              textAlign={"right"}
              onClick={() => {
                reload(engineMap.find((e) => e._id === engine?._id)?.mapped_to);
              }}
            >
              Reload project
            </Row>
            <Row
              textAlign={"right"}
              onClick={() => {
                clear(engineMap.find((e) => e._id === engine?._id)?.mapped_to);
              }}
            >
              Clear graphics
            </Row>
            <Row textAlign={"right"} onClick={() => onShowURL(engine)}>
              Get URL
            </Row>
          </Overlay>
        )}
      </Container>
    </>
  );
}
