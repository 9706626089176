import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import styled from "styled-components";
import { NeverNoContext } from "../../contexts/NeverNoContext";
import { ControlContext } from "../../contexts/ControlContext";
import { FiChevronsRight } from "react-icons/fi";
import { GiPauseButton } from "react-icons/gi";
import { ProjectContext } from "../../contexts/ProjectContext";

const EditArea = styled.div`
  background-color: #191f27;
  display: flex;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #373b48;
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;
const NeverNo = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const Poll = styled.div`
  display: flex;
  flex-direction: column;
`;
const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Option = styled.div`
  display: flex;
  width: 44%;
  box-sizing: border-box;
  font-size: 12px;
  background-color: #0e1219;
  margin: 4px;
  padding: 4px;
  border-radius: 4px;
`;
const Question = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  margin: 4px;
  padding: 4px;
`;
const Percentage = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 10px;
`;
const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

window.twitterDuration = 5;
window.twitterSeeking = false;
export default function NeverNoEditPoll({ selectedScene }) {
  const [data, setData] = useState([]);
  const { preview, animate, animateOff, scenesOnAir } =
    useContext(ControlContext);
  const { edits, clear, selectedPage, setDataDrive } =
    useContext(ProjectContext);
  const [destinations, setDestinations] = useState();
  const [neverNoID, setNeverNoID] = useState();
  const [neverNoDestination, setNeverNoDestination] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [state, setState] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      getData();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (neverNoDestination) {
      getData();
    }
  }, [neverNoDestination]);

  useEffect(() => {
    if (selectedScene) {
      if (selectedScene.data?.neverno?.server) {
        fetch(
          (window.ENV?.REACT_APP_PROJECT_SERVER ||
            process.env.REACT_APP_PROJECT_SERVER) + "/api/neverno",
          {
            headers: {
              neverno: selectedScene.data?.neverno?.server,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setDestinations(
              data.exports
                ?.filter((item) => item.story)
                ?.map((item) => ({
                  url: item.link?.url,
                  title: item.story?.title,
                  format: item.story?.format?.title,
                }))
            );
          });
      }
    }
  }, [selectedScene]);

  function getData() {
    if (neverNoDestination) {
      fetch(
        (window.ENV?.REACT_APP_PROJECT_SERVER ||
          process.env.REACT_APP_PROJECT_SERVER) + "/api/neverno",
        {
          headers: {
            neverno: neverNoDestination,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          let newData =
            data?.exports?.[0]?.sessions?.[0]?.snapshots?.[0]?.items?.items?.map(
              (item, index) => {
                return {
                  question:
                    data?.exports?.[0]?.sessions?.[0]?.snapshots?.[0]?.topic
                      ?.title,
                  index: index,
                  id: item.id,
                  title: item.title,
                  percent: item.percent,
                };
              }
            );

          setData(newData);

          updateContent(newData);
        });
    }
  }

  function updateContent(content) {
    let obj = edits.find(
      (edit) =>
        edit.control?.data?.variables?.findIndex(
          (v) => v.template === "NEVERNO_POLL" && v.field === "Question"
        ) > -1
    );

    if (obj) {
      obj.text = content?.[0]?.question || "";
    }

    new Array(8).fill(1).forEach((_, index) => {
      let item = content?.[index];
      obj = edits.find(
        (edit) =>
          edit.control?.data?.variables?.findIndex(
            (v) =>
              v.template === "NEVERNO_POLL" &&
              v.field === "Answer_" + (index + 1)
          ) > -1
      );
      if (obj) {
        if (item) {
          obj.text = item?.title || "";
        } else {
          obj.text = "";
        }
      }
      obj = edits.find(
        (edit) =>
          edit.control?.data?.variables?.findIndex(
            (v) =>
              v.template === "NEVERNO_POLL" &&
              v.field === "Answer_Percent_" + (index + 1)
          ) > -1
      );
      if (obj) {
        if (item) {
          obj.text = item?.percent || "0";
        } else {
          obj.text = "";
        }
      }
    });

    preview(selectedScene.name, 100, edits);
  }

  return (
    <EditArea>
      <Controls>
        <Button
          onClick={() => {
            animate(
              selectedScene.name,
              edits,
              selectedScene.data.transition_laye
            );
            setState(1);
          }}
        >
          ANIMATE
        </Button>
        <Button
          onClick={() => {
            animateOff(selectedScene.name, []);
            clear();
            setState(0);
          }}
        >
          ANIMATE OFF
        </Button>
      </Controls>
      <NeverNo>
        <Dropown
          onChange={(e) => {
            setNeverNoDestination(e.target.value);
          }}
        >
          <DropownOption value="" selected disabled>
            Choose Destination
          </DropownOption>
          {destinations?.map((destination) => {
            return (
              <DropownOption
                value={destination.url}
                selected={neverNoDestination === destination.url}
              >
                {destination.format} - {destination.title}
              </DropownOption>
            );
          })}
        </Dropown>

        <Poll altColour={0 % 2 === 0} key={0} style={{ fontSize: "1.2em" }}>
          <Question>{data?.[0]?.question}</Question>
          <Options>
            {data?.map((item, index) => {
              return (
                <Option>
                  {item.title}
                  <Percentage>{item.percent}%</Percentage>
                </Option>
              );
            })}
          </Options>
        </Poll>
      </NeverNo>
    </EditArea>
  );
}
