import React, { useContext, useEffect } from "react";
import { LiveLikeContext } from "../../contexts/LiveLikeContext";
import styled from "styled-components";
import { ControlContext } from "../../contexts/ControlContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { FiChevronsRight } from "react-icons/fi";
import { ScrollBar } from "../../Theme/Hyper";

const Main = styled.div``;

const Header = styled.div`
  padding: 0.5em;
`;
const RowHeader = styled.div`
  display: flex;
  font-weight: 100;
  background-color: #252e40;
  justify-content: center;
  padding: 0.5em;
  border-left: 1px solid black;
  font-size: 0.8em;
`;

const Row = styled.div`
  padding-left: 1em;
  padding-top: 1em;
  cursor: pointer;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.altColour ? "#1c222b" : "")};
  :hover {
    background-color: #d11e42;
  }
`;

const OptionRow = styled.div`
  padding-left: 1em;
  padding-top: 1em;
  cursor: pointer;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color:  #0e1219};

  > div {
    width: 50%;
  }
`;

const EmptyRow = styled.div`
  padding-left: 1em;
  padding-top: 1em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.altColour ? "#1c222b" : "")};
`;

const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const Selected = styled(FiChevronsRight)`
  color: #30c17e;
  font-size: 1.5em;
`;
const LiveLikeStatus = styled.div`
  background-color: #30c17e;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 1em;
`;

const QueueContainer = styled(ScrollBar)`
  height: 325px;
  overflow-y: scroll;
`;

export default function Fanzone() {
  const {
    data,
    selectPoll,
    programs,
    setProgram,
    item,
    subscribedTo,
  } = useContext(LiveLikeContext);

  return (
    <Main>
      <Header>
        <Dropown
          onChange={(e) => {
            setProgram(programs.find((p) => p.id === e.currentTarget.value));
          }}
        >
          <option disabled selected value>
            -- select event --
          </option>
          {programs.map((item) => {
            return (
              <DropownOption
                value={item.id}
                selected={window.program && item.id === window.program.id}
              >
                {item.title}
              </DropownOption>
            );
          })}
        </Dropown>
      </Header>
      <div>
        <RowHeader>Published</RowHeader>
        {data
          .filter((a) => a.status === "published")
          .filter((i) => {
            let diff =
              Date.now() - Date.parse(i.published_at_hyper || i.published_at);
            return diff < 120000;
          })
          .map((poll, index) => {
            return (
              <Row
                altColour={index % 2 === 0}
                key={index}
                onClick={() => {
                  selectPoll(poll.id);
                  // previewGraphic(poll);
                }}
              >
                {item && poll.id === item && <Selected>></Selected>}{" "}
                {poll.question}{" "}
                {subscribedTo.indexOf(poll.subscribe_channel) > -1 && (
                  <LiveLikeStatus></LiveLikeStatus>
                )}
              </Row>
            );
          })}
        {data
          .filter((a) => a.status === "published")
          .filter((i) => {
            let diff =
              Date.now() - Date.parse(i.published_at_hyper || i.published_at);
            return diff < 120000;
          }).length === 0 && <EmptyRow>None Published</EmptyRow>}
        {/* <RowHeader>Scheduled</RowHeader>
        {scheduled.map((item, index) => {
          return (
            <Row
              altColour={index % 2 === 0}
              key={index}
              onClick={() => {
                selectPoll(item.id);
              }}
            >
              {item.question}
            </Row>
          );
        })}
        {scheduled.length === 0 && <div>-</div>} */}

        <RowHeader>Queued</RowHeader>
        <QueueContainer>
          {data
            .filter((a) => a.status === "pending")
            .map((poll, index) => {
              return (
                <>
                  <Row
                    altColour={index % 2 === 0}
                    key={index}
                    onClick={() => {
                      selectPoll(poll.id);
                      // previewGraphic(poll);
                    }}
                  >
                    {item && poll.id === item && <Selected>></Selected>}
                    {poll.question}
                    {subscribedTo.indexOf(poll.subscribe_channel) > -1 && (
                      <LiveLikeStatus></LiveLikeStatus>
                    )}
                  </Row>
                  {item && poll.id === item && poll.options && (
                    <OptionRow>
                      {poll.options.map((option) => {
                        return <div>{option.description}</div>;
                      })}
                    </OptionRow>
                  )}
                </>
              );
            })}
          {data.filter((a) => a.status === "pending").length === 0 && (
            <EmptyRow>None Queued</EmptyRow>
          )}
        </QueueContainer>
        {/* <RowHeader>History</RowHeader>
        {published
          .filter((i) => {
            let diff = Date.now() - Date.parse(i.published_at);
            return diff > 120000;
          })
          .map((item, index) => {
            return (
              <Row
                altColour={index % 2 === 0}
                key={index}
                onClick={() => {
                  selectPoll(item.id);
                  previewGraphic(item);
                }}
              >
                {item.question}
              </Row>
            );
          })}
        {published.filter((i) => {
          let diff = Date.now() - Date.parse(i.published_at);
          return diff > 120000;
        }).length === 0 && <div>-</div>} */}
      </div>
    </Main>
  );
}
