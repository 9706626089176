import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { ProjectContext } from "../../contexts/ProjectContext";
import TemplateItem from "./TemplateItem";
import Show from "../Show/Show";
import { UserContext } from "../../contexts/UserContext";
import Fanzone from "../Fanzone/Fanzone";
import Opta from "../Opta/Opta";
import { UIContext } from "../../contexts/UIContext";
import { ScrollBar } from "../../Theme/Hyper";
import ImageItem from "../Editor/ImageItem";
import { ResourcesContext } from "../../contexts/ResourcesContext";
import FolderItem from "./FolderItem";

const Main = styled.div`
  height: 100vh;
  background-color: #181d25;
  box-shadow: 3px 0px 15px 0 rgba(16, 18, 21, 0.15);
  flex-grow: 1;
`;
const Header = styled.div`
  display: flex;
  background-color: #1f212b;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  width: 50%;
  padding: 0.5em;
  border-bottom: ${(props) =>
    props.selected ? "1px solid #db0a41" : "1px solid #2a2c39"};
  cursor: pointer;
  border-right: 1px solid #2a2c39;
`;

const Content = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  height: 800px;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
`;
const Folders = styled.div`
  padding-top: 0.5em;
  min-width: 30%;
  border-right: 1px solid gray;
  display: flex;
  flex-direction: column;
`;

const FolderList = styled(ScrollBar)`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
`;
export default function Sidebar() {
  const [view, setView] = useState(0);
  const { scenes, selectedShow } = useContext(ProjectContext);
  const { setPageNumber } = useContext(UIContext);
  const [showView, setShowView] = useState(1);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState();

  useEffect(() => {
    let new_folders = [];
    scenes?.forEach((scene) => {
      if (scene?.data?.folder) {
        if (
          new_folders?.findIndex((f) => f.name === scene?.data?.folder) === -1
        ) {
          new_folders.push({ type: "directory", name: scene?.data?.folder });
        }
      }
    });
    setFolders(new_folders);
  }, [scenes]);

  if (!selectedFolder && folders?.length > 0) {
    setSelectedFolder(folders[0]?.name);
  }

  return (
    <Main>
      {selectedShow && (
        <Header>
          <Item onClick={() => setView(0)} selected={view === 0}>
            Show
          </Item>
          <Item
            onClick={() => {
              setView(1);
              setPageNumber(null);
            }}
            selected={view === 1}
          >
            Templates
          </Item>
          {/* <Item onClick={() => setView(2)} selected={view === 2}>
              LiveLike
            </Item>
            <Item onClick={() => setView(3)} selected={view === 3}>
              Opta
            </Item> */}
        </Header>
      )}

      {view === 1 && (
        <Body>
          <Row>
            <Folders>
              <FolderList>
                {folders?.map((folder, index) => {
                  return (
                    <FolderItem
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder}
                      key={index}
                      folder={folder}
                      onContextMenu={(e, item) => {
                        //  handleContextMenu(e, item);
                      }}
                    />
                  );
                })}
              </FolderList>
            </Folders>
            <Content>
              {scenes
                ?.filter((s) => {
                  return s?.data?.folder === selectedFolder;
                })
                .map((scene, index) => {
                  return (
                    <TemplateItem
                      scene={scene}
                      altColour={index % 2 === 0}
                      key={index}
                    />
                  );
                })}
            </Content>
          </Row>
        </Body>
      )}
      {view === 0 && (
        <Body>
          <Show showView={showView} setShowView={setShowView} />
        </Body>
      )}
      {view === 2 && (
        <Body>
          <Fanzone />
          <Fanzone />
          <Fanzone />
        </Body>
      )}
      {view === 3 && (
        <Body>
          <Opta />
        </Body>
      )}
    </Main>
  );
}
