import React from "react";
import { FaFolder } from "react-icons/fa";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  justify-items: center;
  font-size: 0.7em;
  padding-left: 10px;
  padding-right: 0.25em;

  background-color: ${(props) =>
    props.selected ? props.theme.highlightColour : ""};
  :hover {
    background-color: ${(props) => props.theme.highlightColour};
  }
`;

const Icon = styled.div`
  padding-right: 0.5em;
  display: flex;
  align-items: center;
  color: #00d0ff;
  justify-items: center;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
`;

export default function FolderItem({
  folder,
  selectedFolder,
  setSelectedFolder,
}) {
  return (
    <>
      <Row
        selected={
          selectedFolder &&
          selectedFolder?.toUpperCase() === folder?.name?.toUpperCase()
        }
      >
        <Main
          onClick={() => {
            setSelectedFolder(folder?.name);
          }}
        >
          <Icon>
            <FaFolder />
          </Icon>
          {folder.name}
        </Main>
      </Row>
    </>
  );
}
