import React, { useState, useEffect, useContext, useCallback } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import themes from "../SKYThemes/Themes.json";
import { UserContext } from "./UserContext";

const ControlContext = React.createContext([{}, () => {}]);
window.scenesOnAir = [];

const ControlProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [scenesOnAir, setScenesOnAir] = useState([]);
  const [usersOnAir, setUserOnAir] = useState([]);
  const [playlistOn, setPlaylistOn] = useState(false);
  const [themeName, setThemeName] = useState("football");
  const { loggedIn, user } = useContext(UserContext);
  const [buildTemplate, setBuildTemplate] = useState();
  const [engineMap, setEngineMap] = useLocalStorage("engine_map", []);

  const animateOff = useCallback(
    (name, data, engine) => {
      animateOffLocal(name, data, engine);
    },
    [scenesOnAir, setScenesOnAir, ws, sendData]
  );

  const animate = useCallback(
    (name, data, layer, engine) => {
      animateLocal(name, data, layer, engine);
    },
    [scenesOnAir, setScenesOnAir, ws, sendData]
  );

  useEffect(() => {
    if (loggedIn) {
      connect();
    }
  }, [loggedIn]);

  function connect() {
    console.log("CG connecting to server");

    setWS(new WebSocket(window.command_server));
  }

  useEffect(() => {
    if (ws) {
      let timeout;
      let interval;
      ws.onopen = () => {
        setWSStatus(1);
        console.log("CG on open");

        // console.log("connected");
        clearInterval(interval);
        interval = setInterval(() => {
          sendData({
            keep_alive: Date.now(),
            group: window.id,
          });
        }, 20000);

        sendData({
          control: true,
          group: window.id,
        });

        window.groups
          .filter((g) => g.status)
          .forEach((group) => {
            connectEngine(group);
          });
      };
      ws.onmessage = (data) => {
        console.log("CG on message");
        try {
          let obj = JSON.parse(data.data);
          if (obj) {
            if (obj.type === "users") {
              setUserOnAir(obj.users);
            }

            if (obj.from_control && obj.action === "preview") {
              setBuildTemplate({ ...obj.data, app: obj.app });
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
      ws.onerror = (err) => {
        console.log("CG on message");
        ws.close();
      };
      ws.onclose = (data) => {
        setWSStatus(0);
        console.log("CG on close");
        clearTimeout(timeout);
        clearInterval(interval);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function sendData(data) {
    if (ws) {
      data.group = user._id + window.random;

      ws.send(JSON.stringify(data));
    }
  }

  function updateGlobals(theme) {
    sendData({
      action: "globals",
      group: window.id,
      data: {
        globals: theme.data,
      },
    });
  }

  function preview(name, frame, data, timline, engine) {
    if (themeName) {
      updateGlobals(
        themes.find((t) => t.name.toLowerCase() === themeName.toLowerCase())
      );
    }

    let play_engine =
      engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(engine || 1))
        ?.engine?.key || "";

    sendData({
      action: "preview",
      group: window.id,
      engine: play_engine,
      data: {
        scene: name,
        frame: frame,
        timeline: timline || "IN",
        data: updateData(data),
      },
    });
  }

  function clearPreview(name, engine) {
    let play_engine =
      engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(engine || 1))
        ?.engine?.key || "";

    sendData({
      action: "CLEAR_PREVIEW",
      group: window.id,
      engine: play_engine,
      data: {
        scene: name,
      },
    });
  }

  function clear(engine) {
    setScenesOnAir([]);
    let play_engine =
      engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(engine || 1))
        ?.engine?.key || "";

    sendData({
      action: "CLEAR",
      group: window.id,
      engine: play_engine,
      data: {},
    });
  }

  function animateLocal(name, data, layer, engine) {
    clearPreview(name);

    if (window.scenesOnAir.findIndex((item) => item.name === name) === -1) {
      let newScenesOnAir = [
        ...window.scenesOnAir.filter(
          (s) =>
            s.layer !== layer ||
            !layer ||
            (s.layer === layer && s.engine !== engine)
        ),
      ];
      newScenesOnAir.push({ name, layer, engine });
      setScenesOnAir(newScenesOnAir);
      window.scenesOnAir = newScenesOnAir;
    }

    let play_engine =
      engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(engine || 1))
        ?.engine?.key || "";

    // play_engine =
    //   window.engines?.find((e) => e.key === play_engine)?.status === "connected"
    //     ? play_engine
    //     : "";
    // if (name === "Lectern_01") {
    //   sendData({
    //     action: "animate",
    //     group: window.id,
    //     engine:
    //       engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(2))?.engine
    //         ?.key || "",
    //     data: {
    //       scene: "FF_Scoreboardx3",
    //       timeline: "IN",
    //       data: [
    //         {
    //           name: "Team1_Score",
    //           value: {
    //             text: data[0].text,
    //           },
    //         },
    //       ],
    //     },
    //   });
    // }
    // if (name === "Lectern_02") {
    //   sendData({
    //     action: "animate",
    //     group: window.id,
    //     engine:
    //       engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(2))?.engine
    //         ?.key || "",
    //     data: {
    //       scene: "FF_Scoreboardx3",
    //       timeline: "IN",
    //       data: [
    //         {
    //           name: "Team2_Score",
    //           value: {
    //             text: data[0].text,
    //           },
    //         },
    //       ],
    //     },
    //   });
    // }
    // if (name === "Lectern_03") {
    //   sendData({
    //     action: "animate",
    //     group: window.id,
    //     engine:
    //       engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(2))?.engine
    //         ?.key || "",
    //     data: {
    //       scene: "FF_Scoreboardx3",
    //       timeline: "IN",
    //       data: [
    //         {
    //           name: "Team3_Score",
    //           value: {
    //             text: data[0].text,
    //           },
    //         },
    //       ],
    //     },
    //   });
    // }
    sendData({
      action: "animate",
      group: window.id,
      engine: play_engine,
      data: {
        scene: name,
        timeline: "IN",
        data: updateData(data),
      },
    });
  }

  function update(name, data, engine) {
    let play_engine =
      engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(engine || 1))
        ?.engine?.key || "";
    sendData({
      action: "update",
      group: window.id,
      engine: play_engine,
      data: {
        scene: name,
        data: updateData(data),
      },
    });
  }

  function reload(engine) {
    let play_engine =
      engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(engine || 1))
        ?.engine?.key || "";
    sendData({
      action: "reload",
      group: window.id,
      engine: play_engine,
    });
  }

  function triggerAnimation(name, data, animation, engine) {
    let play_engine =
      engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(engine || 1))
        ?.engine?.key || "";
    sendData({
      action: "animate",
      group: window.id,
      engine: play_engine,
      data: {
        scene: name,
        timeline: animation,
        data: updateData(data),
      },
    });
  }

  function animateOffLocal(name, data, engine) {
    let newArray = [...window.scenesOnAir];
    let index = newArray.findIndex((s) => s.name === name);
    newArray.splice(index, 1);
    window.scenesOnAir = [...newArray];
    setScenesOnAir(newArray);
    let play_engine =
      engineMap?.find((e) => parseInt(e.mapped_to) === parseInt(engine || 1))
        ?.engine?.key || "";
    sendData({
      action: "animate",
      group: window.id,
      engine: play_engine,
      data: {
        scene: name,
        timeline: "OUT",
        data: data || [],
      },
    });
  }

  function updateData(data) {
    if (data && data.length > 0) {
      return data.map((item) => {
        if (item.type === "CLOCK") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              action: item.action,
              time: item.time,
            },
          };
        } else if (item.type === "TEXT") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              text: item.text,
              colour: item.colour || item.style?._fill,
              fontSize: item.fontSize || item.style?._fontSize,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        } else if (item.type === "IMAGE") {
          let col = item.colour || hex2string(item.tint);
          if (col?.toString()?.indexOf("#") === -1) {
            col = hex2string(item.colour || item.tint);
          }
          let original_src = item.original_image || item.original_src;
          let src = item.image || item.src;

          let obj = {
            name: item.name,
            value: {
              visible: item.visible,
              image:
                original_src !== src
                  ? "${user}" + (item.image || item.src)
                  : item.original_src,
              position: {
                x: item.x,
                y: item.y,
              },
              colour: col,
              width: item.width,
              height: item.height,
            },
          };
          if (item.neverno_image) {
            obj.value.neverno_image = item.neverno_image;
          }
          return obj;
        } else if (item.type === "GROUP") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              position: {
                x: item.x,
                y: item.y,
              },
            },
          };
        } else if (item.type === "VIDEO") {
          return {
            name: item.name,
            value: {
              visible: item.visible,
              video: item.video || item.src,
              position: {
                x: item.x,
                y: item.y,
              },
              // width: item.width,
              // height: item.height,
            },
          };
        } else if (item.type === "RECTANGLE" || item.type === "CIRCLE") {
          let obj = {
            name: item.name,
            value: {
              position: {
                x: item.x,
                y: item.y,
              },
              colour: item.colour || hex2string(item.fillColor),
            },
          };

          return obj;
        }
      });
    }
    return [];
  }

  function hex2string(hex) {
    var hexString = hex.toString(16);
    hexString = "000000".substr(0, 6 - hexString.length) + hexString;
    return "#" + hexString;
  }

  function connectEngine(engine) {
    sendData({
      type: engine.status ? "connect-engine" : "disconnect-engine",
      engine: engine.name,
    });
  }

  return (
    <ControlContext.Provider
      value={{
        sendData,
        preview,
        animate,
        animateOff,
        scenesOnAir,
        clearPreview,
        setThemeName,
        themes,
        wsStatus,
        clear,
        triggerAnimation,
        update,
        connectEngine,
        usersOnAir,
        playlistOn,
        setPlaylistOn,
        buildTemplate,
        reload,
        setEngineMap,
        engineMap,
        updateData,
      }}
    >
      {props.children}
    </ControlContext.Provider>
  );
};

export { ControlContext, ControlProvider };
