import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import styled from "styled-components";
import { NeverNoContext } from "../../contexts/NeverNoContext";
import { ControlContext } from "../../contexts/ControlContext";
import { FiChevronsRight } from "react-icons/fi";
import { GiPauseButton } from "react-icons/gi";
import { ProjectContext } from "../../contexts/ProjectContext";

const EditArea = styled.div`
  background-color: #191f27;
  display: flex;
`;

const Row = styled.div`
  padding-left: 1em;
  padding-top: 1em;
  cursor: pointer;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.altColour ? "#1c222b" : "")};
  :hover {
    background-color: #d11e42;
  }
  overflow: hidden;
  white-space: nowrap;
`;

const Selected = styled(FiChevronsRight)`
  color: #30c17e;
  font-size: 1.5em;
`;

const Paused = styled(GiPauseButton)`
  color: ${(props) => (props.selected ? "#d20a3e" : "#373b48")};
  font-size: 1.5em;
`;

const Arrow = styled.div`
  min-width: 30px;
  display: flex;
  align-items: center;
`;

const Username = styled.div`
  min-width: 135px;
  display: flex;
  align-items: center;
  color: #ffeb00;
`;
const TimerOptions = styled.div`
  display: flex;
  padding: 0.5em;
`;

const Option = styled.div`
  display: flex;
  background-color: ${(props) => (props.selected ? "#d20a3e" : "#0e1219")};
  border-radius: 4px;
  cursor: pointer;
  width: 80px;
  padding: 0.5em;
  justify-content: center;
  margin-right: 0.5em;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #373b48;
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;
const NeverNo = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const TwitterContent = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  width: 100%;
`;

window.twitterDuration = 10;
window.twitterSeeking = false;
export default function NeverNoEdit({ selectedEdit }) {
  const { data, start, stop } = useContext(NeverNoContext);
  const { preview, animate, animateOff, scenesOnAir } = useContext(
    ControlContext
  );
  const { edits, clear } = useContext(ProjectContext);
  window.neverNoID = 3;
  window.neverNoDestination = "hyper";
  const [selectedItem, setSelectedItem] = useState(0);
  const [state, setState] = useState(0);
  const [duration, setDuration] = useState(window.twitterDuration);
  const [paused, setPaused] = useState();

  const updateTweet = useCallback(() => {
    try {
      let item = window.neverNoData[window.neverNoIndex];

      if (item && item.id === paused && window.twitterSeeking === false) {
        return;
      }
      window.neverNoIndex++;
      window.twitterSeeking = false;
      if (window.neverNoIndex >= window.neverNoData.length) {
        window.neverNoIndex = 0;
        if (window.neverNoData.length === 1) {
          return;
        }
      }
      item = window.neverNoData[window.neverNoIndex];

      setSelectedItem(window.neverNoIndex);
      if (item) {
        // edits.find((edit) => edit.control.name === "FIRST_NAME").visible = false;
        edits.find((edit) => edit.control.name === "LINE 1").text =
          item.author.username;
        edits.find((edit) => edit.control.name === "LINE 2").text =
          item.content.message.original;
        edits.find((edit) => edit.control.name === "LINE 3").text =
          item.content.message.original;
        if (window.neverNoStatus === 0) {
          preview("WatchalongTwitter", 55, edits);
          setState(0);
        } else if (window.neverNoStatus === 1) {
          animate("WatchalongTwitter", edits);
          setState(1);
        }
      }
    } catch (err) {}
  }, [edits, paused]);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        let animated = false;

        if (window.optaOnAir) {
          animateOff("WatchalongOpta");
          animated = true;
        }
        if (window.liveLikeOnAir) {
          animateOff("LiveLike");
          animated = true;
        }
        if (window.watchalongPromoOnAir) {
          animateOff("WatchalongPromo");
          animated = true;
        }
        if (animated) {
          setTimeout(() => {
            animate("WatchalongTwitter", edits);
            setState(1);
            window.neverNoStatus = 1;
          }, 1000);
        } else {
          animate("WatchalongTwitter", edits);
          setState(1);
          window.neverNoStatus = 1;
        }
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        setState(0);
        animateOff("WatchalongTwitter", []);
        clear();
        window.neverNoStatus = -1;
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [status, edits, scenesOnAir]);

  useEffect(() => {
    let id;
    window.twitterDuration = duration;
    if (state === 1) {
      id = setInterval(() => {
        updateTweet();
      }, duration * 1000);
    }
    return () => {
      clearInterval(id);
    };
  }, [duration, state, paused]);

  useEffect(() => {
    window.neverNoIndex = -1;
    window.neverNoStatus = 0;
    updateTweet();
  }, []);

  return (
    <EditArea>
      <Controls>
        <Button
          onClick={() => {
            let animated = false;
            if (window.optaOnAir) {
              animateOff("WatchalongOpta");
              animated = true;
            }
            if (window.liveLikeOnAir) {
              animateOff("LiveLike");
              animated = true;
            }
            if (window.watchalongPromoOnAir) {
              animateOff("WatchalongPromo");
              animated = true;
            }
            if (animated) {
              setTimeout(() => {
                animate("WatchalongTwitter", edits);
                window.neverNoStatus = 1;
                window.neverNoIndex++;
              }, 1000);
            } else {
              animate("WatchalongTwitter", edits);
              window.neverNoStatus = 1;
              window.neverNoIndex++;
            }
            setState(1);
          }}
        >
          ANIMATE
        </Button>
        <Button
          onClick={() => {
            animateOff("WatchalongTwitter", []);
            clear();
            window.neverNoStatus = -1;
            setState(0);
          }}
        >
          ANIMATE OFF
        </Button>
      </Controls>
      <NeverNo>
        <TimerOptions>
          <Option
            selected={duration === 10}
            onClick={() => {
              setDuration(10);
            }}
          >
            10s
          </Option>
          <Option
            selected={duration === 12}
            onClick={() => {
              setDuration(12);
            }}
          >
            12s
          </Option>
          <Option
            selected={duration === 15}
            onClick={() => {
              setDuration(15);
            }}
          >
            15s
          </Option>
          <Option
            selected={duration === 20}
            onClick={() => {
              setDuration(20);
            }}
          >
            20s
          </Option>
          <Option
            selected={duration === 25}
            onClick={() => {
              setDuration(25);
            }}
          >
            25s
          </Option>
        </TimerOptions>
        {data &&
          data.exports &&
          data.exports.length > 0 &&
          data.exports[0] &&
          data.exports[0].items &&
          data.exports[0].items.map((d, index) => {
            return (
              <Row altColour={index % 2 === 0} key={index}>
                <div
                  onClick={() => {
                    if (paused === d.id) {
                      setPaused(null);
                    } else {
                      setPaused(d.id);
                    }
                  }}
                >
                  <Paused selected={paused === d.id} />
                </div>

                <Arrow>
                  {selectedItem === index && <Selected>></Selected>}
                </Arrow>
                <TwitterContent
                  onClick={() => {
                    window.twitterSeeking = true;
                    window.neverNoIndex = index - 1;
                    if (window.neverNoStatus === 0) {
                      updateTweet();
                    }
                  }}
                >
                  <Username>{d.author.username}</Username>
                  <div>{d.content.message.original}</div>
                </TwitterContent>
              </Row>
            );
          })}
      </NeverNo>
    </EditArea>
  );
}
