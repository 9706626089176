import React, { useState, useEffect } from "react";

const UIContext = React.createContext([{}, () => {}]);

const UIProvider = (props) => {
  const [view, setView] = useState(0);
  const [pageNumber, setPageNumber] = useState();

  return (
    <UIContext.Provider
      value={{
        view,
        setView,
        pageNumber,
        setPageNumber,
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};

export { UIContext, UIProvider };
