import React, { useContext, useEffect, useState, useCallback } from "react";
import { OptaContext } from "../../contexts/OptaContext";
import Opta from "../Opta/Opta";
import styled from "styled-components";
import HeadToHead from "../Opta/HeadToHead";
import { ScrollBar } from "../../Theme/Hyper";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ControlContext } from "../../contexts/ControlContext";
const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#373b48")};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
`;

const Dropown = styled.select`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;

const DropownOption = styled.option`
  width: 100%;
  background-color: #0e1219;
  color: white;
  border-color: #191f2b;
  padding: 0.5em;
  border-radius: 4px;
`;
export default function OptaLeague({ setCurrentTimeline }) {
  const { standings, teams, setLeagueID, leagueID } = useContext(OptaContext);
  const {
    setDataDrive,
    selectedPage,
    edits,
    clear,
    selectedScene,
  } = useContext(ProjectContext);
  const { preview, animateOff, animate, triggerAnimation } = useContext(
    ControlContext
  );
  const [half, setHalf] = useState(1);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        if (half === 1) {
          animate("FF - Standings", edits, "IN");
          setHalf(2);
        } else {
          triggerAnimation("FF - Standings", edits, "T3");
          setHalf(1);
        }
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        animateOff("FF - Standings", []);
        clear();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    updateData();
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [edits, half]);

  useEffect(() => {
    if (half) {
      if (selectedPage) {
        let dataDrive = [
          {
            name: "OptaLeague",
            half: half,
            leagueID: leagueID,
          },
        ];
        setDataDrive(dataDrive);
      } else if (selectedScene) {
        let dataDrive = [
          {
            name: "OptaLeague",
            half: half,
            leagueID: leagueID,
          },
        ];
        setDataDrive(dataDrive);
      }
    }
  }, [half, selectedPage, selectedScene, leagueID]);

  useEffect(() => {
    if (selectedPage && selectedPage.dataDrive) {
      let data = selectedPage.dataDrive.find((d) => d.name === "OptaLeague");
      if (data) {
        setHalf(data.half);
        setLeagueID(data.leagueID);
      }
    }
  }, [selectedPage]);

  const updateData = useCallback(() => {
    if (edits && standings && teams) {
      try {
        let spacing = 56;
        if (leagueID == 8) {
          spacing = 68;
          edits.find((edit) => edit.control.name === "TITLE PAGE 1").text =
            "PREMIER LEAGUE";
          edits.find((edit) => edit.control.name === "TITLE PAGE 2").text =
            "PREMIER LEAGUE";
          edits.find((edit) => edit.control.name === "ROW_21").visible = false;
          edits.find((edit) => edit.control.name === "ROW_22").visible = false;
          edits.find((edit) => edit.control.name === "ROW_23").visible = false;
          edits.find((edit) => edit.control.name === "ROW_24").visible = false;
          if (
            !edits.find((edit) => edit.control.name === "RAINBOW LACES") ||
            !edits.find((edit) => edit.control.name === "RAINBOW LACES").visible
          ) {
            edits.find((edit) => edit.control.name === "TOP TABLE BG").image =
              "http://localhost:6060/assets/images/SKY/cleanWithSheen.png";
            edits.find(
              (edit) => edit.control.name === "BOTTOM TABLE BG"
            ).image =
              "http://localhost:6060/assets/images/SKY/cleanBottomWithSheen.png";
          } else {
            edits.find((edit) => edit.control.name === "TOP TABLE BG").image =
              "http://localhost:6060/assets/images/SKY/cleanWithSheen_RAINBOW.png";
            edits.find(
              (edit) => edit.control.name === "BOTTOM TABLE BG"
            ).image =
              "http://localhost:6060/assets/images/SKY/cleanBottomWithSheen_RAINBOW.png";
          }
          edits.find(
            (edit) => edit.control.name === "PL LOGO 1"
          ).visible = true;
          edits.find(
            (edit) => edit.control.name === "PL LOGO 2"
          ).visible = true;
        } else {
          edits.find((edit) => edit.control.name === "ROW_21").visible = true;
          edits.find((edit) => edit.control.name === "ROW_22").visible = true;
          edits.find((edit) => edit.control.name === "ROW_23").visible = true;
          edits.find((edit) => edit.control.name === "ROW_24").visible = true;
          if (
            !edits.find((edit) => edit.control.name === "RAINBOW LACES") ||
            !edits.find((edit) => edit.control.name === "RAINBOW LACES").visible
          ) {
            edits.find((edit) => edit.control.name === "TOP TABLE BG").image =
              "http://localhost:6060/assets/images/SKY/Champ, L1 Top-min.png";
            edits.find(
              (edit) => edit.control.name === "BOTTOM TABLE BG"
            ).image =
              "http://localhost:6060/assets/images/SKY/Champ, L1 Bottom-min.png";
          } else {
            edits.find((edit) => edit.control.name === "TOP TABLE BG").image =
              "http://localhost:6060/assets/images/SKY/Champ, L1 Top-min_RAINBOW.png";
            edits.find(
              (edit) => edit.control.name === "BOTTOM TABLE BG"
            ).image =
              "http://localhost:6060/assets/images/SKY/Champ, L1 Bottom-min_RAINBOW.png";
          }

          edits.find((edit) => edit.control.name === "TITLE PAGE 1").text =
            "CHAMPIONSHIP";
          edits.find((edit) => edit.control.name === "TITLE PAGE 2").text =
            "CHAMPIONSHIP";
          edits.find(
            (edit) => edit.control.name === "PL LOGO 1"
          ).visible = false;
          edits.find(
            (edit) => edit.control.name === "PL LOGO 2"
          ).visible = false;
        }
        standings.forEach((row, index) => {
          try {
            let team = teams.find(
              (t) => t["@attributes"].uID === row["@attributes"].TeamRef
            );
            let rowNumber = index;
            let yPos = 0;

            let tempIndex = index;
            if (leagueID == 8) {
              if (index >= 10) {
                rowNumber = index - 10;
              }
              yPos = 68 * rowNumber;
            } else {
              if (index >= 12) {
                rowNumber = index - 12;
                tempIndex -= 2;
              }
              if (index === 6) {
                yPos += 5;
              }
              yPos += spacing * rowNumber;
              if (index === 10) {
                tempIndex = 20;
              } else if (index === 11) {
                tempIndex = 21;
              } else if (index === 22) {
                tempIndex = 22;
              } else if (index === 23) {
                tempIndex = 23;
              }
            }
            edits.find(
              (edit) => edit.control.name === tempIndex + 1 + "_BADGE"
            ).image =
              `http://${
                window.location.hostname
              }:6060/uploads/SKY/${encodeURIComponent(
                "Images/Badges/" + (team.badge || team.Name + ".png")
              )}` +
              "?token=" +
              window.token;
            edits.find(
              (edit) => edit.control.name === "ROW_" + (tempIndex + 1)
            ).y = yPos;

            edits.find(
              (edit) => edit.control.name === tempIndex + 1 + "_POSITION"
            ).text = row.Standing.Position;
            edits.find(
              (edit) => edit.control.name === tempIndex + 1 + "_NAME"
            ).text = team.Name;
            edits.find(
              (edit) => edit.control.name === tempIndex + 1 + "_PLAYED"
            ).text = row.Standing.Played;
            edits.find(
              (edit) => edit.control.name === tempIndex + 1 + "_POINTS"
            ).text = row.Standing.Points;
            let diff =
              parseInt(row.Standing.For) - parseInt(row.Standing.Against);
            if (diff > 0) {
              diff = "+" + diff;
            }
            edits.find(
              (edit) => edit.control.name === tempIndex + 1 + "_GOALS"
            ).text = diff;
          } catch (err) {}
        });
      } catch (err) {}
      if (half === 1) {
        preview("FF - Standings", 25, edits, "IN");
      } else {
        preview("FF - Standings", 28, edits, "T3");
      }
    }
  }, [edits, half, standings, teams, leagueID]);

  useEffect(() => {
    if (half === 1) {
      setCurrentTimeline("IN");
    } else {
      setCurrentTimeline("T3");
    }
    if (half === 1) {
      preview("FF - Standings", 25, edits, "IN");
    } else {
      preview("FF - Standings", 28, edits, "T3");
    }
  }, [half]);

  useEffect(() => {
    updateData();
  }, [standings]);

  return (
    <EditArea>
      <Controls>
        <Column>
          <Dropown
            onChange={(e) => {
              setLeagueID(e.currentTarget.value);
            }}
          >
            <option disabled selected value>
              -- select league --
            </option>

            <DropownOption value={8} selected={leagueID == 8}>
              Premier League
            </DropownOption>
            <DropownOption value={10} selected={leagueID == 10}>
              Championship
            </DropownOption>
            {/* <DropownOption value={11} selected={leagueID === 11}>
              League One
            </DropownOption> */}
          </Dropown>

          <Button onClick={() => updateData()}>UPDATE DATA</Button>
        </Column>
        <Column>
          <Button
            onClick={() => {
              setHalf(1);
            }}
            selected={half === 1}
          >
            PREVIEW TOP HALF
          </Button>
          <Button
            onClick={() => {
              setHalf(2);
            }}
            selected={half === 2}
          >
            PREVIEW BOTTOM HALF
          </Button>
        </Column>
        <Column>
          <Button
            onClick={() => {
              if (half === 1) {
                animate("FF - Standings", edits, "IN");
                setHalf(2);
              } else {
                triggerAnimation("FF - Standings", edits, "T3");
                setHalf(1);
              }
            }}
          >
            ANIMATE
          </Button>
          <Button
            onClick={() => {
              animateOff("FF - Standings", []);
              clear();
            }}
          >
            ANIMATE OFF
          </Button>
        </Column>
      </Controls>
    </EditArea>
  );
}
