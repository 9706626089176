import React, { useContext, useEffect, useState, useCallback } from "react";
import { OptaCricketContext } from "../../contexts/OptaCricketContext";
import Opta from "../Opta/Opta";
import styled from "styled-components";
import HeadToHead from "../Opta/HeadToHead";
import { ScrollBar } from "../../Theme/Hyper";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ControlContext } from "../../contexts/ControlContext";
const EditArea = styled.div`
  background-color: #191f27;
  padding: 1em;
  display: flex;
`;

const Button = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#373b48")};
  margin-left: auto;
  padding: 0.5em;
  min-width: 80px;
  justify-content: center;
  margin: 0.5em;
`;

const Controls = styled.div`
  display: flex;
  width: 100%;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;
export default function OptaCricketBowling() {
  const { scorecardData } = useContext(OptaCricketContext);
  const {
    setDataDrive,
    selectedPage,
    edits,
    clear,
    selectedScene,
  } = useContext(ProjectContext);
  const { preview, animateOff, animate, triggerAnimation } = useContext(
    ControlContext
  );
  const [innings, setInnings] = useState(1);
  const [team, setTeam] = useState(1);
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 119 || e.keyCode === 109) {
        // animate("FF - Standings", edits, "IN");
      } else if (e.keyCode === 120 || e.keyCode === 106) {
        //animateOff("CRICKET_Batting_Card", []);
        //clear();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [edits]);

  const updateData = useCallback(() => {
    try {
      if (scorecardData) {
        for (let i = 1; i < 9; i++) {
          edits.find((edit) => edit.control.name === i + "_LAST").text = "";
          edits.find((edit) => edit.control.name === i + "_FIRST").text = "";
          edits.find((edit) => edit.control.name === i + "_OVERS").text = "";
          edits.find((edit) => edit.control.name === i + "_MAIDENS").text = "";
          edits.find((edit) => edit.control.name === i + "_RUNS").text = "";
          edits.find((edit) => edit.control.name === i + "_WICKETS").text = "";
          edits.find((edit) => edit.control.name === i + "_ECONOMY").text = "";
        }
        for (let i = 1; i < 11; i++) {
          edits.find((edit) => edit.control.name === "10_SCORE_" + i).text = "";
        }

        try {
          edits.find((edit) => edit.control.name === "TABLE_HEADER_1").text =
            innings === 1 ? "1st Innings" : "2nd Innings";
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === "12_LAST").text = "";
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === "12_HOW_NAME").text = "";
        } catch (err) {}
        try {
          edits.find((edit) => edit.control.name === "12_RUNS").text = "";
        } catch (err) {}

        let bowlingTeam = scorecardData.CricketMatchSummary.PlayerDetail.Team.find(
          (t) => t._attributes.home_or_away === (team === 1 ? "home" : "away")
        );

        let bowling = [];
        if (Array.isArray(scorecardData.CricketMatchSummary.Innings)) {
          bowling = scorecardData.CricketMatchSummary.Innings.filter(
            (i) =>
              i._attributes.bowling_team_id === bowlingTeam._attributes.team_id
          );
        } else if (
          scorecardData.CricketMatchSummary.Innings._attributes
            .bowling_team_id === bowlingTeam._attributes.team_id
        ) {
          bowling.push(scorecardData.CricketMatchSummary.Innings);
        }
        if (bowling.length > 0) {
          bowling = bowling[innings - 1];
          bowling.Bowlers.Bowler.map((bowler, index) => {
            let row = index + 1;
            let name = bowlingTeam.Player.find(
              (p) => p._attributes.id === bowler._attributes.id
            );

            try {
              edits.find((edit) => edit.control.name === row + "_LAST").text =
                name._attributes.player_last_name;
            } catch (err) {}
            try {
              edits.find((edit) => edit.control.name === row + "_FIRST").text =
                name._attributes.player_first_name[0];
            } catch (err) {}
            try {
              edits.find((edit) => edit.control.name === row + "_OVERS").text =
                bowler._attributes.overs_bowled +
                (bowler._attributes.balls_bowled !== "0"
                  ? "." + bowler._attributes.balls_bowled
                  : "");
            } catch (err) {}
            try {
              edits.find(
                (edit) => edit.control.name === row + "_MAIDENS"
              ).text = bowler._attributes.maidens_bowled;
            } catch (err) {}
            try {
              edits.find((edit) => edit.control.name === row + "_RUNS").text =
                bowler._attributes.runs_conceded;
            } catch (err) {}
            try {
              edits.find(
                (edit) => edit.control.name === row + "_WICKETS"
              ).text = bowler._attributes.wickets_taken;
            } catch (err) {}
            try {
              edits.find(
                (edit) => edit.control.name === row + "_ECONOMY"
              ).text =
                parseInt(bowler._attributes.overs_bowled) > 0
                  ? (
                      parseInt(bowler._attributes.runs_conceded) /
                      (parseInt(bowler._attributes.overs_bowled) +
                        parseInt(bowler._attributes.balls_bowled) / 6)
                    ).toFixed(2)
                  : 0;
            } catch (err) {}
          });

          let wicket = [];
          if (bowling.FallofWickets) {
            if (Array.isArray(bowling.FallofWickets.FallofWicket)) {
              wicket = bowling.FallofWickets.FallofWicket;
            } else {
              wicket.push(bowling.FallofWickets.FallofWicket);
            }
            wicket.map((wicket, index) => {
              try {
                edits.find(
                  (edit) =>
                    edit.control.name === "10_SCORE_" + wicket._attributes.order
                ).text = wicket._attributes.runs;
              } catch (err) {}
            });
          }
          try {
            edits.find((edit) => edit.control.name === "12_LAST").text =
              bowling.Extras._attributes.total_extras;
          } catch (err) {}
          try {
            edits.find((edit) => edit.control.name === "12_HOW_NAME").text =
              bowling.Total._attributes.overs;
          } catch (err) {}
          try {
            edits.find((edit) => edit.control.name === "12_RUNS").text =
              bowling.Total._attributes.runs_scored +
              (bowling.Total._attributes.wickets !== "10"
                ? "-" + bowling.Total._attributes.wickets
                : "");
          } catch (err) {}
        }
        preview("CRICKET_Bowling_Card", 25, edits, "IN");
      }
    } catch (err) {
      preview("CRICKET_Bowling_Card", 25, edits, "IN");
    }
  }, [scorecardData, edits, team, innings]);

  useEffect(() => {
    if (selectedPage) {
      let dataDrive = [
        {
          name: "OptaCricket",
          innings: innings,
          team: team,
        },
      ];
      setDataDrive(dataDrive);
    } else if (selectedScene) {
      let dataDrive = [
        {
          name: "OptaCricket",
          innings: innings,
          team: team,
        },
      ];
      setDataDrive(dataDrive);
    }
  }, [selectedPage, selectedScene, innings, team]);

  useEffect(() => {
    if (selectedPage && selectedPage.dataDrive) {
      let data = selectedPage.dataDrive.find((d) => d.name === "OptaCricket");
      if (data) {
        setInnings(data.innings);
        setTeam(data.team);
      }
    }
  }, [selectedPage]);

  return (
    <EditArea>
      <Controls>
        <Column>
          <Button
            onClick={() => {
              updateData();
            }}
          >
            UPDATE DATA
          </Button>
        </Column>
        <Column>
          <Button
            selected={innings === 1}
            onClick={() => {
              setInnings(1);
            }}
          >
            1ST INNINGS
          </Button>
          <Button
            selected={innings === 2}
            onClick={() => {
              setInnings(2);
            }}
          >
            2ND INNINGS
          </Button>
        </Column>
        <Column>
          <Button
            selected={team === 1}
            onClick={() => {
              setTeam(1);
            }}
          >
            HOME TEAM
          </Button>
          <Button
            selected={team === 2}
            onClick={() => {
              setTeam(2);
            }}
          >
            AWAY TEAM
          </Button>
        </Column>
      </Controls>
    </EditArea>
  );
}
